import classNames from "classnames";
import React from "react";
import { Collapse } from "react-collapse";

const CollapseItem = ({ title, desc, open, setOpen, index }) => {
  const handletoggle = () => {
    setOpen(open ? -1 : index);
  };

  return (
    <div
      className="cursor-pointer transition animate-fade-left secondary:hidden block "
      onClick={handletoggle}
    >
      <div className="  ">
        <div className="flex justify-between items-center border-b border-[#d8d8d8] py-3 ">
          <h1
            className={classNames(
              `font-semibold xs:text-[1.25rem] text-base `,
              {
                "text-trkis": open,
              }
            )}
          >
            {title}
          </h1>
          <img
            src="/images/icons8pfeil-ausklappen3-1.png"
            className={`transition-all ${open && "rotate-90"}`}
            alt=""
          />
        </div>
      </div>

      <Collapse
        isOpened={open}
        s
        className={`transition-all  animate-duration-1000 animate-dissolve`}
      >
        <p
          className={`xs:p-[1.875rem] p-2  transition-all xs:text-[1rem] text-xs font-semibold dissolve animate-fade-down animate-duration-1000`}
        >
          {desc}
        </p>
      </Collapse>
    </div>
  );
};

export default CollapseItem;
