import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FloatLabel from "../FloatingLabel";

const HTMLTextEditor = ({ quillattributes, value, onChange, label = "" }) => {
  return (
    <div {...quillattributes}>
      <FloatLabel
        label={label}
        keepitfocused
        focusClass="!text-base font-semibold text-[#D8D8D8]  ms-[5px]"
      >
        <ReactQuill value={value} onChange={onChange} />
      </FloatLabel>
    </div>
  );
};

export default HTMLTextEditor;
