import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { replaceAndMark } from "../../../../../utils/replaceAndMarkText";
import { replaceText } from "../../../../../utils/replaceText";
import { imageBackground } from "../../../../../utils/setImageBackground";
import TypeButton from "../../shared/TypeButton";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";
import Footer from "../footer/Footer";
import Hero from "../hero/Hero";
import LocalReviews from "../localReviews/LocalReviews";
import PortraitAndText from "../portraitAndText/PortraitAndText";

import { sendEventUser } from "../../../../../utils/analytics";

const View = ({
  landingPageData,
  clickid,
  promoter,
  localReviews,
  type,
  promoter_id,
  landingPageId,
  user,
}) => {
  const navigate = useNavigate();
  const {
    activateLocalReviews,
    whatsappButtonTextOne,
    whatsappButtonTextTwo,
    persentationVideo,
    presentationVideoDuration,
    thumbnail,
    questionaireIntroductionText,
    questionaireButtonText,
    whatsappText,
    whatsappNumber,
    activateWhatsApp,
    logo,
    localReviewsHeader,
    introVideoHeader,
    accompanyingTextOne,
    accompanyingTextTwo,
    accompanyingTextThree,
    introductoryText,
  } = landingPageData;

  // Textersätzung...
  [
    "introductoryText",
    "accompanyingTextOne",
    "accompanyingTextTwo",
    "accompanyingTextThree",
    "introVideoHeader",
    "questionaireIntroductionText",
  ].forEach((key) => {
    let temp = replaceAndMark(
      landingPageData[key],
      {
        "{Promoter Vorname}": ` ${promoter.firstname} `,
        "{Promoter Nachname}": ` ${promoter.lastname} `,
      },
      "#00DEF7"
    );
    landingPageData[`transformed_${key}`] = temp;
  });

  const promoterReplacements = {
    "{Promoter Vorname}": ` ${promoter.firstname} `,
    "{Promoter Nachname}": ` ${promoter.lastname} `,
  };

  const textMappings = {
    transformed_whatsappText: whatsappText,
    transformed_introVideoHeader: introVideoHeader,
    transformed_questionaireIntroductionText: questionaireIntroductionText,
    transformed_accompanyingTextOne: accompanyingTextOne,
    transformed_accompanyingTextTwo: accompanyingTextTwo,
    transformed_accompanyingTextThree: accompanyingTextThree,
    transformed_introductoryText: introductoryText,
  };

  Object.keys(textMappings).forEach((key) => {
    landingPageData[key] = replaceText(textMappings[key], promoterReplacements);
  });

  landingPageData.transformed_introVideoHeader = replaceText(introVideoHeader, {
    ...promoterReplacements,
    "{Videolaenge}": Math.round(presentationVideoDuration),
  });
  // Google Analytics PageView
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sendEventUser({
        category: "page",
        action: "page_viewLP",
        label: "lppageview",
        value: 1,
        landingPageId: landingPageId,
      });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [landingPageId]);

  // Google Analytics Handler
  const googleAnalyticsHandler = (label) => {
    sendEventUser({
      category: "button",
      action: `click_${label}`,
      label: label,
      value: 1,
      landingPageId: landingPageId,
    });
  };
  const withBackground = (set) => {
    return set ? "bg-[#000000] p-1 rounded-sm bg-opacity-50" : "";
  };

  const isEmptyHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText.trim() === "";
  };

  const shouldHideTextBlockTwo = isEmptyHtml(
    landingPageData.transformed_accompanyingTextTwo
  );
  return (
    <div className="w-full">
      <Hero landingPageData={landingPageData} type={type} promoter={promoter} />

      <div className={type === "property" ? "bg-[#F1F3F6]" : "bg-white"}>
        <WhatsappButton
          number={whatsappNumber}
          whatsappText={landingPageData.transformed_whatsappText}
          text={whatsappButtonTextOne}
          activateWhatsApp={activateWhatsApp}
          className="hidden secondary:flex py-14"
          type={type}
          sendGoogleAnalytics={() => googleAnalyticsHandler("wa1")}
        />

        {type === "finance" && (
          <div
            className={`relative text-xl mb-14 p-[12px] text-black flex w-full flex-col items-center justify-center secondary:hidden whitespace-pre-line mt-14 ${withBackground(
              landingPageData.transformed_accompanyingTextOne != "" &&
                landingPageData.activateBackgroundAccompanyingTextOne
            )}`}
          >
            <div
              className="w-full"
              dangerouslySetInnerHTML={{
                __html: landingPageData.transformed_accompanyingTextOne,
              }}
            ></div>
          </div>
        )}

        <LocalReviews
          localReviews={localReviews}
          activateLocalReviews={activateLocalReviews}
          localReviewsHeader={localReviewsHeader}
          type={type}
          logo={logo}
        />

        <Video
          url={persentationVideo}
          thumbnail={thumbnail}
          className={
            type !== "finance"
              ? "!text-white hidden secondary:block pt-14"
              : "hidden pt-14"
          }
          type={type}
          heading={landingPageData.transformed_introVideoHeader}
          landingPageId={landingPageId}
          videoTextBG={landingPageData.activateBackgroundVideoText}
        />

        <div className="flex-col gap-12 w-full items-center p-16 secondary:flex hidden">
          {type === "finance" && (
            <div className="flex secondary:flex-row secondary:gap-4 secondary:justify-between w-[100%] ">
              {!shouldHideTextBlockTwo && (
                <div
                  className={`hidden whitespace-pre-line  ${
                    type === "property" ? "text-[#000000]" : "text-white"
                  } secondary:block break-words mx-auto xs:w-[500px] primary:w-[600px] secondary:w-[70%]`}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: landingPageData.transformed_accompanyingTextTwo,
                    }}
                    className={`whitespace-pre-line -mt-[0.5rem] ${withBackground(
                      landingPageData.transformed_accompanyingTextTwo &&
                        landingPageData.activateBackgroundAccompanyingTextTwo
                    )} ${
                      !landingPageData.activateBackgroundAccompanyingTextTwo &&
                      "text-[#000000]"
                    }`}
                  ></div>
                </div>
              )}

              <div
                className={`h-[600px] w-[40%] ${landingPageData.activateBackgroundAccompanyingTextTwo} -mt-2  rounded-xl hidden secondary:block !bg-top`}
                style={
                  landingPageData.primary_image
                    ? { ...imageBackground(landingPageData.primary_image) }
                    : {}
                }
              ></div>
            </div>
          )}

          {type === "finance" && (
            <WhatsappButton
              activateWhatsApp={activateWhatsApp}
              number={whatsappNumber}
              whatsappText={landingPageData.transformed_whatsappText}
              text={whatsappButtonTextTwo}
              className="mx-4 mt-14"
              type={type}
              sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
            />
          )}

          {type === "finance" &&
            landingPageData.transformed_accompanyingTextThree !==
              "<p><br></p>" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: landingPageData.transformed_accompanyingTextThree,
                }}
                className={`whitespace-pre-line w-[100%] text-[#000000] p-4 rounded-sm bg-[#fafafa] ${withBackground(
                  landingPageData.transformed_accompanyingTextThree &&
                    landingPageData.activateBackgroundAccompanyingTextThree
                )} ${
                  landingPageData.activateBackgroundAccompanyingTextThree && ""
                }`}
              ></div>
            )}
        </div>

        {type === "finance" && (
          <WhatsappButton
            activateWhatsApp={activateWhatsApp}
            number={whatsappNumber}
            whatsappText={landingPageData.transformed_whatsappText}
            text={whatsappButtonTextTwo}
            className="mx-4 mt-14 secondary:hidden block"
            type={type}
            sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
          />
        )}

        {type !== "finance" && (
          <WhatsappButton
            activateWhatsApp={activateWhatsApp}
            number={whatsappNumber}
            whatsappText={landingPageData.transformed_whatsappText}
            text={whatsappButtonTextTwo}
            className="mx-4 mt-14 hidden secondary:block"
            type={type}
            sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
          />
        )}

        <PortraitAndText
          landingPageData={landingPageData}
          className="secondary:hidden pt-14 flex flex-col gap-10 items-center"
        />

        {type !== "finance" && (
          <WhatsappButton
            activateWhatsApp={activateWhatsApp}
            number={whatsappNumber}
            whatsappText={landingPageData.transformed_whatsappText}
            text={whatsappButtonTextTwo}
            className="mx-4 mt-14 block secondary:hidden"
            type={type}
            sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
          />
        )}

        <img
          src="/images/icons-8-doppelt-runter-21-schwarz.png"
          alt=""
          className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto mt-14"
        />

        <div
          className="mt-14 p-7 primary:px-40 overflow-anywhere"
          dangerouslySetInnerHTML={{
            __html: landingPageData.transformed_questionaireIntroductionText,
          }}
        ></div>

        <TypeButton
          className="mx-4 mt-4 mb-14"
          type={type}
          text={questionaireButtonText}
          onClick={() => {
            googleAnalyticsHandler("qf");
            navigate(
              `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`
            );
          }}
        />

        <Footer
          user={user}
          ladingPageId={landingPageId}
          landingPageData={landingPageData}
          promoter_id={promoter_id}
          type={type}
        />
      </div>
    </div>
  );
};

export default View;
