import { LOADING, REMOVECACHE, SETCACHE } from "./constants";

const initialState = {
  cache: null,
};

const cacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETCACHE:
      return {
        ...state,
        cache: action.payload,
      };
    case REMOVECACHE:
      return {
        ...state,
        cache: null,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default cacheReducer;
