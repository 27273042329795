import React from "react";
import HeroFinance from "./HeroFinance";
import HeroStromGas from "./HeroStromGas";
import HeroProperty from "./HeroProperty";

const Hero = ({ type, landingPageData, promoter }) => {
  switch (type) {
    case "finance":
    case "individual":
      return (
        <HeroFinance landingPageData={landingPageData} promoter={promoter} />
      );
    case "stormgas":
      return (
        <HeroStromGas landingPageData={landingPageData} promoter={promoter} />
      );
    case "property":
      return (
        <HeroProperty landingPageData={landingPageData} promoter={promoter} />
      );
  }
};

export default Hero;
