import { Button, Skeleton } from "antd";
import classNames from "classnames";
import moment from "moment/moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import NotificationService from "../../service/NotificationService";

const Messages = ({
  data,
  setNotificationOutboundPage,
  notificationOutboundPage,
  totalPages,
  read,
  getNotifications,
  isInbound,
}) => {
  return (
    <InfiniteScroll
      dataLength={data?.length || 0} //This is important field to render the next data
      style={{ overflowX: "hidden" }}
      next={() => {
        setNotificationOutboundPage((prev) => {
          return prev + 1;
        });
      }}
      hasMore={notificationOutboundPage !== totalPages}
      loader={<Skeleton active />}
    >
      <Button
        type="primary"
        onClick={async () => {
          await NotificationService.markAllAsRead({ isInbound });
          await getNotifications();
        }}
      >
        Alle als gelesen markieren
      </Button>
      <div className="flex flex-col gap-5 py-5 px-2">
        {data?.map((e, i) => {
          return (
            <div
              key={i}
              id={`notification-item-${i}`}
              onClick={(x) => {
                x.preventDefault();
                x.stopPropagation();
                read(e);
              }}
              className={`relative rounded-[20px] p-[10px] bg-[#FAFAFA] cursor-pointer flex ${
                !e.hasRead && " border-[1px] border-[#868686]"
              }`}
            >
              <div
                className={classNames(
                  "flex flex-col gap-5 sm:text-base text-xs text-ellipsis overflow-hidden",
                  {
                    "w-[95%]": !e.hasRead,
                  }
                )}
              >
                <p
                  className=" text-base font-semibold text-ellipsis overflow-hidden"
                  onClick={(x) => {
                    x.preventDefault();
                    x.stopPropagation();
                  }}
                  dangerouslySetInnerHTML={{ __html: e.text }}
                />
                <p className="text-[#868686] sm:text-base text-xs">
                  {moment(e.createdAt).fromNow()}
                </p>
              </div>
              {!e.hasRead && (
                <img
                  src="/notificationicons/icons-8-neu-34.svg"
                  className="absolute unreadNewIcon sm:top-[-20px] top-[-15px] sm:right-[-17px] z-50 right-[-10px] sm:w-[80px] w-[50px] "
                  alt="img"
                />
              )}
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
};

export default Messages;
