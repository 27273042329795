// replaceText-Funktion, die den Eingabestring und das Key-Value-Objekt für die Ersetzungen akzeptiert
export const replaceText = (inputString, replacements) => {
  // Escape special characters for use in a regular expression
  const escapeRegExp = (string) =>
    string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Erstellen Sie einen regulären Ausdruck, um die Wörter zu finden, die ersetzt werden sollen
  const regex = new RegExp(
    Object.keys(replacements).map(escapeRegExp).join("|"),
    "gi"
  );

  // Ersetzen Sie die gefundenen Wörter durch ihre entsprechenden Werte
  return inputString.replace(regex, (matched) => replacements[matched]);
};
