import { Alert, Button, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";
import SubscriptionService from "../../service/SubscriptionService";

const Course = ({ title }) => {
  const urlMemberspot = "https://eingutertipp.mymemberspot.de";
  const isFirstCall = React.useRef(true);

  const user = useSelector(selectUser);
  const [subscription, setSubscription] = useState(null);

  const getSubscriptionInfo = async () => {
    const { data } = await SubscriptionService.getSubscriptionInfo();
    console.log(data);
    setSubscription(data?.subscription);
  };

  useEffect(() => {
    title("Videokurs");
  }, [title]);

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  const [tutorials, setTutorials] = React.useState(null);

  React.useEffect(() => {
    const getTutorials = async () => {
      const { data } = await PublicService.getTutorials();
      const sortedData = data.sort((a, b) => a.ranking - b.ranking);
      setTutorials(data);
    };
    getTutorials();
  }, []);

  const navigate = useNavigate();

  if (!user?.user?.canAccessSystemForFree && !subscription)
    return (
      <Alert
        type="info"
        message={
          <>
            <div>
              Entdecke das Geheimnis erfolgreichen Empfehlungsmarketings mit
              unseren exklusiven Tutorials – Dein Schl&uuml;ssel zu einem
              au&szlig;ergew&ouml;hnlichen Business! Planbare Einnahmen! ;o)
              Diese Premium-Videos sind einzig und allein unseren Mitgliedern
              vorbehalten. Sichere dir jetzt dein Abo und tauche ein in die Welt
              des gezielten Marketingerfolgs. Mit unseren Insider-Tipps bist du
              auf dem schnellsten Weg, dein Business auf ein neues Level zu
              heben. Warte nicht l&auml;nger – starte jetzt durch!
            </div>
            <div className="mt-2">
              <Button
                type="primary"
                onClick={() => navigate("/dashboard/upgrade")}
              >
                Jetzt Abonieren
              </Button>
            </div>
          </>
        }
      />
    );

  if (isFirstCall.current) {
    isFirstCall.current = false;
    window.open(urlMemberspot, "_blank");
  }

  return (
    <div className="border-[1px] my-10 border-[#d8d8d8] bg-[#fafafa]   rounded-[30px] ">
      <div className="p-5">
        <h1 className="sm:text-[28px] text-xl  font-semibold">
          EinGuterTipp | Online Kurse
        </h1>
        <br />
        <p>Lieber Nutzer,</p>
        <br />
        <p>
          sch&ouml;n, dass Du wieder zur&uuml;ck bist! Genug gelernt für heute?
          Oder war gar kein Ende gewollt?
        </p>
        <p>
          Dann geht es{" "}
          <b>
            <a href={urlMemberspot} target="_blank">
              HIER
            </a>
          </b>{" "}
          zurück zum Online-Kurs.
        </p>
        <br />
        <p>
          Alternativ kann es, dass Dein Popup Blocker die Weiterleitung zum
          Videokurs verhindert hat.
        </p>
        <p>
          Am besten Du erlaubst EinGuterTipp Popups in Deinen
          Browsereinstellungen.
        </p>
        <p>
          Bis dahin, geht es auch{" "}
          <b>
            <a href={urlMemberspot} target="_blank">
              HIER
            </a>
          </b>{" "}
          direkt zum Online-Kurs.
        </p>
        <br />
        <p>
          Bei Fragen oder Anregungen stehen wir euch jederzeit gerne zur
          Verf&uuml;gung.
        </p>
        <br />
        <p>
          Mit freundlichen Gr&uuml;&szlig;en,
          <br />
          <p>Euer Team von EinGuterTipp</p>
        </p>
      </div>
    </div>
  );
};

export default Course;
