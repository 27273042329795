import React from "react";
import { sendEventUser } from "../../../../../utils/analytics";
import { imageBackground } from "../../../../../utils/setImageBackground";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";

const HeroProperty = ({ landingPageData }) => {
  const {
    transformed_accompanyingTextOne,
    transformed_accompanyingTextTwo,
    transformed_accompanyingTextThree,
    transformed_introductoryText,
    transformed_whatsappText,
    whatsappNumber,
    background_image,
    logoSrc,
    primary_image,
    whatsappButtonTextOne,
    persentationVideo,
    thumbnail,
    activateWhatsApp,
    promoter,
  } = landingPageData;

  const promoterReplacements = promoter
    ? {
        "{Promoter Vorname}": `<span">${promoter.firstname}</span>`,
        "{Promoter Nachname}": `<span">${promoter.lastname}</span>`,
      }
    : {
        "{Promoter Vorname}": `<span>{Promoter Vorname}</span>`,
        "{Promoter Nachname}": `<span>{Promoter Nachname}</span>`,
      };

  const replaceText = (text) =>
    text
      .replace("{Promoter Vorname}", promoterReplacements["{Promoter Vorname}"])
      .replace(
        "{Promoter Nachname}",
        promoterReplacements["{Promoter Nachname}"]
      );

  const replacedIntroText = replaceText(transformed_introductoryText);
  const replacedAccompanyingTextOne = replaceText(
    transformed_accompanyingTextOne
  );
  const replacedAccompanyingTextTwo = replaceText(
    transformed_accompanyingTextTwo
  );
  const replacedAccompanyingTextThree = replaceText(
    transformed_accompanyingTextThree
  );

  return (
    <>
      {/** Desktop  */}
      <div className="hidden w-full px-32 py-12 min-h-[820px] secondary:flex">
        {logoSrc && (
          <img
            src={logoSrc}
            alt="logo"
            className="absolute h-[60px] secondary:h-[60px] left-1/2 translate-x-[-50%] translate-y-[-50%] top-[40px]"
          />
        )}

        <div className="flex w-full h-full gap-12 max-w-[1600px] m-auto">
          <div className="flex flex-col justify-center w-[450px] gap-8 whitespace-pre-line">
            <p
              className="pb-20 text-4xl font-semibold whitespace-pre-line"
              dangerouslySetInnerHTML={{ __html: replacedIntroText }}
            ></p>
            <p
              className="text-2xl font-bold whitespace-pre-line"
              dangerouslySetInnerHTML={{
                __html: replacedAccompanyingTextTwo,
              }}
            ></p>
            <p
              className="text-2xl font-bold whitespace-pre-line"
              dangerouslySetInnerHTML={{
                __html: replacedAccompanyingTextThree,
              }}
            ></p>
          </div>

          <div
            className="flex-1 rounded-sm bg-slate-300"
            style={{ ...imageBackground(background_image) }}
          />

          <div className="w-[450px] break-words">
            <div
              className="h-[330px] rounded-sm"
              style={{ ...imageBackground(primary_image) }}
            ></div>
            <div
              className="pt-12 text-2xl font-bold whitespace-pre-line"
              dangerouslySetInnerHTML={{
                __html: replacedAccompanyingTextOne,
              }}
            ></div>
          </div>
        </div>
      </div>

      {/** Mobile */}
      <div className="relative p-5 pt-16 text-center secondary:hidden">
        {logoSrc && (
          <img
            src={logoSrc}
            alt="logo"
            className="absolute !h-12 !secondary:h-14 left-1/2 translate-x-[-50%] top-2"
          />
        )}
        <p
          className="text-2xl font-semibold pb-14 whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: replacedIntroText }}
        ></p>
        <div
          className="w-full h-[200px] md:h-[300px] rounded-sm mb-14 xs:w-[500px] primary:w-[600px] m-auto whitespace-pre-line"
          style={{ ...imageBackground(background_image) }}
        ></div>
        <p
          className="text-xl font-semibold mb-14 whitespace-pre-line"
          dangerouslySetInnerHTML={{
            __html: replacedAccompanyingTextOne,
          }}
        ></p>

        {/** Video */}
        <Video
          url={persentationVideo}
          thumbnail={thumbnail}
          type="property"
          heading={landingPageData.transformed_introVideoHeader}
        />

        <WhatsappButton
          number={whatsappNumber}
          whatsappText={transformed_whatsappText}
          className="mt-14"
          text={whatsappButtonTextOne}
          activateWhatsApp={activateWhatsApp}
          type="property"
          sendGoogleAnalytics={() =>
            sendEventUser({
              category: "button",
              action: `click_wa1`,
              label: "wa1",
              value: 1,
              landingPageId: landingPageData._id,
            })
          }
        />
      </div>
    </>
  );
};

export default HeroProperty;
