import { Segmented, Table } from "antd";
import Column from "antd/es/table/Column";
import React, {useState, useEffect} from "react";
import AdminService from "../../../service/AdminService";

const Index = () => {
  
  const [promoter, setPromoter] = useState([]);
  const [dayFilter, setDayFilter] = useState("Alle");
  const [topThree, setTopThree] = useState([]);

  const getAdminSetting = async () => {
    const { data } = await AdminService.getPartnerLeaderboard();
    console.log(data.leaderboard)
    data.leaderboard.forEach((item, i) => {
      item.index = i + 1;
    });
    setPromoter(data.leaderboard);
    const sortedArray = data.leaderboard.sort((a, b) => b.aufrufCount - a.aufrufCount);
    setTopThree(sortedArray.slice(0, 3));
  }

  useEffect(() => {
    getAdminSetting();
  }, [dayFilter])

  return (
    <div>
      <div className="overflow-auto">
        <Segmented
          className="mx-0 xs:mx-5 my-6  font-medium text-[14px] rounded-[10px]"
          defaultValue={"Alle"}
          onChange={(value) => {setDayFilter(value)}}
          options={[
            {
              label: (
                <div className=" px-[10px] rounded-me rounded-[6px] py-[7px]">
                  Alle
                </div>
              ),
              value: "Alle",
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  7 Tage
                </div>
              ),
              value: "7days",
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  Letzter Monat
                </div>
              ),
              value: "lastmonth",
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  3 Monate
                </div>
              ),
              value: "3months",
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  Letztes Jahr
                </div>
              ),
              value: "lastyear",
            },
          ]}
        />
      </div>
      <div className="leader-board-hero-second lg:h-[400px] grid lg:grid-cols-3 grid-cols-1">
        <div className="h-full">
          <div className="h-full">
            <div className="flex justify-center items-end h-full ">
              <div className="my-10">
                <div className="relative flex flex-col justify-center">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROEMCKQPj0-FtCQEGoafkYxbdycwzTTvZXIGxa09Bts_3s2w4NlZQUt43oYpg-xcotvFE&usqp=CAU"
                    className="w-[100px] h-[100px] rounded-full m-auto"
                    alt=""
                  />

                  <div className=" bg-[#70514A] h-[30px]  w-[30px] text-center p-1 m-auto right-0 left-0 bottom-[-15px]   rounded-full text-white font-bold absolute">
                    3
                  </div>
                </div>
                <div className="bg-white rounded-[15px] p-3 text-center">
                  <h1 className="text-[28px] font-semibold">{topThree.length ? topThree[2].name : ""}</h1>
                  <div className="flex gap-3 items-center justify-between white-text-div-folder">
                    <div>
                      <p>Aufrufe</p>
                      <h5>{topThree.length ? topThree[2].aufrufCount : ""}</h5>
                    </div>
                    <div>
                      <p>Empfehlungen</p>
                      <h5>{topThree.length ? topThree[2].empfehlungenCount : ""}</h5>
                    </div>
                    <div>
                      <p>Neukunden</p>
                      <h5>{topThree.length ? topThree[2].promoterCount : ""}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-full">
          <div className="flex justify-center items-start  h-full ">
            <div className="my-10">
              <div className="relative flex flex-col justify-center">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROEMCKQPj0-FtCQEGoafkYxbdycwzTTvZXIGxa09Bts_3s2w4NlZQUt43oYpg-xcotvFE&usqp=CAU"
                  className="w-[100px] h-[100px] rounded-full m-auto"
                  alt=""
                />

                <div className=" bg-[#FFD700] h-[30px]  w-[30px] text-center p-1 m-auto right-0 left-0 bottom-[-15px]   rounded-full text-white font-bold absolute">
                  1
                </div>
              </div>
              <div className="bg-white rounded-[15px] p-3 text-center">
                <h1 className="text-[28px] font-semibold">{topThree.length ? topThree[0].name : ""}</h1>
                <div className="flex gap-3 items-center justify-between white-text-div-folder">
                  <div>
                    <p>Aufrufe</p>
                    <h5>{topThree.length ? topThree[0].aufrufCount : ""}</h5>
                  </div>
                  <div>
                    <p>Empfehlungen</p>
                    <h5>{topThree.length ? topThree[0].empfehlungenCount : ""}</h5>
                  </div>
                  <div>
                    <p>Neukunden</p>
                    <h5>{topThree.length ? topThree[0].promoterCount : ""}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-full">
          <div className="flex justify-center items-center   h-full ">
            <div className="my-10">
              <div className="relative flex flex-col justify-center">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROEMCKQPj0-FtCQEGoafkYxbdycwzTTvZXIGxa09Bts_3s2w4NlZQUt43oYpg-xcotvFE&usqp=CAU"
                  className="w-[100px] h-[100px] rounded-full m-auto"
                  alt=""
                />

                <div className=" bg-[#868686] h-[30px]  w-[30px] text-center p-1 m-auto right-0 left-0 bottom-[-15px]   rounded-full text-white font-bold absolute">
                  2
                </div>
              </div>
              <div className="bg-white rounded-[15px] p-3 text-center">
                <h1 className="text-[28px] font-semibold">{topThree.length ? topThree[1].name : ""}</h1>
                <div className="flex gap-3 items-center justify-between white-text-div-folder">
                  <div>
                    <p>Aufrufe</p>
                    <h5>{topThree.length ? topThree[1].aufrufCount : ""}</h5>
                  </div>
                  <div>
                    <p>Empfehlungen</p>
                    <h5>{topThree.length ? topThree[1].empfehlungenCount : ""}</h5>
                  </div>
                  <div>
                    <p>Neukunden</p>
                    <h5>{topThree.length ? topThree[1].promoterCount : ""}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <Table
          className="my-10"
          bordered={false}
          dataSource={promoter}
          pagination={false}
          rowKey={"uid"}
        >
          <Column
            title="Platz"
            dataIndex={"index"}
            className="font-bold text-[18px]"
          ></Column>
          <Column
            title="Name"
            dataIndex={"name"}
            className="font-bold text-[18px]"
          ></Column>
          <Column
            title="Telefonnummer des Promoters"
            dataIndex={"telefon"}
            className="font-bold text-[18px]"
          ></Column>
          <Column
            title="Aufrufe"
            dataIndex={"aufrufCount"}
            className="font-bold text-[18px]"
          ></Column>
          <Column
            title="Anzahl Empfehlungen"
            dataIndex={"empfehlungenCount"}
            className="font-bold text-[18px]"
          ></Column>
          <Column
            title="Anzahl Neukunden"
            dataIndex={"promoterCount"}
            className="font-bold text-[18px]"
          ></Column>
        </Table>
      </div>
    </div>
  );
};

export default Index;
