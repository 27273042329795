import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class TemplateService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/template`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  listTemplates() {
    return this.api.get("/list");
  }

  createTemplate(templateData) {
    return this.api.post("/create", templateData);
  }

  updateTemplate(templateId, templateData) {
    return this.api.put(`/update?id=${templateId}`, templateData);
  }
  deleteTemplate(templateId) {
    return this.api.delete(`/deleteTemplate?id=${templateId}`);
  }
}

export default new TemplateService();
