import { ConfigProvider, Skeleton, message } from "antd";
import deDE from "antd/locale/de_DE";
import jwtDecode from "jwt-decode";
import "moment/locale/de";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import AGB from "./components/AGB";
import AdminDashboard from "./components/AdminDashboard";
import ForgetPassword from "./components/Auth/ForgotPassword";
import Login from "./components/Auth/Login";
import Confirmation from "./components/Auth/PromoterRegisration/Confirmation";
import { default as PromoterRegistration } from "./components/Auth/PromoterRegisration/PromoterRegistration";
import Register from "./components/Auth/Register";
import ResetPassword from "./components/Auth/ResetPassword";
import Dashboard from "./components/Dashboard";
import Danke from "./components/Dashboard/Danke/index.jsx";
import Erstgesprach from "./components/Dashboard/Erstgesprach/index.jsx";
import NotFound from "./components/Dashboard/NotFound/NotFound";
import Startergesprach from "./components/Dashboard/Startergesprach/index.jsx";
import Datenschutz from "./components/Datenschutz";
import DatenschutzLandingPages from "./components/Datenschutz/DatenschutzLandingPages";
import EmailVerification from "./components/EmailVerificationPages/EmailVerification";
import EmailComfirmation from "./components/EmailVerificationPages/EmailVerificationAuth";
import PromoterEmailVerification from "./components/EmailVerificationPages/EmailVerificationAuthPromoter";
import Home from "./components/Home";
import Impressum from "./components/Impressum/Impressum";
import ImpressumLandingPages from "./components/Impressum/ImpressumLandingPages";
import ImpressumUser from "./components/Impressum/ImpressumUser";
import LandingPages from "./components/LandingPageConfig";
import MainQuestionare from "./components/LandingPageConfig/MainQuestionare";
import LandingPageNew from "./components/NewLandingPage";
import PromoterSite from "./components/PromoterSite";
import Teilnahmebedingungen from "./components/Teilnahmebedingungen";
import Chapter from "./components/Tutorials/Chapter";
import { MessageProvider } from "./context/messageContext.jsx";
import { useCredentials } from "./hooks/useCredentails";
import "./index.css";
import NewLandingPage from "./pages/LandingPage.jsx";
import LandingPageConfig from "./pages/LandingPageConfig.jsx";
import { selectUser } from "./redux/auth/selectors";
import { removeCACHE } from "./redux/cache/actions";
import { store } from "./redux/store";
import AuthService from "./service/AuthService.js";
import PublicService from "./service/PublicService.js";
import UserService from "./service/UserService";
import { getToken, removeToken } from "./utils/LocalStorageHandler";

const Main = () => {
  const [loading, setLoading] = useState(false);
  const { admin, user } = useCredentials();
  moment.locale("de");

  const getAdminConfigure = async () => {
    const response = await PublicService.getAdminConfigure();
    localStorage.adminTestPhase = response?.data?.config?.testPhaseDurationDays;
  };
  React.useEffect(() => {
    getAdminConfigure();
  }, []);

  useEffect(() => {
    return () => {
      store.dispatch(removeCACHE());
    };
  }, []);
  useEffect(() => {
    const { access_token } = getToken();

    if (!!access_token && window.location.pathname === "/") {
      const decodedToken = jwtDecode(access_token);
      if (!decodedToken?.promoterId) {
        const doit = async () => {
          try {
            const userData = await AuthService?.me();
            if (userData?.firstname) window.location.href = "/dashboard";
            else {
              removeToken();
              window.location.reload();
            }
          } catch (error) {}
        };
        doit();
      }
    }
  }, []);
  useEffect(() => {
    const state = store.getState();
    const { access_token } = getToken();
    const decodedToken = !!access_token && jwtDecode(access_token);

    if (!decodedToken?.promoterId && !state?.auth?.user?.user) {
      removeToken();
    }
  }, []);
  useEffect(() => {
    setLoading(true);
  }, [admin]);

  if (!loading) {
    return <Skeleton active />;
  }

  /**Delete this later */
  const showNewLandingPage = true;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#001D47",
        },
        components: {
          Switch: {
            colorPrimary: "#00def7",
          },
        },
      }}
      locale={deDE}
    >
      <Helmet>
        <title>EinGuterTipp</title>
        <meta
          name="description"
          content="Die einzige Software, die Du heute für Dein Empfehlungsmarketing brauchst! Jede Woche automatisiert top Empfehlungen erhalten."
        />
      </Helmet>

      <MessageProvider>
        <Router>
          <Routes>
            {/* Landing pages */}
            <Route
              path="/landingpage/:type/:id/:promoter_id"
              element={
                showNewLandingPage ? <NewLandingPage /> : <LandingPages />
              }
            />
            <Route
              path="/landingpage/:type/:id/:promoter_id/:promotion_link_id"
              element={
                showNewLandingPage ? <NewLandingPage /> : <LandingPages />
              }
            />

            {/* landing page questionare */}
            <Route
              path="/questionare/:type/:id/:promoter_id/:clickId"
              element={<MainQuestionare />}
            />

            {/** Landing page config */}
            {user?.user && (
              <Route
                path="/landingpageconfig/:type/:id"
                element={<LandingPageConfig />}
              />
            )}

            {/** Landing pages rechtliches */}
            <Route
              path="/impressum/:id/:promoter_id"
              element={<ImpressumLandingPages />}
            />
            <Route path="/impressum/:user_id" element={<ImpressumUser />} />
            <Route
              path="/teilnahmebedingungen/:user_id"
              element={<Teilnahmebedingungen />}
            />
            <Route
              path="/datenschutz/:id/:promoter_id"
              element={<DatenschutzLandingPages />}
            />

            {/** add landingpage*/}
            <Route path="/landingpagenew" element={<LandingPageNew />} />

            <Route
              path="/"
              // element={user?.user ? <Navigate to="/dashboard" /> : <Home />}
              element={<Home />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />

            {user?.user && (
              <Route path="/dashboard/*" element={<Dashboard />} />
            )}
            {!user?.user && <Route path="/dashboard/*" element={<Home />} />}

            <Route
              path="/promoterregistration/:id"
              element={<PromoterRegistration />}
            />
            <Route
              path="/promoter/emailConfirm2/:id"
              element={<Confirmation />}
            />

            {admin && (
              <Route path="/admindashboard/*" element={<AdminDashboard />} />
            )}
            {user?.user && (
              <Route
                path="/verifyemail/:token"
                element={<EmailVerification />}
              />
            )}

            <Route path="/verifyemail/:token" element={<EmailVerification />} />
            <Route path="/confirmemail/:id" element={<EmailComfirmation />} />

            <Route
              path="/promoter/emailConfirm/:promoter_id"
              element={<PromoterEmailVerification />}
            />

            <Route path="/promotersite/:token" element={<PromoterSite />} />
            <Route path="/impressum" element={<Impressum />} />

            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/agb" element={<AGB />} />
            <Route path="/danke" element={<Danke />} />
            <Route path="/erstgesprach" element={<Erstgesprach />} />
            <Route path="/startergesprach" element={<Startergesprach />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </MessageProvider>
    </ConfigProvider>
  );
};
export default Main;
