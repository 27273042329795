import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PublicService from "../../../service/PublicService";

import { Skeleton } from "antd";
import FormComponent from "../../LandingPageConfig/FormComponent";
import MovingHearts from "../../MovingHearts/MovingHearts";
const styles = {
  modelBg: "rgba(250, 250, 250, 0.30)",
  formBgOne: "transparent",
  formBgTwo: "white",
  font: "Manrope",
  rounded: true,
  buttonbg: "#001D47",
};
const Confirmation = () => {
  const { id } = useParams();
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    PublicService.erlaubnisPromoter(id).then(() => setConfirmed(true));
  }, []);

  return (
    <div>
      <MovingHearts />
      <div className="z-100">
        <FormComponent
          bg={styles.formBgOne}
          bg2={styles.formBgTwo}
          rounded={true}
          visible={true}
          style={{
            zIndex: "50",
            background: "rgba( 255, 255, 255, 0.85 )",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur( 4px )",
            "-webkit-backdrop-filter": " blur( 4px )",
            borderRadius: "10px",
            border: "1px solid rgba( 255, 255, 255, 0.18 )",
            minWidth: "350px",
            maxWidth: "600px",
          }}
        >
          {confirmed ? (
            <div>
              <div className="md:text-9xl text-xl font-semibold text-center md:w-1/2 mx-auto my-5">
                Vielen Dank! Schaue mal in Deinem Postfach nach.
              </div>
            </div>
          ) : (
            <div className="min-w-[300px]">
              <Skeleton active />
            </div>
          )}
        </FormComponent>
      </div>
    </div>
  );
};

export default Confirmation;
