import React from "react";

const ResponsiveBlueFace = () => {
  return (
    <div className="flex flex-col [background:linear-gradient(98.92deg,_#001d47,_#005ff8)] rounded-11xl my-32     font-h1  border-[2px] border-solid border-grey-accent box-border h-[950px]  primary:h-[419px]  justify-center items-center gap-10 md:gap-20  w-[95%] max-w-6xl mx-auto">
      <div className="w-full h-full flex primary:flex-row primary:justify-around flex-col mt-20  items-center">
        <div className="relative primary:h-full h-[200px] ">
          <b className="primary:text-primary text-white text-responsiveHeading">
            EinGuterTipp <br />
            Empfehlungs <span className="primary:inline hidden">-</span>
            <br className="primary:inline hidden" />
            marketing
          </b>

          <img
            className="absolute primary:top-[128px] primary:left-[-62px] scale-50 primary:scale-75   object-cover       top-[15px]
            right-[39px]"
            alt=""
            src="/images/morflaxstudio55@2x.png"
          />
          <img
            className="z-50 absolute primary:top-[-150.87px] scale-50 primary:scale-[0.6] primary:right-[-145px]  top-[-128px] right-[-100px]  primary:left-auto   object-cover"
            alt=""
            src="/images/morflaxstudio63@2x.png"
          />
          <img
            className="absolute primary:bottom-[60px]  scale-[0.25]  primary:right-[-100px]   object-cover bottom-[-61px] right-[-50px]"
            alt=""
            src="/images/morflaxstudio72@2x.png"
          />
        </div>
        <div className="relative     text-blue-main">
          {/* <YellowFace /> */}

          <div className="relative primary:h-[600px] text-center">
            <div className="relative secondary:scale-100 xl:scale-90 scale-75  rounded-481xl [background:linear-gradient(98.92deg,_#001d47,_#005ff8)] w-[420px] h-[420px] flex justify-center items-center">
              <img
                className=" m-auto w-[184px] h-[189px] object-cover"
                alt=""
                src="/images/morflaxstudio14-1@2x.png"
              />
              <div className="absolute top-[-30px]  whitespace-nowrap left-[-30px] text-[20px]  rounded-xl bg-white shadow-md overflow-hidden text-center py-[21px] px-[25px] items-start justify-start border-[1.5px] border-solid border-grey-accent  er  yellow-card-box-shadow primary:left-[-30px]">
                keine Nummern rausgeben
              </div>
              <div className="absolute top-[calc((52.5px*1)-30px)]  whitespace-nowrap max-w-[350px] text-[20px]  right-0 rounded-xl bg-white shadow-md overflow-hidden text-center py-[21px] px-[25px] items-start justify-start border-[1.5px] border-solid border-grey-accent    yellow-card-box-shadow primary:right-[-100px]">
                keinen Aufwand
              </div>
              <div className="absolute primary:top-[calc((52.5px*2)-30px)] top-[calc((230px+80px))] whitespace-nowrap text-[20px] max-w-[350px] left-[-30px] rounded-xl bg-white shadow-md overflow-hidden text-center py-[21px] px-[25px] items-start justify-start border-[1.5px] border-solid border-grey-accent  r  yellow-card-box-shadow primary:left-[-150px]">
                ungezwungen
              </div>
              <div className="absolute primary:top-[calc((52.5px*3)-30px)] top-[calc((350px))] whitespace-nowrap  text-[20px] right-[-30px] rounded-xl bg-white shadow-md overflow-hidden text-center py-[21px] px-[25px] items-start justify-start border-[1.5px] border-solid border-grey-accent    yellow-card-box-shadow primary:right-[-200px]">
                tolle Anreize
              </div>
              <div className="absolute primary:top-[calc((52.5px*4)-30px)] top-[calc((400px+30px))] whitespace-nowrap max-w-[350px] text-[20px] left-[0] rounded-xl bg-white shadow-md overflow-hidden text-center py-[21px] px-[25px] items-start justify-start border-[1.5px] border-solid border-grey-accent  r  yellow-card-box-shadow primary:left-[-170px]">
                offizielles Prämiensystem
              </div>

              <div className="absolute primary:top-[calc((52.5px*5)-30px)] top-[calc((500px))] whitespace-nowrap max-w-[350px] text-[20px] right-[0] rounded-xl bg-white shadow-md overflow-hidden text-center py-[21px] px-[25px] items-start justify-start border-[1.5px] border-solid border-grey-accent    yellow-card-box-shadow primary:right-[-170px]">
                kein Beigeschmack
              </div>
              <div className="absolute primary:top-[calc((52.5px*6))] top-[calc((600px-30px))]  whitespace-nowrap max-w-[350px]  text-[20px]  primary:left-auto left-[0] rounded-xl bg-white shadow-md overflow-hidden text-center py-[21px] px-[25px] items-start justify-start border-[1.5px] border-solid border-grey-accent    yellow-card-box-shadow primary:right-[-100px]">
                Keine Erklärungen
              </div>
              <div className="absolute primary:top-[calc((52.5px*7)-30px)] top-[calc((700px-30px))] whitespace-nowrap max-w-[350px] text-[20px] right-[0] rounded-xl bg-white shadow-md overflow-hidden text-center py-[21px] px-[25px] items-start justify-start border-[1.5px] border-solid border-grey-accent  r  yellow-card-box-shadow primary:left-[-100px]">
                keine Freunde ansprechen
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveBlueFace;
