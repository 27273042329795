import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LandingPageService from "../../service/LandingPageService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";

const Index = () => {
  const { id } = useParams();
  const [privacy, setPrivacy] = useState("");

  const getConditions = React.useCallback(async () => {
    try {
      const { data } = await LandingPageService.detail(id);

      setPrivacy(
        data?.specificPrivacyText?.length > 0
          ? data?.specificPrivacyText
          : data.user.defaultPrivacyText
      );
    } catch (error) {
      console.log(error);
    }
  }, [id]);
  useEffect(() => {
    getConditions();
  }, [getConditions]);
  function createMarkup() {
    return { __html: privacy };
  }

  if (!privacy) {
    return <LoadingScreen />;
  }
  return (
    <div className="p-5 wordbreak formattierterRechtsText">
      <h1 className="text-xl font-bold text-center">Datenschutz</h1>
      <div className="my-10" dangerouslySetInnerHTML={createMarkup()}></div>
    </div>
  );
};

export default Index;
