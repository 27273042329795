import React from "react";
import { LoadingOutlined } from '@ant-design/icons';

const Button = ({
  text,
  onClick,
  className,
  display = true,
  disabled = false,
  loading = false
}) => {
  if (display)
    return (
      <div
        className={`
          p-[12px_20px] rounded-[10px]  text-center  sm:text-xl text-base font-semibold bg-darkslateblue text-white cursor-pointer
          ${className}
          ${disabled ? " opacity-60 !cursor-not-allowed" : ""}
          `}
          onClick={!disabled ? onClick : undefined}
      >
        {text}
        {loading && <LoadingOutlined className="ml-2" />}
      </div>
    );
};

export default Button;
