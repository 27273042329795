import { Button, Card } from "antd";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useMessageContext } from "../../../../context/messageContext";
import Box from "../Layout/Box";
import MediaUpload from "../shared/MediaUpload";
import SwitchTitle from "../shared/SwitchTitle";

const ClosingVideoConfig = forwardRef(
  (
    {
      landingPageData: {
        closingVideo,
        closingVideoThumbnail,
        activateClosingVideo,
      },
      disabled,
    },
    ref
  ) => {
    const [closingVideoState, setClosingVideoState] = useState(closingVideo);
    const [activateClosingVideoState, setActivateClosingVideoState] =
      useState(activateClosingVideo);
    const [closingVideoThumbnailState, setClosingVideoThumbnailState] =
      useState(closingVideoThumbnail);
    const closingVideoRef = useRef(null);
    const closingVideoThumbnailRef = useRef(null);
    const { showMessage } = useMessageContext();

    useImperativeHandle(ref, () => ({
      async save() {
        if (disabled) return;
        console.log("ClosingVideoConfig.save()");

        const [newClosingVideoUrl, newClosingVideoThumbnail] =
          await Promise.all([
            closingVideoRef.current?.saveLocalToCloud?.(),
            closingVideoThumbnailRef.current?.saveLocalToCloud?.(),
          ]);

        const deactivate =
          activateClosingVideoState &&
          newClosingVideoUrl?.unchanged_secure_url === "";

        if (deactivate) {
          showMessage(
            "error",
            "Abschlussvideo deaktiviert: Bitte Video einfügen."
          );
          setActivateClosingVideoState(false);
        }

        return {
          landingPageData: {
            ...(newClosingVideoUrl.success && {
              closingVideo: newClosingVideoUrl.secure_url,
            }),
            ...(newClosingVideoThumbnail.success && {
              closingVideoThumbnail: newClosingVideoThumbnail.secure_url,
            }),
            activateClosingVideo: deactivate
              ? false
              : activateClosingVideoState,
          },
        };
      },
    }));

    return (
      <Box>
        <SwitchTitle
          checked={disabled ? false : activateClosingVideoState}
          onChange={(e) => {
            setActivateClosingVideoState(e);
          }}
        >
          Abschlussvideo
        </SwitchTitle>
        {disabled ? (
          <div className="h-[200px] relative">
            <div
              style={{ zIndex: 99999999999999999 }}
              className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-md"
            />
            <Card
              style={{ zIndex: 99999999999999999 }}
              className="absolute top-0 left-1/2 transform -translate-x-1/2 p-2 text-center"
            >
              <h2 className="text-2xl font-bold mb-4">Upgrade benötigt</h2>
              <p className="mb-4">
                Um auf diesen Bereich zugreifen zu können, musst du auf eine
                höhere Abonnementstufe upgraden.
              </p>
              <Link to="/dashboard/upgrade">
                <Button type="primary" className="bg-[#001D47] h-[40px] mr-2">
                  Upgrade
                </Button>
              </Link>
            </Card>
          </div>
        ) : (
          <>
            <MediaUpload
              ref={closingVideoRef}
              type="video"
              url={closingVideoState}
              setUrl={setClosingVideoState}
              id="closing-video"
              textSrcThere="Video ändern"
              textSrcNotThere="Video hochladen"
              full={true}
              emptyCheck={activateClosingVideoState}
            />
            <div className="text-base font-semibold text-center lg:text-9xl sm: sm:text-xl">
              Laden Sie ein ansprechendes Miniaturbild hoch, das zur Darstellung
              der Videovorschau verwendet wird
            </div>
            <MediaUpload
              ref={closingVideoThumbnailRef}
              url={closingVideoThumbnailState}
              setUrl={setClosingVideoThumbnailState}
              id="closing-video-thumbanail"
              textSrcThere="Miniaturbild ändern"
              textSrcNotThere="Miniaturbild hochladen"
              full={true}
              emptyCheck={activateClosingVideoState}
            />
          </>
        )}
      </Box>
    );
  }
);

export default ClosingVideoConfig;
