import React, { useState, useRef } from "react";
import {
  handlePlay,
  handlePause,
  handleEnded,
} from "../../../../utils/analytics";

const Video = ({
  url,
  className,
  textWhite = false,
  thumbnail,
  type,
  heading,
  textBackground,
  landingPageId,
  videoTextBG,
}) => {
  const withBackground = (set) => {
    return set ? "bg-black p-1 rounded-sm bg-opacity-50" : "";
  };

  // Google Analytics
  const videoRef = useRef();
  const [firstPlay, setFirstPlay] = useState(true);
  const [watchtime, setWatchtime] = useState(0);
  const googleAnalyticsHandler = (action) => {
    if (action === "play") {
      handlePlay(
        videoRef,
        setWatchtime,
        firstPlay,
        setFirstPlay,
        landingPageId,
        "v1",
        true,
        false
      );
    } else if (action === "pause") {
      handlePause(
        videoRef,
        watchtime,
        setWatchtime,
        landingPageId,
        "v1",
        true,
        false
      );
    } else if (action === "end") {
      handleEnded(
        videoRef,
        watchtime,
        setWatchtime,
        landingPageId,
        "v1",
        true,
        false
      );
    }
  };
  return (
    <div
      className={`${className} mx-auto text-center video-container xs:w-[500px] primary:w-[600px] secondary:w-[900px] ${
        textWhite ? "text-white" : "text-black"
      }`}
    >
      <p
        className={`mb-2 text-xl font-bold secondary:text-4xl secondary:mb-8 ${
          videoTextBG ? withBackground(heading != "") : ""
        }`}
      >
        {heading}
      </p>
      <video
        ref={videoRef}
        onPlay={() => googleAnalyticsHandler("play")}
        onPause={() => googleAnalyticsHandler("pause")}
        onEnded={() => googleAnalyticsHandler("end")}
        playsInline
        poster={thumbnail}
        width="600"
        controls
        className={`aspect-video w-[400px] ${
          type != "stormgas" && "rounded-sm"
        } w-full object-cover shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}
      >
        <source src={url} type="video/mp4" />
        <source src={url} type="video/webm" />
        <source src={url} type="video/quicktime" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
