import { Check, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import CrudService from "../../../service/CrudService";
import PublicService from "../../../service/PublicService";
import { sendEventEGT } from "../../../utils/analytics";
import CollapseItem from "./CollapseItem";
import ReviewSlider from "./ReviewSlider";
const items = [
  {
    title: "Muss ich etwas installieren?",
    desc: "Um EinGuterTipp nutzen zu können, brauchst du keine Software installieren. Du kannst es auf allen Geräten nutzen.",
  },
  {
    title: "Brauche ich das Coaching zwingend?",
    desc: "Nein. Mit der Nutzung erhältst Du Zugang zu einem sehr schlanken Videokurs mit Gesprächsleitfäden und 1:1 Umsetzungserklärungen, damit Du schnell und unkompliziert starten kannst. Mit dem kostenlosen Onboarding findest Du Dich auch umgehend zurecht. So hast du alles für einen guten Start. Das Coaching kannst du bei Bedarf jederzeit nachordern.",
  },
  {
    title: "Sind meine Kundendaten sicher?",
    desc: "Selbstverständlich. Deinen Daten liegen auf Deutschen und Europäischen Sicherheitsservern. So werden höchste Sicherheit und die DSGVO gewährleistet.",
  },
  {
    title: "Brauche ich dafür eine Webseite ?",
    desc: "Du brauchst weder eine Webseite, noch sonst eine digitale Präsens. Du brauchst auch keine besonderen Kenntnisse oder Fähigkeiten im Umgang mit Computern oder Software.",
  },
];

export const features = [
  { name: "Empfehlungsseiten", basic: "1", standard: "3", premium: "25" },
  { name: "Promoteranzehl", basic: "20", standard: "250", premium: "∞" },
  {
    name: "Benachrichtigungsystem",
    basic: true,
    standard: true,
    premium: true,
  },
  { name: "Einführungsvideos", basic: true, standard: true, premium: true },
  { name: "Prämiensystem", basic: true, standard: true, premium: true },
  {
    name: "Leitfaden Kundenansprache",
    basic: true,
    standard: true,
    premium: true,
  },
  { name: "Videokurs", basic: false, standard: true, premium: true },
  {
    name: "KundenEmpfehlungsPortal",
    basic: false,
    standard: true,
    premium: true,
  },
  { name: "Skripte & Leitfaden", basic: false, standard: true, premium: true },
  { name: "Kampagnen", basic: false, standard: true, premium: true },
  { name: "Leistungsformel", basic: false, standard: true, premium: true },
  {
    name: "Wöchentliche Livecalls",
    basic: false,
    standard: false,
    premium: true,
  },
  {
    name: "Seiten Einrichtungshilfe",
    basic: false,
    standard: false,
    premium: true,
  },
  { name: "Online Akademie", basic: false, standard: false, premium: true },
  { name: "Seitenstatistiken", basic: false, standard: false, premium: true },
  {
    name: "Linkindividualisierung",
    basic: false,
    standard: false,
    premium: true,
  },
  { name: "Ohne EGT Branding", basic: false, standard: false, premium: true },
];

export const tiers = [
  { name: "Basic", price: "39,00€", color: "bg-[#282828]" },
  { name: "Standard", price: "79,00€", color: "bg-[#0184f8]" },
  { name: "Premium", price: "149,00€", color: "bg-[#001d47]" },
];

const priceText = {
  month: "Du bist Dir unsicher und möchtest erstmal reinschnuppern",
  sixMonths: "Die goldene Mitte: Preislich attraktiv ohne lange Laufzeiten",
  twelveMonths:
    "Für Preisbewusste, die wissen was sie wollen und richtig durchstarten möchten",
};
const Index = () => {
  const googleAnalyticsHandler = (label) => {
    sendEventEGT({
      category: "button",
      action: `click-${label}`,
      label: label,
      value: 1,
    });
  };

  return (
    <div className=" rounded-[30px] bg-white" id="onboard">
      <div className="gray-bg-gradient sm:p-[6rem]  sm:w-[90%] w-[99%] m-auto">
        <h1 className="font-bold text-center xl:text-primary text-responsiveHeading sm:text-start ">
          <span>Wir machen Dich fit</span>
        </h1>
        <div className="my-10 sm:w-auto w-[80%] font-semibold text-[#868686] text-base sm:text-start text-center sm:text-[20px] sm:m-0 m-auto ">
          <p>
            Wichtiger als die Tatsache das Du unsere Software nutzt ist, dass du
            Erfolg hast. Deshalb bekommst Du nicht nur die Software, sondern
            auch ein Onboarding, damit Du dich direkt zurecht findest. Über
            unseren knackigen Videokurs erhältst Du eine Schritt für Schritt
            Anleitung für schnelle und erfolgreiche Umsetzung ohne unnötiges
            „blabla“
          </p>
        </div>

        <div className="my-10 sm:text-start text-center sm:w-auto w-[80%] m-auto">
          <h1 className="text-xl font-bold sm:text-primary">Der Ablauf</h1>
        </div>
        <div className="m-auto  w-[400px] xl:w-full rounded-[30px] relative flex flex-wrap px-[4rem] xl:flex-nowrap xl:gap-0 gap-20  bg-white justify-between overflow-hidden py-10">
          <div className="flex flex-col items-center justify-center w-full">
            <h1 className="text-center text-[20px] font-semibold">
              Erstgespräch
            </h1>
            <img
              src="/images/morflax-studio-18 1.png"
              alt=""
              className="mt-2 w-[250px]"
            />
          </div>
          <hr className="bg-[#D8D8D8] text-[#D8D8D8]  rotate-90 w-[80%] m-auto xl:block hidden" />
          <div className="flex flex-col items-center justify-center w-full">
            <h1 className="text-center text-[20px] font-semibold">
              <div>Nachdenken & </div>
              <div>richtig entscheiden</div>
            </h1>
            <img
              src="/images/settingchaticons.png"
              alt=""
              className="mt-10 w-[250px]"
            />
          </div>
          <hr className="bg-[#D8D8D8] text-[#D8D8D8] rotate-90 w-[80%] overflow-hidden m-auto xl:block hidden" />
          <div className="flex flex-col items-start w-full h-full ">
            <h1 className=" w-full text-[20px]  text-center font-semibold">
              Empfehlungen erhalten
            </h1>
            <div className="xl:hidden flex h-[400px]"></div>
            <img
              src="/images/myhandwithheart.png"
              className="mt-10 xl:w-[330px] w-[450px]  m-auto absolute  xl:right-0 xl:bottom-[-15%] bottom-0 right-0"
              alt=""
            />
          </div>
        </div>

        <div className="my-10 sm:text-start text-center sm:w-auto w-[80%] m-auto">
          <h1 className="text-xl font-bold sm:text-primary">
            Worauf Du zählen kannst!
          </h1>
          <div className="my-10 font-semibold text-base sm:text-xl text-[#868686] w-full">
            <p>
              Unser Support unterstützt Dich{" "}
              <img
                src="/images/logo.png"
                alt=""
                srcSet=""
                width="20"
                className="inline"
              />
              -voll bei all Deinen Fragen. So kannst Du direkt einfach und
              unkompliziert starten. Kein langes suchen, kein langes oder
              kompliziertes einarbeiten, sondern direkt loslegen!
            </p>
          </div>
        </div>
        <div className="my-10 grid xl:grid-cols-2 xl:grid-rows-2 grid-cols-1 grid-rows-4  gap-3 text-center text-base sm:text-xl font-semibold  sm:w-auto w-[80%] m-auto">
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Einfache Tutorials zum simplen nachmachen
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Schritt für Schritt Anleitungen aus der Praxis für die Praxis
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            1000fach erprobte Gesprächsvorlagen für die richtige Kommunikation
            mit Deinen Kunden
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Auch für Software-Hasser zu 100% geeignet
          </div>
        </div>
      </div>{" "}
      <div className="m-auto my-20  sm:w-[90%]   w-[80%] ">
        <div className="makeyoufit-card text-center font-bold xl:text-[46px] text-[26px] md:text-[32px] text-white xs:py-14 py-6 xs:px-16 px-0">
          <h1 className="w-[60%]  m-auto my-10">
            Stell Dir vor, Deine Empfehlungen würden sich bei Dir melden?
          </h1>
        </div>

        <div className="my-20 sm:mt-[14rem] flex-col flex relative">
          <div
            className="static block sm:hidden rounded-3xl aspect-square"
            style={{
              backgroundImage: "url(/images/frame-126@2x.png)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
            }}
          ></div>
          <div className="grub-dich-card secondary:w-[90%] w-full rounded-[30px] text-white sm:px-10 px-3 py-4 sm:py-12 flex justify-center">
            <div className="w-[95%] primary:mr-[15%] flex">
              <div className="w-full xl:w-3/4 secondary:w-full">
                <h1 className="font-bold text-white text-responsiveHeading sm:text-primary">
                  Grüß Dich!
                </h1>
                <p className="sm:text-[1.25rem] text-base w-auto font-[600] my-10 mt-5 text-[#D8D8D8] ">
                  “Seit 23 Jahren bin ich Finanzdienstleister mit Leib und
                  Seele. Alles was ich je erreicht hatte ist durch Empfehlungen
                  entstanden - keinen Bestand übernommen, keine Leads gekauft,
                  kein Onlinemarketing! Aber die Zeiten haben sich geändert,
                  Kunden taten sich zunehmend schwerer, ich kam mir wie ein
                  Bettler vor und die DSGVO schaffte neue Probleme. Relativ
                  schnell verstand ich, dass man Empfehlungsmarketing heute
                  völlig anders denken muss. So konnte ich mit meiner Erfahrung
                  aus über 2 Jahrzehnten Jahren Vertrieb und hunderten
                  begleiteten Vermittlern die Lösung der Zukunft entwickeln. Aus
                  genau diesem Grund gibt es EinGuterTipp! Ich würde mich freuen
                  dich sehr bald an Board begrüßen zu dürfen, damit wir auch für
                  Deine Neukundengewinnung ein nachhaltiges Fundament schaffen
                  können.”
                </p>
                <h2 className="flex items-start gap-2 my-4">
                  <img
                    src="/images/icon03-21.png"
                    alt="heart"
                    className="mt-1"
                  />
                  <p className="sm:text-[1.25rem] text-base font-[600]">
                    Sascha Emmerich, Gründer
                  </p>
                </h2>
              </div>
              <div className="items-center justify-center hidden w-0 xl:w-1/4 secondary:hidden xl:flex">
                <img
                  src="/images/frame-126@2x.png"
                  alt=""
                  className=" hidden secondary:hidden xl:block w-[400px] "
                />
              </div>
            </div>
            <img
              src="/images/frame-126@2x.png"
              alt=""
              className="secondary:absolute  secondary:block hidden  right-[0] top-[-100px] w-[300px] static"
            />
          </div>
        </div>
        <h1 className="my-10 font-bold sm:text-primary text-responsiveHeading">
          Häufige Fragen:
        </h1>
        <div className="transition-all ">
          {items.map((e, i) => (
            <CollapseItem key={i} {...e} />
          ))}
        </div>
        <div className="px-3 py-5 mt-[7rem] font-bold text-center text-white makeyoufit-second-card text-responsiveHeading xl:text-primary sm:py-10 sm:px-16 ">
          <div className="">
            <h1> Keine teuren Leads</h1>
            <h1>kaufen müssen um neue Kunden zu gewinnen.</h1>
          </div>
          <button
            onClick={() => googleAnalyticsHandler("reg2")}
            className="bg-[#FFD814] hover:bg-[#f7ca00] !text-[#000000] text-base sm:text-[20px] mt-10  py-[1.25rem] px-[2.1875rem] rounded-[3.125rem]"
          >
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.eingutertipp.de/register"
            >
              Jetzt Kunden gewinnen
            </a>
          </button>
        </div>
      </div>
      <div
        className="flex max-w-[90vw] sm:w-[90%] overflow-auto  w-[80%] secondary:flex-nowrap flex-wrap m-auto secondary:gap-10 gap-[1rem] secondary:p-0  justify-center sm:mt-[80px] hidden primary:block"
        id="pricing"
      >
        <div className="w-full  mx-auto space-y-6 flex mdx:flex-col items-start justify-center">
          {/* Header */}

          {/* Pricing Table */}
          <div className="rounded-[20px]  bg-[#0184f8] overflow-auto smx:pl-2">
            {/* Table Header */}
            <div className="grid grid-cols-4 mdx:grid-cols-[200px_1fr_1fr_1fr] min-w-[600px]">
              <div className="p-6 mdx:px-1 pb-0 mdx:min-w-[200px]">
                <h1 className="text-2xl mdx:text-lg font-semibold text-white">
                  Hauptlizenz
                </h1>
                <h2 className="text-xl mdx:text-md font-bold mt-8 !text-[#000000]">
                  Features
                </h2>
              </div>
              {[
                { name: "Basic", price: "39,00€" },
                { name: "Standard", price: "79,00€" },
                { name: "Premium", price: "149,00€" },
              ].map((tier) => (
                <div key={tier.name} className=" ">
                  <h3 className="font-bold text-white w-[180px] mdx:w-[100px] mx-2 mdx:mx-auto mt-8 mb-2 text-center">
                    {tier.name}
                  </h3>
                  <div className="bg-[#001d47] w-[180px] mdx:w-[100px] mdx:mx-auto rounded-t-[20px] p-6   mx-2">
                    <div className="text-white space-y-1">
                      <p className="text-xl font-bold flex justify-center whitespace-nowrap mdx:text-sm">
                        {tier.price}* mtl.
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Features */}
            <div className="min-w-[600px]">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  className="grid grid-cols-4 mdx:grid-cols-[200px_1fr_1fr_1fr]"
                >
                  <div className="px-6 mdx:px-1 py-1 text-white whitespace-nowrap">
                    {feature.name}
                  </div>
                  {[feature.basic, feature.standard, feature.premium].map(
                    (value, index) => (
                      <div
                        key={index}
                        className="bg-[#001d47] px-4 mdx:px-1 w-[180px] mdx:w-[100px] mdx:mx-auto py-1 mx-2 flex justify-center"
                      >
                        {typeof value === "boolean" ? (
                          value ? (
                            <Check className="w-6 h-6 text-green-400" />
                          ) : (
                            <X className="w-6 h-6 text-red-400" />
                          )
                        ) : (
                          <span className="text-white font-medium">
                            {value}
                          </span>
                        )}
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>

            {/* Footer */}
            <div className="grid grid-cols-4 mdx:grid-cols-[200px_1fr_1fr_1fr] min-w-[600px]">
              <div className="p-4" />
              {[1, 2, 3].map((i) => (
                <div
                  key={i}
                  className="bg-[#001d47] w-[180px] mdx:w-[100px] mdx:mx-auto rounded-b-[20px] mx-2 p-4"
                />
              ))}
            </div>
            <div className="p-4 text-sm text-[#000000] text-center font-bold min-w-[600px] ml-[215px]">
              *Preise zzgl. Mehrwertsteuer und einer Mindestlaufzeit von 12
              Monaten
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[85vw] mx-auto px-4 py-8 primary:hidden block">
        <div className="grid md:grid-cols-3 gap-8">
          {tiers.map((tier, tierIndex) => (
            <div
              key={tier.name}
              className={`rounded-2xl p-6 ${tier.color} text-white`}
            >
              <div className="mb-8">
                <h2 className="text-2xl font-bold mb-2">Tarife</h2>
                <h3 className="text-xl font-semibold mb-4">{tier.name}</h3>
                <p className="text-2xl font-bold">{tier.price}* mtl.</p>
              </div>

              <div className="space-y-4">
                <h4 className="font-bold text-lg">Features</h4>
                {features.map((feature) => (
                  <div
                    key={feature.name}
                    className="flex items-center justify-between"
                  >
                    <span className="mr-2">{feature.name}</span>
                    <span>
                      {typeof feature[tier.name.toLowerCase()] === "boolean" ? (
                        feature[tier.name.toLowerCase()] ? (
                          <Check className="w-5 h-5 text-green-400" />
                        ) : (
                          <X className="w-5 h-5 text-red-400" />
                        )
                      ) : (
                        feature[tier.name.toLowerCase()]
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <p className="text-center text-sm mt-6">
          *Preise zzgl. Mehrwertsteuer und einer Mindestlaufzeit von 12 Monaten
        </p>
      </div>
      <div className="flex secondary:w-[80%]  secondary:flex-nowrap flex-wrap m-auto secondary:gap-10 gap-[1rem] secondary:p-0  xl:px-[15rem] primary:px-20 justify-center sm:mt-[20px]">
        <button
          onClick={() => googleAnalyticsHandler("eg4")}
          className=" bg-[#FFD814] hover:bg-[#f7ca00] px-9 !text-[#000000]  animate-jump-in py-[10px] justify-center items-center flex text-center  secondary:py-[20px]   rounded-[50px] text-white font-[600] text-[20px] mt-8 "
        >
          <a href="/register">Jetzt kostenlos testen</a>
        </button>
      </div>
    </div>
  );
};

export default Index;
