import { removeToken } from "../../utils/LocalStorageHandler";
import { LOADING, LOGIN, LOGOUT } from "./constants";

export const login = (user) => ({
  type: LOGIN,
  payload: user,
});

export const logout = () => {
  removeToken();
  return {
    type: LOGOUT,
  };
};

export const setLoading = (loading) => ({
  type: LOADING,
  payload: loading,
});
