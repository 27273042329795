import { message } from "antd";
import * as XLSX from "xlsx";

export const exportToExcel = (data, name) => {
  try {
    if (data?.length === 0) {
      return message.info("Noch keine Daten");
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    XLSX.writeFile(workbook, !name ? "data" : name + ".xlsx");
    message.success("Exportiert");
  } catch (error) {
    console.log(error);
  }
};
