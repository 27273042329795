import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";
class AnalyticService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/analytic`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });

    middleField(this.api, true);
  }

  getUserAnalytics(landingpage_id) {
    return this.api.post("/getUserAnalytics", {
      landingPageId: landingpage_id,
    });
  }

  getEGTAnalytics() {
    return this.api.post("/getEGTAnalytics");
  }

  getAdminUserAnalytics() {
    return this.api.post("/getAdminUserAnalytics");
  }
}

export default new AnalyticService();
