import React, { useEffect, useRef } from "react";

const MovingHearts = () => {
  const canvasRef = useRef();
  useEffect(() => {
    const canv = canvasRef.current;
    canv.width = window.innerWidth;
    canv.height = window.innerHeight;

    window.addEventListener("resize", () => {
      canv.width = window.innerWidth;
      canv.height = window.innerHeight;
    });

    const c = canv.getContext("2d");
    const image = new Image();
    image.src = "/images/logo-04_dark-blue.png";
    class Hearts {
      constructor(x, y, dx, dy) {
        this.x = x;
        this.y = y;

        this.dx = dx;
        this.dy = dy;
      }
      draw() {
        c.beginPath();

        c.drawImage(image, this.x, this.y);
      }
      update() {
        if (this.x + 50 > window.innerWidth || this.x < 0) {
          this.dx = -this.dx;
        }
        this.x += this.dx;
        if (this.y + 50 > window.innerHeight || this.y < 0) {
          this.dy = -this.dy;
        }

        this.y += this.dy;
        this.draw();
      }
    }

    const heartsCollection = [];
    for (let i = 0; i < 30; i++) {
      const x = Math.random() * (window.innerWidth - 50);
      const y = Math.random() * (window.innerHeight - 50);

      const dx = Math.ceil(Math.random() * 2);
      const dy = Math.ceil(Math.random() * 2);

      heartsCollection.push(new Hearts(x, y, dx, dy));
    }

    const animate = () => {
      c.clearRect(0, 0, window.innerWidth, window.innerHeight);
      requestAnimationFrame(animate);
      heartsCollection.forEach((e) => e.update());
    };
    animate();
    return () => {
      window.removeEventListener("resize", () => {
        canv.width = window.innerWidth;
        canv.height = window.innerHeight;
      });
    };
  }, []);
  return (
    <canvas
      id="canvas"
      ref={canvasRef}
      className="fixed top-0 left-0 w-full h-full"
    />
  );
};

export default MovingHearts;
