import React from "react";
import Alert from "antd/es/alert/Alert";
import { PAGESKELETTON } from "../../assets/Images";
import { BsArrowLeft } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import LandingPageService from "../../service/LandingPageService";
import newLP from "../../assets/Images/newVorlage.png";
const data = {
  finance2: "Finanzberater",
  finance: "Finanzberater",
  stormgas: "Strom & Gas",
  property: "Immobilienfinanzierung",
  individual: "Individual Landingpage",
};

const designNames = {
  finance2: "Bullet",
};
const bgSeter = (bg) => {
  console.log(bg);
  return {
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "norepeat",
  };
};
const Index = () => {
  const navigate = useNavigate();
  const { user } = useSelector(selectUser);
  return (
    <div className="max-w-[2000px] flex justify-center w-full m-auto">
      <div className="w-full">
        <div className="flex w-10/12 m-auto justify-between items-center my-10 ">
          <div className="xl:text-primary text-xl cursor-pointer text-[#868686]">
            <BsArrowLeft onClick={() => navigate(-1)} />
          </div>
          <div>
            <img
              src="/images/logo.png"
              alt=""
              className="sm:w-[50px] w-[30px]"
            />
          </div>
          <div></div>
        </div>
        <div className="text-9xl text-center font-semibold">
          <h1>Wähle hier zunächst eine für Dich passende Vorlage aus.</h1>
          <h1>Anschließend kannst Du im Konfigurator Anpassungen vornehmen.</h1>
        </div>
        <Alert
          message="Der neue Landingpage-Konfigurator ist verfügbar - probiere unbedingt die neuen Features! Du kannst ab jetzt jede Design-Vorlage für jedes Thema nutzen. Aufgrund dieser Umstellung steht aktuell nur das Design Bullet zur Verfügung. Zwei weitere folgen kurzfristig. Vielen Dank für euer Verständnis."
          type="info"
          showIcon
          className="my-10 sm:w-[80%] w-[95%] text-center left-[50%] -translate-x-[50%]"
        />
        <div className="sm:w-[80%] w-[95%] m-auto flex flex-col sm:gap-[50px] gap-3 my-10">
          {Object.keys(data).map((e, i) => (
            <div
              onClick={async () => {
                const { data } = await LandingPageService.create({
                  primary_image: user?.avatar,
                  theme: e === "finance2" ? "finance" : e,
                  user_id: user?._id,
                  background_image: `/landingPageBgs/${
                    e === "finance" || e === "finance2"
                      ? e + ".svg"
                      : e + ".png"
                  }`,
                });
                navigate(
                  `/landingpageconfig/${e === "finance2" ? "finance" : e}/${
                    data._id
                  }`
                );
              }}
              style={
                e !== "finance2"
                  ? bgSeter(`/images/card-bg-${i}.png`)
                  : bgSeter(`/landingPageBgs/bullet.webp`)
              }
              className={` rounded-11xl relative ${
                e === "finance2"
                  ? "cursor-pointer"
                  : "cursor-not-allowed opacity-50 pointer-events-none"
              }`}
            >
              {e === "finance2" ? (
                <img
                  src={PAGESKELETTON}
                  className="h-[6.5rem] absolute -right-[2rem] top-2 sm:h-[12rem] sm:-right-[4.5rem] sm:-top-0 md:h-[15rem] md:-top-4 md:-rigth-[2rem] lg:h-[18rem] lg:-top-[2.5rem] lg:-right-[2rem] z-10"
                />
              ) : (
                ""
              )}

              {e !== "finance2" ? (
                <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-xl sm:text-3xl whitespace-nowrap text-yellow-500 z-10 font-bold animate-pulse drop-shadow-[0_0_15px_rgba(234,179,8,0.5)]">
                  WIRD GEUPDATET...
                </div>
              ) : (
                <div className="absolute -top-12 -left-11">
                  <img src={newLP} className="w-32 h-32" />
                </div>
              )}

              <div
                className={`h-full relative ${
                  e === "finance2"
                    ? ""
                    : "before:absolute before:inset-0 before:bg-[#000000] before:bg-opacity-50 before:backdrop-brightness-50 before:rounded-11xl"
                } sm:p-10 p-5`}
              >
                <h1
                  className={`sm:text-primary text-responsiveHeading ${
                    e === "finance2" ? "text-[#000000]" : "text-white"
                  } font-bold`}
                >
                  {designNames[e] ? "Design: " + designNames[e] : data[e]}
                </h1>
                <p
                  className={`sm:text-9xl text-xl ${
                    e === "finance2" ? "text-[#000000]" : "text-white"
                  } font-semibold`}
                >
                  {"Vorlage " + data[e]}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
