import React, { forwardRef, useImperativeHandle, useState } from "react";
import Box from "../Layout/Box";
import Inputs from "../../shared/Input";

const NameConfig = forwardRef(({ landingPageData: { name } }, ref) => {
  const [nameState, setNameState] = useState(name);

  useImperativeHandle(ref, () => ({
    async save() {
      console.log("NameConfig.save()");
      return {
        landingPageData: {
          name: nameState,
        },
      };
    },
  }));

  return (
    <Box>
      <div className="flex flex-col primary:flex-row gap-[30px]">
        <div className="w-full text-base font-semibold text-center primary:flex-1 lg:text-9xl sm:text-xl primary:text-left">
          Benenne deine Landing Page, um sie wieder zu finden.
        </div>
        <div className="primary:flex-1">
          <Inputs
            value={nameState}
            onChange={(e) => setNameState(e.target.value)}
            label={"Name"}
          />
        </div>
      </div>
    </Box>
  );
});

export default NameConfig;
