import { Button, Divider, Skeleton, Space } from "antd";
import React, { useState } from "react";
import {
  AiFillCaretLeft,
  AiOutlinePlayCircle,
  AiTwotonePlayCircle,
} from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import PublicService from "../../service/PublicService";

const Chapter = () => {
  const [newvideo, setnewvideo] = React.useState();
  const [tutorial, setTutorial] = useState(null);
  const navigate = useNavigate();
  const getTutorialById = async (id) => {
    try {
      const { data } = await PublicService.detailTutorial(id);
      setTutorial({ ...data });
      setnewvideo(data?.video_src?.[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const { id } = useParams();

  const clickhandle = (element) => {
    navigate(`/dashboard/video/${id}/${element._id}`);
  };

  React.useEffect(() => {
    getTutorialById(id);
  }, [id]);
  if (!tutorial) {
    return <Skeleton active />;
  }
  return (
    <div className=" flex-col  flex xl:p-4 p-0 gap-14 ">
      <Space
        className="mb-4 flex items-center cursor-pointer"
        onClick={() => navigate("/dashboard/tutorials")}
      >
        <AiFillCaretLeft size={25} />
        <h1 className="font-bold text-xl">Kapitel: {tutorial?.title}</h1>
      </Space>

      <div className="xl:p-4 p-0">
        <h1 className="text-xl font-bold">Lektionen</h1>
        <div className="my-2 flex justify-start gap-2 flex-wrap ">
          {tutorial?.video_src?.map((element, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  clickhandle(element);
                }}
                className="cursor-pointer bg-[#fafafa] p-2 rounded-2xl shadow-inner"
              >
                <video
                  src={element?.url}
                  poster={element?.thumbnail}
                  className="rounded-xl max-w-full h-[200px] min-w-full bg-slate-500 cursor-pointer"
                />
                <div>
                  <div className="font-bold flex  items-center text-ellipsis mt-3 whitespace-nowrap">
                    <AiOutlinePlayCircle />
                    {element?.title}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Chapter;
