import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FloatLabel from "../../FloatingLabel";

const HTMLTextEditor = ({
  quillattributes,
  value = "",
  onChange = () => {},
  label = "",
  variables,
  options = {},
}) => {
  const textBeautifier = (value) => {
    let newText = value;
    for (let values of variables) {
      newText = newText.replaceAll(
        values,
        `<span style="color:#00def7">${values}</span>`
      );
    }
    return newText;
  };

  return (
    <div {...quillattributes}>
      <FloatLabel
        label={label}
        keepitfocused
        focusClass="!text-base font-semibold text-[#D8D8D8]  ms-[5px]"
      >
        <ReactQuill
          // value={textBeautifier(value)}
          value={value}
          onChange={onChange}
          {...options}
        />
      </FloatLabel>
    </div>
  );
};

export default HTMLTextEditor;
