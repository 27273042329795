import { Button, Skeleton } from "antd";
import React, { useRef, useState } from "react";
import {
  BsArrowDown,
  BsArrowLeft,
  BsArrowRight,
  BsArrowUp,
  BsTrash3,
} from "react-icons/bs";
import { RiEditBoxLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import AdminService from "../../../service/AdminService";
import PublicService from "../../../service/PublicService";
import { PopUpComponent } from "../../ConfirmPopup";

const Index = () => {
  const navigate = useNavigate();
  const [tutorials, setTutorials] = useState(null);
  const getTutorials = async () => {
    const { data } = await AdminService.getTutorials();
    setTutorials(data);
  };
  React.useEffect(() => {
    const getTutorials = async () => {
      const { data } = await AdminService.getTutorials();
      const sortedData = data.sort((a, b) => a.ranking - b.ranking);
      setTutorials(sortedData);
    };
    getTutorials();
  }, []);

  const parentWrapper = useRef();

  if (!tutorials) {
    return (
      <div className="d-flex ">
        <Skeleton active />
      </div>
    );
  }

  const handleMove = async (oldIdx, newIdx) => {
    try {
      const { data } = await AdminService.changeRanking({ oldIdx, newIdx });
      if (data.error) return;
      const sortedData = data.sort((a, b) => a.ranking - b.ranking);
      setTutorials(sortedData);
      console.log("replied:", data);
    } catch {}
  };

  const handleDeleteTutorial = async (idx) => {
    try {
      const { data } = await AdminService.deleteTutorial(idx);
      const sortedData = data.sort((a, b) => a.ranking - b.ranking);
      setTutorials(sortedData);
    } catch {}
  };

  const moveTutorial = (direction, idx) => {
    const childWidth = parentWrapper.current.children[0].offsetWidth;
    const parentWidth = parentWrapper.current.offsetWidth;
    let arrangeMode = 1,
      changeIdx = -1;
    if (childWidth * 2 <= parentWidth) {
      arrangeMode = 2;
    }
    switch (direction) {
      case "UP":
        if (idx < arrangeMode) break;
        changeIdx = idx - arrangeMode;
        break;
      case "LEFT":
        if (idx % arrangeMode === 0) break;
        changeIdx = idx - 1;
        break;
      case "RIGHT":
        if (idx % arrangeMode === 1 || arrangeMode === 1) break;
        changeIdx = idx + 1;
        break;
      case "DOWN":
        changeIdx = idx + arrangeMode;
        break;
      default:
        break;
    }
    if (changeIdx < 0) return;
    handleMove(idx, changeIdx);
  };

  return (
    <div className="flex flex-col gap-[35px]">
      <div
        className="grid  items-center gap-[35px] lg:grid-cols-2 grid-cols-1"
        ref={parentWrapper}
      >
        {tutorials?.map((e, i) => {
          return (
            <div
              className="video-card-two rounded-xl overflow-hidden w-full"
              key={i}
              style={{
                backgroundImage: `url(${e?.thumbnail})`,
                position: `relative`,
              }}
            >
              <div
                className="left-0 top-0 absolute flex justify-center items-center"
                style={{
                  width: `100%`,
                  height: `100%`,
                }}
              >
                <div style={{ zIndex: `1001` }}>
                  <Button
                    className="bg-green-600  text-center border-none text-white font-medium p-0 flex justify-center items-center h-[42px] w-[42px] ml-[42px]"
                    onClick={(e) => {
                      e.preventDefault();
                      moveTutorial("UP", i);
                    }}
                  >
                    <BsArrowUp width={16} height={16} fontSize={16} />
                  </Button>
                  <div className="flex space-x-[42px]">
                    <Button
                      className="bg-green-600  text-center border-none text-white font-medium p-0 flex justify-center items-center h-[42px] w-[42px]  "
                      onClick={(e) => {
                        e.preventDefault();
                        moveTutorial("LEFT", i);
                      }}
                    >
                      <BsArrowLeft width={16} height={16} fontSize={16} />
                    </Button>
                    <Button
                      className="bg-green-600  text-center border-none text-white font-medium p-0 flex justify-center items-center h-[42px] w-[42px]  "
                      onClick={(e) => {
                        e.preventDefault();
                        moveTutorial("RIGHT", i);
                      }}
                    >
                      <BsArrowRight width={16} height={16} fontSize={16} />
                    </Button>
                  </div>
                  <Button
                    className="bg-green-600  text-center border-none text-white font-medium p-0 flex justify-center items-center h-[42px] w-[42px] ml-[42px]"
                    onClick={(e) => {
                      e.preventDefault();
                      moveTutorial("DOWN", i);
                    }}
                  >
                    <BsArrowDown width={16} height={16} fontSize={16} />
                  </Button>
                </div>
              </div>
              <div className="  min-h-[400px]   flex justify-between flex-col backdrop-brightness-75  py-[25px] px-[50px] ">
                <div className="text-base sm:text-xl lg:text-primary text-white font-bold">
                  {e?.title}
                </div>
                <div className="flex items-center gap-4 sm:flex-nowrap flex-wrap">
                  <Button
                    className="bg-blue-main  text-center border-none text-white font-medium p-0 flex justify-center items-center h-[42px] w-[42px]  "
                    onClick={() =>
                      navigate(
                        "/admindashboard/uploadtutorials?tutorial=" + e?._id
                      )
                    }
                  >
                    <RiEditBoxLine width={16} height={16} fontSize={16} />
                  </Button>
                  <Button className="bg-redattendtion text-center border-none text-white font-medium p-0  flex justify-center items-center h-[42px] w-[42px] ">
                    <BsTrash3
                      width={16}
                      height={16}
                      fontSize={16}
                      onClick={() =>
                        PopUpComponent({
                          onConfirm: async () => {
                            await AdminService.deleteTutorial(e?._id);
                            getTutorials();
                          },
                          heading:
                            "Sind Sie sicher, dass Sie dieses Kapitel löschen möchten?",
                          confirmButtonText: "Löschen",
                          cancelButtonText: "Stornieren",
                        })
                      }
                    />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div className="video-card-three w-full   rounded-xl overflow-hidden ">
          <div className="  min-h-[400px]  flex justify-between flex-col backdrop-brightness-75  py-[25px] px-[50px] ">
            <div className="flex items-center gap-4 sm:flex-nowrap flex-wrap sm:flex-nowrap flex-wrap">
              <Button className="bg-white text-[#979797] font-medium text-[14px] w-[32px] h-[35px] p-0">
                +
              </Button>
            </div>
            <div className="text-base sm:text-xl lg:text-primary text-white font-bold">
              Kampagnen <br className="lg:block hidden" />
              optimieren
            </div>
            <div className="flex items-center gap-4 sm:flex-nowrap flex-wrap">
              <Button
                className="bg-white  font-medium text-[14px] h-[42px]"
                type="primary"
              >
                Neues Thumbnail
              </Button>

              <Button className="bg-blue-main  text-center border-none text-white font-medium p-0 flex justify-center items-center h-[42px] w-[42px]  ">
                <RiEditBoxLine width={16} height={16} fontSize={16} />
              </Button>
              <Button className="bg-redattendtion text-center border-none text-white font-medium p-0  flex justify-center items-center h-[42px] w-[42px] ">
                <BsTrash3 width={16} height={16} fontSize={16} />
              </Button>
            </div>
          </div>
        </div> */}
      </div>
      <Button
        type="primary"
        onClick={() => navigate("/admindashboard/uploadtutorials")}
        className=" text-xl font-sembold w-full h-[65px] rounded-2xl"
      >
        Neues Tutorial hochladen
      </Button>
    </div>
  );
};

export default Index;
