import React, { createContext, useContext } from "react";
import { message } from "antd";

const MessageContext = createContext(null);
export const useMessageContext = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <MessageContext.Provider
      value={{
        showMessage: (type, message, duration = 6, key) => {
          messageApi.open({
            type: type,
            content: message,
            duration: duration,
            key: key,
          });
        },
        clearMessage: (key) => {
          messageApi.destroy(key);
        },
      }}
    >
      {contextHolder}
    {children}
    </MessageContext.Provider>
  );
};
