import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP";
import MovingHearts from "../../MovingHearts/MovingHearts";

const Danke = () => {
  useEffect(() => {
    window.fbq("track", "Lead");
  }, []);
  return (
    <div>
      <MovingHearts />
      <div className="flex justify-center items-center flex-col text-center h-full relative z-40">
        <style>
          {`
            #root{
                position:relative;
                height:100vh;
                display:flex;
                justify-content:center;
                align-items:center;
                overflow:hidden;
                
            }
            
            
            
            `}
        </style>
        <div className="font-bold backdrop-blur p-10 rounded-xl	flex flex-col justify-center items-center gap-5	">
          <div>
            Wir haben den Termin mit Dir eingetragen und nehmen uns die Zeit für
            Deine Zukunft. Plane hier bitte 30min plus eine kleine Reserve mit
            ein.
          </div>
          <div>
            <button className="w-[283px] whitespace-nowrap bg-[#001D47]  py-[10px] sm:mr-3  justify-center items-center flex text-center  secondary:py-[20px]   rounded-[50px] text-white font-[600] text-[20px] mt-3">
              <a href={window.location.origin}>Zur Homepage</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Danke;
