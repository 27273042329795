import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import AnalyticService from "../../../service/AnalyticService";
import "./LandingPageTable.css";
const LandingPageTable = () => {
  const [searchByName, setSearchByName] = useState("");
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const getUserAnalytics = async () => {
      try {
        const { data } = await AnalyticService.getAdminUserAnalytics();
        setStats(data.data);
        console.log(data.data);
      } catch (error) {
        console.error("Error fetching analytics:", error);
      }
    };
    getUserAnalytics();
  }, []);

  const generateColumn = (title, dataIndex, sorter = true) => ({
    title,
    dataIndex,
    width: 220,
    sorter: sorter
      ? (a, b) =>
          typeof a[dataIndex] === "string"
            ? a[dataIndex].localeCompare(b[dataIndex])
            : a[dataIndex] - b[dataIndex]
      : undefined,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      onFilter: (value, record) =>
        record.name
          ? record.name.toLowerCase().includes(value.toLowerCase())
          : false,
      filteredValue: searchByName ? [searchByName] : null,
    },
    generateColumn("Landing Page", "landingPage"),
    generateColumn("Aktive Nutzer", "user"),
    generateColumn("Aufrufe", "views"),
    generateColumn("Clicks Kernvideo", "clicksVideo1"),
    generateColumn("Clicks Abschlussvideo", "clicksVideo2"),
    generateColumn("Watchtime Kernvideo", "watchTime1"),
    generateColumn("Watchtime Abschlussvideo", "watchTime2"),
    generateColumn("Whatsapp Sektion 1", "whatsapp1", false),
    generateColumn("Whatsapp Sektion 2", "whatsapp2", false),
    generateColumn("Whatsapp Formular", "whatsapp3", false),
    generateColumn("Fragen ansehen", "questionform", false),
    generateColumn("Frage 1", "question1", false),
    generateColumn("Frage 2", "question2", false),
    generateColumn("Frage 3", "question3", false),
    generateColumn("Frage 4", "question4", false),
    generateColumn("Frage 5", "question5", false),
    generateColumn("Frage 6", "question6", false),
    generateColumn("Frage 7", "question7", false),
    generateColumn("Frage 8", "question8", false),
  ];
  const toPercent = (clickrate, pageviews) => {
    if (clickrate !== 0) {
      return Math.round((clickrate / pageviews) * 100);
    }
    return 0;
  };
  const data = stats.map((d) => ({
    key: d.lpId,
    name: d.username,
    user: d.activeUsers,
    views: d.page_viewLP,
    landingPage: d.lpName,
    clicksVideo1: d.Play_v1 + "%",
    clicksVideo2: d.play_v2 + "%",
    watchTime1: d.Watchtime_v1 + "%",
    watchTime2: d.watchtime_v2 + "%",
    whatsapp1: toPercent(d.Click_wa1, d.page_viewLP) + "%",
    whatsapp2: toPercent(d.Click_wa2, d.page_viewLP) + "%",
    whatsapp3: toPercent(d.Click_wa3, d.page_viewLP) + "%",
    questionform: toPercent(d.click_qf, d.page_viewLP) + "%",
    question1: toPercent(d.click_q1, d.page_viewLP) + "%",
    question2: toPercent(d.click_q2, d.page_viewLP) + "%",
    question3: toPercent(d.click_q3, d.page_viewLP) + "%",
    question4: toPercent(d.click_q4, d.page_viewLP) + "%",
    question5: toPercent(d.click_q5, d.page_viewLP) + "%",
    question6: toPercent(d.click_q6, d.page_viewLP) + "%",
    question7: toPercent(d.click_q7, d.page_viewLP) + "%",
    question8: toPercent(d.click_q8, d.page_viewLP) + "%",
  }));

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log("Table params:", pagination, filters, sorter, extra);
  };

  return (
    <div
      className="self-start w-full  custom-scrollbar"
      style={{ overflowX: "auto" }}
    >
      <Input
        placeholder="Filter by name"
        onChange={(e) => setSearchByName(e.target.value)}
        value={searchByName}
        style={{ marginBottom: 16, width: 200 }}
      />
      <Table
        className="text-nowrap"
        pagination={{ position: ["bottomLeft"] }}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        tableLayout="fixed"
      />
    </div>
  );
};

export default LandingPageTable;
