import { Alert, Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectLoading } from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService.js";
import UserService from "../../service/UserService.js";

const EmailConfirmInfo = ({ title }) => {
  useEffect(() => {
    title("Bestätigung");
    setTimeout(() => title("Bestätigung"), 200);
  }, []);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const checkInterval = useRef();
  useEffect(() => {
    checkInterval.current = setInterval(async () => {
      const me = await UserService.me();

      const confirmed = !!me?.data?.user?.emailConfirmed;
      if (confirmed) {
        clearInterval(checkInterval.current);
        navigate("/dashboard/upgrade");
      }
    }, 2000);
    return () => clearInterval(checkInterval.current);
  }, []);

  return (
    <div className="w-full h-[400px] flex justify-center items-center">
      <div className="max-w-[275px]">
        <Alert
          type="info"
          className=""
          message="Schön, dass du dich registriert hast! Überprüfe nun Deine E-Mails, und klicke auf den Aktivierungslink um Deine E-Mail Adresse zu bestätigen."
        />
        <Button
          type="primary"
          className="mt-5 max-w-full"
          loading={loading}
          onClick={async () => {
            if (loading) return;
            setLoading(true);
            try {
              await AuthService.confirmEmailSend();
            } catch (e) {
            } finally {
              setLoading(false);
            }
          }}
        >
          Bestätigungsemail nochmal schicken
        </Button>
      </div>
    </div>
  );
};

export default EmailConfirmInfo;
