import { Button, message, Popconfirm, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { UploadIcon } from "../../../assets/Svgs/UploadIcon";
import ImageUploadService from "../../../service/UploadToCloudService";
import { inputFileTypeValidator } from "../../../utils/InputFileTypeValiator";
import { PopUpComponent } from "../../ConfirmPopup";

import FloatingLabel from "../../FloatingLabel";
import VideoUploadWidget from "../../VideoUploadWidget";

import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AdminService from "../../../service/AdminService";
import PublicService from "../../../service/PublicService";
import UploadToCloudService from "../../../service/UploadToCloudService";
import CustomImageLoader from "../../CustomImageLoader/CustomImageLoader";
import CollapseItem from "../../_NEW/LandingPageConfig/shared/CollapseItem";

const UploadTutorials = () => {
  const [id, setId] = useState("");
  const location = window.location.href;
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [fileName, setFileName] = useState("");
  const [tutorial, setTutorial] = useState({
    new: true,
    title: "",
    tags: [],
    thumbnail: "",
    hidden: false,
    video_src: [
      // {  title: String,
      //   url: String,
      // thumbnail:String
      // pdfs:[String]
      // }
    ],
  });
  const updateMessage = ({ type, key }) => {
    return {
      open: () =>
        messageApi.open({
          key,
          type: "loading",
          content: "Hochladen",
          duration: 0,
        }),
      close: () =>
        messageApi.open({
          key,
          type: "success",
          content: "Hochgeladen!",
          duration: 2,
        }),
      error: () =>
        messageApi.open({
          key,
          type: "error",
          content: "Etwas ist schief gelaufen!",
          duration: 2,
        }),
    }[type];
  };
  const handleManualUpload = async (callback) => {
    setTutorial(callback);
    await AdminService.updateTutorial(id, callback(tutorial));
  };
  const handleVideoupload = async (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (!inputFileTypeValidator(e)) {
      return;
    }
    updateMessage({ type: "open", key: "videoupload" })?.();
    const { secure_url } = await ImageUploadService.upload(
      e.target.files[0]
    ).catch(() => {
      updateMessage({ type: "error", key: "videoupload" })?.();
    });
    updateMessage({ type: "close", key: "videoupload" })?.();
    return secure_url;
  };
  const handleImageUpload = async (e, key, overrideSetterCallback) => {
    if (!e.target.files[0]) {
      return;
    }
    if (!inputFileTypeValidator(e)) {
      return;
    }
    try {
      updateMessage({ type: "open", key })?.();
      const { secure_url } = await ImageUploadService.upload(e.target.files[0]);

      if (overrideSetterCallback) {
        updateMessage({ type: "close", key })?.();
        return setTutorial((prev) => overrideSetterCallback(prev, secure_url));
      }
      setTutorial((prev) => ({ ...prev, [key]: secure_url }));
      updateMessage({ type: "close", key })?.();
    } catch (error) {
      console.log(error);
      updateMessage({ type: "error", key })?.();
    }
  };
  useEffect(() => {
    const getTutorialById = async (id) => {
      try {
        if (id === "undefined") return;

        setId(id);
        const { data } = await PublicService.detailTutorial(id);
        setTutorial({ ...data, new: false });
      } catch (error) {
        console.log(error);
      }
    };
    const id = location?.split?.("?")?.[1]?.split?.("=")?.[1];
    if (id) {
      getTutorialById(id);
    }
  }, [location]);
  const handleSave = async () => {
    if (tutorial.new) {
      const payload = {
        ...tutorial,
        video_src: [...tutorial.video_src].map((e, idx) => ({
          url: e.url,
          title: e.title,
          description: e.description,
        })),
      };
      const { data } = await AdminService.createTutorial(payload);
      navigate("/admindashboard/uploadtutorials?tutorial=" + data._id);
    } else {
      await AdminService.updateTutorial(id, tutorial);
    }
  };

  const moveVideo = (dir, data) => {
    let tmp,
      tmp_tutorial = tutorial;
    switch (dir) {
      case "UP":
        if (data === 0) break;
        tmp = tmp_tutorial.video_src[data - 1];
        tmp_tutorial.video_src[data - 1] = tmp_tutorial.video_src[data];
        tmp_tutorial.video_src[data] = tmp;
        break;
      case "DOWN":
        if (data === tmp_tutorial.video_src.length - 1) break;
        tmp = tmp_tutorial.video_src[data + 1];
        tmp_tutorial.video_src[data + 1] = tmp_tutorial.video_src[data];
        tmp_tutorial.video_src[data] = tmp;
        break;
      default:
        break;
    }
    setTutorial({ ...tmp_tutorial });
  };
  const handlePdfUpload = async (e, _id) => {
    try {
      updateMessage({ key: _id, type: "open" })?.();
      const pdf = e.target.files[0];
      const { secure_url } = await UploadToCloudService.upload(pdf);

      handleManualUpload((prev) => ({
        ...prev,
        video_src: prev.video_src.map((e) => {
          if (e._id !== _id) {
            return e;
          } else {
            return {
              ...e,
              pdfs: e?.pdfs
                ? [...e?.pdfs, { name: pdf?.name, url: secure_url }]
                : [{ name: pdf?.name, url: secure_url }],
            };
          }
        }),
      }));
      updateMessage({ key: _id, type: "close" })?.();
    } catch (error) {
      updateMessage({ key: _id, type: "error" })?.();
    }
  };
  const pdfNameChange = (ce, i, _) =>
    handleManualUpload((prev) => ({
      ...prev,
      video_src: prev?.video_src?.map((e) => {
        if (e._id !== _._id) {
          return e;
        } else {
          return {
            ...e,
            pdfs: e?.pdfs?.map((e, mi) => {
              if (mi !== i) {
                return e;
              } else {
                return { ...e, name: ce };
              }
            }),
          };
        }
      }),
    }));
  const pdfDelete = async (i, _) => {
    try {
      handleManualUpload((prev) => ({
        ...prev,
        video_src: prev?.video_src?.map((e) => {
          if (e._id !== _._id) {
            return e;
          } else {
            return {
              ...e,
              pdfs: e?.pdfs?.filter((e, mi) => {
                return mi !== i;
              }),
            };
          }
        }),
      }));
    } catch (error) {}
  };

  return (
    <div className="max-w-[2000px] m-auto">
      {contextHolder}
      <div>
        <div className="rounded-11xl bg-[#fafafa] grid gap-10 p-5">
          <div className="grid gap-10 primary:grid-cols-2 grid-col-1">
            <div className="text-base font-semibold lg:text-9xl sm: sm:text-xl">
              Laden Sie ein ansprechendes Miniaturbild hoch, das zur Darstellung
              des Kapitels verwendet wird
            </div>
            <div className="rounded-11xl bg-white min-h-[200px] overflow-hidden">
              <input
                type="file"
                id="upload-UploadTutorials-first"
                accept="image/*"
                className="hidden"
                onChange={(e) => handleImageUpload(e, "thumbnail")}
              />
              {tutorial?.thumbnail?.length < 5 ? (
                <label
                  htmlFor="upload-UploadTutorials-first"
                  className="flex items-center flex-col h-[200px] select-none justify-center cursor-pointer"
                >
                  <img src="/images/icons-8-hochladen-1.png" alt="" />
                  <p className="text-base font-semibold  sm:text-xl">
                    Foto hochladen
                  </p>
                </label>
              ) : (
                <label
                  htmlFor="upload-UploadTutorials-first"
                  className="h-[200px] relative  rounded-11xl overflow-hidden "
                >
                  <div
                    style={{
                      width: "100%",
                      height: "200px",
                      backgroundImage: `url(${tutorial.thumbnail})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="h-[200px] top-0 absolute cover-upload-bg-second  w-full flex justify-end items-end">
                    <div className="flex flex-col items-center justify-center m-6 text-white  sm:m-10">
                      <UploadIcon />
                      <p className="text-base font-semibold text-white  sm:text-xl">
                        Miniaturansicht hochladen
                      </p>
                    </div>
                  </div>
                </label>
              )}
            </div>
          </div>
          <div className="grid gap-10 primary:grid-cols-2 grid-col-1">
            <div className="text-base font-semibold lg:text-9xl sm: sm:text-xl">
              Titel des Kapitels
            </div>
            <div className="flex flex-wrap items-center justify-between gap-5 secondary:gap-10 xl:flex-nowrap ">
              <FloatingLabel
                label={"Title"}
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={" flex-grow"}
                value={tutorial?.title}
              >
                <input
                  type="text"
                  onChange={(e) =>
                    setTutorial((prev) => ({ ...prev, title: e.target.value }))
                  }
                  value={tutorial?.title}
                  className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
                />
              </FloatingLabel>
            </div>
          </div>
          <div className="grid gap-10 primary:grid-cols-2 grid-col-1">
            <div className="text-base font-semibold lg:text-9xl sm: sm:text-xl">
              Ausblenden (Dadurch wird das Kapitel für Benutzer ausgeblendet)
            </div>
            <div className="flex flex-wrap items-center justify-between gap-5 trkisswitch secondary:gap-10 xl:flex-nowrap ">
              <Switch
                checked={tutorial?.hidden}
                onChange={() =>
                  setTutorial((prev) => {
                    return { ...prev, hidden: !prev.hidden };
                  })
                }
              />
            </div>
          </div>
        </div>

        {tutorial?.video_src?.map((_, idx) => (
          <CollapseItem
            title={_.title || `Video ${idx + 1}`}
            key={idx}
            moveVideo={moveVideo}
            data={idx}
          >
            <div className="grid gap-10 p-5 transition-all grid-col-1">
              <div>
                <FloatingLabel keepitfocused label={"Titel"}>
                  <input
                    className="border-[1px] border-[#d6d6d6] rounded-mini p-[20px] w-full"
                    value={_.title}
                    onChange={(ce) => {
                      setTutorial((prev) => ({
                        ...prev,
                        video_src: prev.video_src.map((e, idx_inner) => {
                          if (idx_inner === idx) {
                            return { ...e, title: ce.target.value };
                          } else {
                            return e;
                          }
                        }),
                      }));
                    }}
                  />
                </FloatingLabel>
              </div>

              <div>
                <FloatingLabel keepitfocused label={"Beschreibung"}>
                  <input
                    className="border-[1px] border-[#d6d6d6] rounded-mini p-[20px] w-full"
                    value={_.description}
                    onChange={(ce) => {
                      setTutorial((prev) => ({
                        ...prev,
                        video_src: prev.video_src.map((e, idx_inner) => {
                          if (idx_inner === idx) {
                            return { ...e, description: ce.target.value };
                          } else {
                            return e;
                          }
                        }),
                      }));
                    }}
                  />
                </FloatingLabel>
              </div>
              <div>
                <h1 className="my-2 font-bold">Hochladen von Videoinhalten</h1>

                <div className="px-2 py-2 border rounded-lg border-darkslateblue">
                  <input
                    type="file"
                    name=""
                    id={"uploadpdffile" + idx}
                    accept="pdf/*"
                    className="hidden"
                    onChange={(e) => {
                      if (!inputFileTypeValidator(e)) {
                        return message.error("Datei wird nicht unterstützt");
                      }
                      setFileName(e.target.files[0]?.name);
                      handlePdfUpload(e, _._id);
                    }}
                  />
                  <label
                    htmlFor={"uploadpdffile" + idx}
                    className="p-1 text-white rounded-md  bg-darkslateblue"
                  >
                    Datei wählen
                  </label>
                  <span className="mx-2">
                    {fileName?.length > 0 ? fileName : "Keine Datei ausgewählt"}
                  </span>
                </div>
              </div>

              {_?.pdfs?.map((e, i) => (
                <div
                  className="flex items-center justify-between px-3 py-2 text-white rounded-lg shadow-inner bg-darkslateblue"
                  key={i}
                >
                  <Typography.Paragraph
                    prefixCls="pdf_name_typography"
                    className="w-full"
                    editable={{
                      text: e?.name,
                      onChange: (e) => pdfNameChange(e, i, _),
                    }}
                  >
                    {e?.name}
                  </Typography.Paragraph>
                  <div className="flex items-center justify-between gap-3">
                    <div className="text-white">
                      <a href={e?.url} target="_blank" rel="noreferrer">
                        <AiFillEye />
                      </a>
                    </div>

                    <div className="text-redattendtion">
                      <Popconfirm
                        title="Sind Sie sicher, dass Sie dieses PDF löschen möchten?"
                        onConfirm={() => pdfDelete(i, _)}
                      >
                        <AiFillDelete />
                      </Popconfirm>
                    </div>
                  </div>
                </div>
              ))}
              <div className="grid gap-10 primary:grid-cols-2 grid-col-1">
                <div className="text-base font-semibold lg:text-9xl sm: sm:text-xl">
                  Laden Sie ein ansprechendes Miniaturbild hoch, das zur
                  Darstellung des video verwendet wird
                </div>
                <div className="rounded-11xl bg-white min-h-[200px] overflow-hidden">
                  <input
                    type="file"
                    id={"upload-UploadTutorials-" + idx}
                    accept="image/*"
                    className="hidden"
                    onChange={(e) =>
                      handleImageUpload(e, _._id, (prev, url) => {
                        return {
                          ...prev,
                          video_src: [...prev?.video_src]?.map((e) => {
                            if (e._id !== _._id) {
                              return e;
                            } else {
                              return { ...e, thumbnail: url };
                            }
                          }),
                        };
                      })
                    }
                  />
                  {_?.thumbnail?.length === 0 ? (
                    <label
                      htmlFor={"upload-UploadTutorials-" + idx}
                      className="flex items-center flex-col h-[200px] select-none justify-center cursor-pointer"
                    >
                      <img src="/images/icons-8-hochladen-1.png" alt="" />
                      <p className="text-base font-semibold  sm:text-xl">
                        Foto hochladen
                      </p>
                    </label>
                  ) : (
                    <label
                      htmlFor={"upload-UploadTutorials-" + idx}
                      className="h-[200px] relative  rounded-11xl overflow-hidden "
                    >
                      <CustomImageLoader
                        src={_?.thumbnail}
                        classNames={"w-full h-[200px]"}
                      />
                      <div className="h-[200px] top-0 absolute cover-upload-bg-second   w-full flex justify-end items-end">
                        <div className="flex flex-col items-center justify-center m-6 text-white  sm:m-10">
                          <UploadIcon />
                          <p className="text-base font-semibold text-white  sm:text-xl">
                            Miniaturansicht hochladen
                          </p>
                        </div>
                      </div>
                    </label>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center md:w-[70%] w-full m-auto ">
                <VideoUploadWidget
                  widgetId={`upload_widget_${idx}`}
                  inner={
                    <label
                      key={idx}
                      htmlFor={`cursor-pointer landing-page-config-change-video-${idx}`}
                      className="relative overflow-hidden  rounded-11xl"
                    >
                      {_?.url ? (
                        <video src={_?.url} className="aspect-video" alt="" />
                      ) : (
                        <img
                          src={"/images/Frame 125.png"}
                          className="cursor-pointer aspect-video"
                          alt=""
                        />
                      )}
                      <div className="absolute top-0 w-full h-full cursor-pointer cover-upload-bg">
                        <div className="justify-center items-center flex flex-col absolute right-[10%] sm:right-[20%] bottom-[10%] sm:bottom-[20%] text-white">
                          <UploadIcon />
                          <p className="text-base font-semibold text-white  sm:text-xl">
                            Video ändern/hochladen
                          </p>
                        </div>
                      </div>
                    </label>
                  }
                  callBack={(secure_url) => {
                    setTutorial((prev) => ({
                      ...prev,
                      video_src: prev.video_src.map((e, prev_index) => {
                        if (prev_index === idx) {
                          return { ...e, url: secure_url };
                        } else {
                          return e;
                        }
                      }),
                    }));
                  }}
                />
              </div>
            </div>

            <Button
              type="primary"
              className=" w-full h-[50px] text-xl p-0 flex justify-center items-center mx-2"
              onClick={() =>
                PopUpComponent({
                  onConfirm: async () => {
                    const id = location?.split?.("?")?.[1]?.split?.("=")?.[1];
                    if (!id) {
                      setTutorial((prev) => {
                        return {
                          ...prev,
                          video_src: [...prev.video_src].filter(
                            (e) => e._id !== _._id
                          ),
                        };
                      });
                    } else {
                      setTutorial((prev) => {
                        return {
                          ...prev,
                          video_src: [...prev.video_src].filter(
                            (e) => e._id !== _._id
                          ),
                        };
                      });

                      await AdminService.updateTutorial(tutorial?._id, {
                        ...tutorial,
                        video_src: [...tutorial.video_src].filter(
                          (e) => e._id !== _._id
                        ),
                      });
                    }
                  },
                  heading:
                    "Bist Du sicher, dass Du diese video löschen möchtest?",
                  confirmButtonText: "Löschen",
                  cancelButtonText: "Stornieren",
                })
              }
            >
              Video löschen
            </Button>
          </CollapseItem>
        ))}

        <div className="my-5 cursor-pointer">
          <div
            className="p-5 text-center bg-[#fafafa] rounded-xl my-5 flex justify-center items-center gap-2"
            onClick={() =>
              setTutorial((prev) => {
                return {
                  ...prev,
                  video_src: [
                    ...prev.video_src,
                    { title: "", url: "", description: "" },
                  ],
                };
              })
            }
          >
            <span className="text-2xl font-semibold">+</span>
            <p className="text-base font-semibold  sm:text-xl">
              {" "}
              Weitere Video{" "}
            </p>
          </div>
        </div>
      </div>

      <div
        className="p-[12px_20px] rounded-[10px] my-10  bg-darkslateblue text-center text-white  sm:text-xl text-base  font-semibold cursor-pointer "
        onClick={handleSave}
      >
        Speichern
      </div>
    </div>
  );
};

export default UploadTutorials;
