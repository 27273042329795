import { Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LandingPageService from "../../service/LandingPageService";
import PublicService from "../../service/PublicService";
import { getDefaultVideo } from "../../utils/GetDefaultVideo";
import { GoogleLogo } from "../../utils/GoogleLogo";
import CustomImageLoader from "../CustomImageLoader/CustomImageLoader";
import Stars from "./Stars";
import { useScreenWidth } from "./useScreenWidth";

export const calculateAverageStars = (reviews) => {
  if (!Array.isArray(reviews) || reviews.length === 0) {
    return 0; // Return 0 if the input is not an array or is empty
  }
  const totalStars = reviews.reduce((acc, review) => acc + review.stars, 0);
  const averageStars = totalStars / reviews.length;
  return averageStars;
};

const Index = ({ clickId }) => {
  const { id, promoter_id } = useParams();
  const [landingPageDetails, setLandingPageDetails] = useState(null);
  const [promoter, setPromoter] = useState();
  const [googleReviews, setGoogleReviews] = useState([]);
  const [localReviews, setLocalReviews] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoDuration, setVideoDuration] = useState();
  const videoPlayerRef = useRef();
  const videoPlayerSmallRef = useRef();

  const { width } = useScreenWidth();

  useEffect(() => {
    const getLandingPageDetails = async () => {
      try {
        if (promoter_id !== "viewmode") {
          const { data: Promoter } = await PublicService.getPromoterInitials(
            promoter_id
          );
          console.log(Promoter);
          setPromoter(Promoter);
        } else {
          setPromoter({
            firstname: "Promoter Vorname",
            lastname: "Nachname",
          });
        }
        const { data } = await LandingPageService.detail(id);

        console.log(data);
        if (data?.activateGoogleReviews) {
          PublicService.getGoogleReviews(id).then(({ data }) => {
            setGoogleReviews(data);
          });
        }

        if (data?.activateLocalReviews) {
          PublicService.getLocalReviews(id).then(({ data }) => {
            console.log(data, "local reviews");
            setLocalReviews(data);
          });
        }

        setLandingPageDetails(data);
      } catch (error) {
        console.log(error);
      }
    };
    getLandingPageDetails();
  }, [id, promoter_id]);
  const bgSeter = () => {
    const bg = landingPageDetails?.background_image;
    return {
      backgroundImage: `url(${bg})`,
      backgroundPosition: "center",
      backdropFilter: "brightness(0.9)",

      backgroundSize: "cover",
      backgroundRepeat: "norepeat",
    };
  };

  useEffect(() => {
    try {
      const videoplayer = videoPlayerRef.current;
      videoplayer.ondurationchange = () => {
        setVideoDuration(videoplayer?.duration);
      };
    } catch (error) {}
  }, [landingPageDetails, videoPlayerRef]);

  const { type } = useParams();
  const navigate = useNavigate();
  if (!landingPageDetails) {
    return <Skeleton active />;
  }
  return (
    <div className="max-w-[2000px]">
      <div className="py-10 " style={bgSeter()}>
        <div className="flex items-center">
          {landingPageDetails?.logoSrc && (
            <img
              src={landingPageDetails?.logoSrc}
              className="m-auto"
              alt=""
              height="60"
            />
          )}
        </div>
        <div
          className="secondary:w-[80%] w-[95%] m-auto grid md:grid-cols-2 grid-cols-1 md:my-20"
          style={{ fontWeight: 700 }}
        >
          <div
            className="text-xl font-bold text-center text-white md:text-primary md:text-start "
            style={{
              textShadow: "0 0 20px hsla(0,0%,0%,.9)",
            }}
          >
            <p className={`${width < 767 ? "text-3xl" : ""}`}>
              {/* Etwas Gutes kann man weiterempfehlen! Und so hat{" "}
              <span className="text-trkis whitespace-wrap">{`${
                promoter?.firstname ?? ""
              } ${promoter?.lastname ?? ""}`}</span>{" "}
              heute auch an Dich gedacht. */}
              {landingPageDetails?.introductoryText}
            </p>
            <p className="my-5 text-xl font-normal sm:text-9xl md:mt-20 md:my-0">
              {/* Mein Name ist{" "}
              {`${landingPageDetails?.user?.firstname} ${landingPageDetails?.user?.lastname}`}{" "}
              <span className="hidden md:inline">
                und ich bin seit{" "}
                {landingPageDetails?.yearsOfExperienceInProfession} Jahren als{" "}
                {landingPageDetails?.profession} tätig.
              </span> */}
              {landingPageDetails?.accompanyingTextOne}
            </p>
          </div>
          <div className="items-center justify-center hidden my-10 md:justify-end md:my-0 md:flex">
            {landingPageDetails?.primary_image && (
              <CustomImageLoader
                src={landingPageDetails?.primary_image}
                classNames="primary:w-[410px] primary:h-[410px] w-[300px] h-[300px] aspect-square rounded-3xl"
              />
            )}
          </div>
        </div>
        <div
          className="font-semibold text-white secondary:w-[80%] w-[95%] mx-auto text-center md:block hidden text-9xl  "
          style={{
            textShadow: "0 0 20px hsla(0,0%,0%,.9)",
          }}
        >
          <p className="px-10">
            {/* Deshalb berate ich auch {`${promoter?.firstname}`} im Bereich{" "}
            {landingPageDetails?.fieldOfExpertise}.{" "}
            {landingPageDetails?.particularSkill}. {`${promoter?.firstname}`}{" "}
            ist der Meinung, dass das auch für Dich sehr wertvoll sein könnte. */}
            {landingPageDetails?.accompanyingTextTwo}
          </p>
        </div>
        <div className="w-11/12 m-auto text-white md:hidden">
          <h1
            className="text-xl font-bold text-center md:text-primary"
            style={{
              textShadow: "0 0 20px hsla(0,0%,0%,.9)",
            }}
          >
            Lerne mich in{" "}
            {`${videoDuration ? videoDuration?.toFixed?.(0) : "22"} `}
            Sekunden kennen!
          </h1>
          <div className="relative flex items-center justify-center my-10">
            <video
              ref={videoPlayerSmallRef}
              className="rounded-[20px] w-full"
              src={getDefaultVideo(landingPageDetails?.persentationVideo)}
              controls
              preload="metadata"
              poster={landingPageDetails?.thumbnail}
            />
          </div>
        </div>
        <div className="flex items-center justify-center my-1">
          <a href="#middle">
            <img
              src="/images/icons-8-doppelt-runter-21.png"
              alt=""
              className="animate-bounce primary:w-[80px] sm:w-[50px] w-[32px]"
            />
          </a>
        </div>
      </div>

      <div className="w-[95%] secondary:w-[80%] m-auto    my-10">
        {landingPageDetails?.activateGoogleReviews && (
          <div className="bg-[#fafafa] rounded-11xl sm:p-10 p-3">
            <div className=" md:text-primary text-[26px] text-center   font-bold">
              <h1>
                Das sagen meine Kunden auf{" "}
                <span className="font-notosan">
                  {<GoogleLogo rating={calculateAverageStars(googleReviews)} />}
                </span>
              </h1>
            </div>

            <div className="grid hidden grid-cols-1 md:block md:grid-cols-2 gap-7">
              {googleReviews?.map((e, i) => (
                <div
                  className="flex flex-col gap-8 p-4 mb-5 bg-white sm:p-10 rounded-11xl"
                  key={i}
                >
                  <div className="flex items-center gap-3">
                    <img
                      src="/images/icons-8-mannlicher-benutzer-eingekreist-1.png"
                      alt=""
                    />
                    <div>
                      <h1 className="md:text-[20px] text-base font-semibold">
                        {e?.reviewedby}
                      </h1>
                      <Stars rating={e?.stars} />
                    </div>
                  </div>
                  <div className="text-[#868686] md:text-base text-sm font-semibold">
                    {e?.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {landingPageDetails?.activateLocalReviews && (
          <div className="bg-[#fafafa] rounded-11xl">
            <div className="md:text-primary text-[26px] text-center font-bold">
              <h1>Das sagen meine Kunden</h1>
            </div>

            <div className="flex w-screen pt-10 overflow-x-scroll sm:w-auto lg:grid-cols-3 sm:grid-cols-2 sm:grid gap-7">
              {localReviews
                // ?.filter((e) => e.comment !== "" && e.reviewedby !== "")
                .map((e, i) => (
                  <div
                    className="flex flex-col gap-8 bg-white rounded-11xl p-5 min-w-[300px] sm:min-w-[0px]"
                    key={i}
                  >
                    <div className="flex items-center gap-3">
                      <img
                        className="object-cover rounded-full"
                        src={
                          e?.customerImage ||
                          "/images/icons-8-mannlicher-benutzer-eingekreist-1.png"
                        }
                        alt="Profilbild"
                      />
                      <h1 className="md:text-[20px] text-base font-semibold">
                        {e?.reviewedby}
                      </h1>
                    </div>
                    <p className="text-[#868686] md:text-base text-sm font-semibold break-words	">
                      {e?.comment}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className="" id="middle">
          <div className="flex flex-col items-center hidden gap-2 my-10 text-center xsss:flex-row xsss:text-start">
            <div className="mx-auto text-sm font-semibold text-left xsss:w-8/12">
              <p className="break-words">
                Deshalb berate ich auch {`${promoter?.firstname}`} im Bereich{" "}
                {landingPageDetails?.fieldOfExpertise}.{" "}
                {landingPageDetails?.particularSkill}.{" "}
                {`${promoter?.firstname}`} ist der Meinung, dass meine Beratung
                auch für Dich sehr wertvoll sein könnte.
              </p>
            </div>
            <div className="w-5/12">
              {landingPageDetails?.primary_image && (
                <CustomImageLoader
                  src={landingPageDetails?.primary_image}
                  classNames="min-w-[150px] sm:min-width:[200px]  sm:min-h-[200px]   h-[190px]  rounded-xl"
                />
              )}
            </div>
          </div>

          <h1 className="hidden font-bold text-center md:text-primary text-9xl md:block">
            Lerne mich in{" "}
            {`${videoDuration ? videoDuration?.toFixed?.(0) : "22"} `}
            Sekunden kennen!
          </h1>
          <div className="relative items-center justify-center hidden my-10 md:flex">
            <video
              ref={videoPlayerRef}
              className="w-full rounded-11xl"
              preload="metadata"
              controls
              poster={landingPageDetails?.thumbnail}
              src={getDefaultVideo(landingPageDetails?.persentationVideo)}
            />
          </div>
          <h1 className="md:text-primary text-[26px]  text-center font-bold mt-5">
            {landingPageDetails?.questionaireIntroductionText}
          </h1>
          <div className="text-center">
            <button
              className="m-auto rounded-31xl md:p-[20px_67px] p-[10px_33.5px] my-10 bg-[linear-gradient(152deg,_#00DEF7_0%,_#0084F7_49.48%,_#001D47_100%)] text-center md:text-9xl text-xl font-semibold text-white shadow-[0px_0px_26.711999893188477px_0px_#06DEF8]"
              onClick={() =>
                navigate(
                  `/questionare/${type}/${landingPageDetails._id}/${promoter_id}/${clickId}`
                )
              }
            >
              {landingPageDetails?.questionaireButtonText}
            </button>
          </div>
        </div>
      </div>

      <div className="w-full px-3   bg-white border-t-[1px] border-[#d8d8d8]   mt-10">
        <div className="flex secondary:w-[70%] m-auto  justify-center sm:justify-between font-semibold text-[16px] flex-wrap gap-20 my-5 w-full ">
          <h1>© Copyright</h1>
          <div className="flex items-center gap-10">
            <a
              className="cursor-pointer "
              target="_blank"
              rel="noreferrer"
              href={
                landingPageDetails?.user?.defaultImpressumText?.length > 1
                  ? `${window.location.origin}/impressum/${id}/${promoter_id}`
                  : landingPageDetails?.user?.defaultImpressumLink
              }
            >
              Impressum
            </a>
            {/* <a
              className="cursor-pointer "
              target="_blank"
              rel="noreferrer"
              href={
                landingPageDetails?.user?.defaultPrivacyText?.length > 1
                  ? `${window.location.origin}/datenschutz/${id}/${promoter_id}`
                  : landingPageDetails?.user?.defaultPrivacyLink
              }
            >
              Datenschutz
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
