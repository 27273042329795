export const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <circle cx="20" cy="20" r="20" fill="white" />
    <path
      opacity="0.85"
      d="M29.25 18.701C30.25 19.2783 30.25 20.7217 29.25 21.299L16.5 28.6603C15.5 29.2376 14.25 28.5159 14.25 27.3612L14.25 12.6388C14.25 11.4841 15.5 10.7624 16.5 11.3397L29.25 18.701Z"
      fill="#979797"
    />
  </svg>
);
