import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class LocalReviewService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/localReviews`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }
  
  list(landingpage_id) {
    console.log("LocalReviewService.list()");
    const params = landingpage_id ? { landingpage_id } : {};
    return this.api.get("/list", { params });
  }

  create(data) {
    console.log("LocalReviewService.create()");
    return this.api.post("/create", data);
  }

  update(id, data) {
    console.log("LocalReviewService.update()");
    return this.api.put(`/update?id=${id}`, data);
  }

  remove(id) {
    console.log("LocalReviewService.remove()");
    return this.api.put(`/remove?id=${id}`);
  }


  wipeAndAdd(id, data) {
    console.log("LocalReviewService.wipeAndAdd()", data);
    return this.api.put(`/wipeAndAdd?id=${id}`, data);
  }
}

const localReviewService = new LocalReviewService();

export default localReviewService;
