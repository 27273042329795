import { createContext, useEffect, useState } from "react";

// Create a context to manage the script loading state
const CloudinaryScriptContext = createContext();

function CloudinaryUploadWidget({
  uwConfig,
  setPublicId,
  callBack,
  inner,
  widgetId = "upload_widget",
}) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Check if the script is already loaded
    if (!loaded) {
      const uwScript = document.getElementById("uw");
      if (!uwScript) {
        // If not loaded, create and load the script
        const script = document.createElement("script");
        script.setAttribute("async", "false");
        script.setAttribute("id", "uw");
        script.src = "https://upload-widget.cloudinary.com/global/all.js";
        script.addEventListener("load", () => setLoaded(true));
        document.body.appendChild(script);
      } else {
        // If already loaded, update the state
        setLoaded(true);
      }
    }
  }, [loaded]);

  const initializeCloudinaryWidget = () => {
    var AllIframes = document.querySelectorAll('[title="Upload Widget"]');
    console.log(AllIframes)
      
    if (loaded) {
      var myWidget = window.cloudinary.createUploadWidget(
        uwConfig,
        (error, result) => {
          if (!error && result && result.event === "success") {
            const public_id = result.info.public_id;
            const secure_link = result.info?.secure_url;
            console.log("Done! Here is the link: ", secure_link);
            const thumbnailURL = `https://res.cloudinary.com/${uwConfig?.cloudName}/video/upload/w_1280,h_720,c_fill/${public_id}.jpg`;
            callBack(secure_link, thumbnailURL);
            setPublicId(result.info.public_id);
          }
        }
      );

      document.getElementById(widgetId).addEventListener(
        "click",
        function () {
          myWidget.open();
        },
        false
      );
    }
  };

  return (
    <CloudinaryScriptContext.Provider value={{ loaded }}>
      <div id={widgetId} onClick={initializeCloudinaryWidget}>
        {inner}
      </div>
    </CloudinaryScriptContext.Provider>
  );
}

export default CloudinaryUploadWidget;
export { CloudinaryScriptContext };
