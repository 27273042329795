import { markText } from "./MarkText";
import { replaceText } from "./replaceText";

export const replaceAndMark = (text, replacements, color = "blue") => {
  // Step 1: Replace the text using the replacements object
  const replacedText = replaceText(text, replacements);

  // Step 2: Extract the replaced substrings for marking
  const replacedSubstrings = Object.values(replacements);

  // Step 3: Mark the replaced substrings in the replaced text
  const markedText = markText(replacedText, replacedSubstrings, color);

  return markedText;
};
