export const replaceBase64WithCloudinaryUrls = (
  htmlContent,
  uploadedImages
) => {
  let updatedContent = htmlContent;

  uploadedImages.forEach(({ base64Image, url }) => {
    const escapedBase64 = base64Image.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const regex = new RegExp(escapedBase64, "g");
    updatedContent = updatedContent.replace(regex, url);
  });

  return updatedContent;
};
