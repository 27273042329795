import React from "react";
import { imageBackground } from "../../utils/setImageBackground";

const FormComponent = ({ bg, children, visible, style = {} }) => {
  return (
    visible && (
      <div
        className="w-full  h-[100vh] flex justify-center items-center"
        style={{ ...imageBackground(bg), overflow: "auto", paddingBlock: 20 }}
      >
        <div
          className={`sm:w-[80%] w-full m-auto rounded-[30px]   p-[30px] bg-white`}
          style={{ ...style, marginInline: 20 }}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default FormComponent;
