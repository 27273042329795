const GUTERTIPCARD = "/images/gutertipcard.png";

const DOWNGREENARROW = "/images/downArrow.png";

const RIGhTGREENARROW = "/images/rightarrow.png";

const CURVEDARROW = "/images/curvedarrow.png";

const PAGESKELETTON = "/images/pageskelet.png";

export { GUTERTIPCARD, DOWNGREENARROW, RIGhTGREENARROW, CURVEDARROW, PAGESKELETTON };
