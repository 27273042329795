import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useMemo, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import UserService from "../../../service/UserService";
function DebounceSelect({
  usesimple,
  fetchOptions,
  debounceTimeout = 800,
  className,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return !usesimple ? (
    <div className="flex items-center border border-grey-accent rounded-[10px] px-2 py-1 relative">
      <AiOutlineSearch className="text-responsiveHeading" />
      <Select
        labelInValue
        filterOption={false}
        showSearch
        suffixIcon={<></>}
        className="min-w-[200px] w-full"
        placeholder="Suchbegriff eingeben..."
        onSearch={debounceFetcher}
        {...props}
        options={options}
      />
    </div>
  ) : (
    <Select
      labelInValue
      filterOption={false}
      showSearch
      suffixIcon={<></>}
      placeholder="Suchbegriff eingeben..."
      onSearch={debounceFetcher}
      {...props}
      options={options}
    />
  );
}

async function fetchUserList(username) {
  const user = await UserService.searchUsers(username);
  const ans = [
    ...user.data.promoterUsers.map((e) => ({
      value: e._id,
      label: e?.firstname + " " + e?.lastname,
      key: e._id + "promoter",
    })),
    ...user.data.empfehlungUsers.map((e) => ({
      value: e._id,
      label: e?.firstname,
      key: e._id + "empfehlungen",
    })),
  ];
  return ans;
}
const SearchSelect = ({
  usesimple = false,
  value,
  setValue,
  prefixCls,
  style,
  setTitle,
}) => {
  const navigate = useNavigate();
  return (
    <DebounceSelect
      value={value}
      placeholder="Suchbegriff eingeben..."
      fetchOptions={fetchUserList}
      prefixCls={prefixCls}
      usesimple={usesimple}
      onSelect={(newValue) => {
        const navigateDetails = {
          realKey: "",
          route: "",
        };
        if (newValue.key.includes("promoter")) {
          navigateDetails.realKey = newValue.key.replace("promoter", "");
          navigateDetails.route = "promoter";
          setTitle("Promoter");
        } else if (newValue.key.includes("empfehlungen")) {
          navigateDetails.realKey = newValue.key.replace("empfehlungen", "");
          navigateDetails.route = "empfehlungen";
          setTitle("Empfehlungen");
        }
        navigate(
          `/dashboard/${navigateDetails.route}?${navigateDetails.route}=${navigateDetails.realKey}`
        );

        setValue(newValue);
      }}
      style={
        style || {
          width: "100%",
        }
      }
    />
  );
};
export default SearchSelect;
