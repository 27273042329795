import { Skeleton, Table } from "antd";
import Column from "antd/es/table/Column";
import React, { useCallback, useEffect, useState } from "react";
import UserService from "../../../service/UserService";

const Index = ({ resultFilter }) => {
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [loading, setLoading] = useState(true);
  const getLeaderBoard = useCallback(async () => {
    UserService.getLeaderboard(resultFilter).then(({ data }) => {
      const { leaderboard } = data;
      setLeaderBoard(leaderboard);
      setLoading(false);
    });
  }, [resultFilter]);
  useEffect(() => {
    getLeaderBoard();
  }, [getLeaderBoard]);

  if (loading) return <Skeleton active />;

  return (
    <div className="sm:p-0 px-2">
      {leaderBoard[0] && (
        <div className="leader-board-hero lg:h-[400px] grid lg:grid-cols-3 grid-cols-1">
          <div className="h-full">
            <div className="h-full">
              <div className="flex justify-center items-end h-full ">
                {leaderBoard[2] && (
                  <div className="my-10">
                    <div className="relative flex flex-col justify-center">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROEMCKQPj0-FtCQEGoafkYxbdycwzTTvZXIGxa09Bts_3s2w4NlZQUt43oYpg-xcotvFE&usqp=CAU"
                        className="w-[100px] h-[100px] rounded-full m-auto"
                        alt=""
                      />

                      <div className=" bg-[#70514A] h-[30px]  w-[30px] text-center p-1 m-auto right-0 left-0 bottom-[-15px]   rounded-full text-white font-bold absolute">
                        3
                      </div>
                    </div>
                    <div className="bg-white rounded-[15px] p-3 text-center">
                      <h1 className="sm:text-[28px] text-base font-semibold">
                        {leaderBoard[2]?.name}
                      </h1>
                      <div className="flex gap-3 items-center justify-between white-text-div-folder">
                        <div>
                          <p>Aufrufe</p>
                          <h5>{leaderBoard[2]?.aufrufCount}</h5>
                        </div>
                        <div>
                          <p>Empfehlungen</p>
                          <h5>{leaderBoard[2]?.anzahlEmpfehlungen}</h5>
                        </div>
                        <div>
                          <p>Neukunden</p>
                          <h5>{leaderBoard[2]?.anzahlNeukunden}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="h-full">
            <div className="flex justify-center items-start  h-full ">
              <div className="my-10">
                <div className="relative flex flex-col justify-center">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROEMCKQPj0-FtCQEGoafkYxbdycwzTTvZXIGxa09Bts_3s2w4NlZQUt43oYpg-xcotvFE&usqp=CAU"
                    className="w-[100px] h-[100px] rounded-full m-auto"
                    alt=""
                  />

                  <div className=" bg-[#FFD700] h-[30px]  w-[30px] text-center p-1 m-auto right-0 left-0 bottom-[-15px]   rounded-full text-white font-bold absolute">
                    1
                  </div>
                </div>
                <div className="bg-white rounded-[15px] p-3 text-center">
                  <h1 className="sm:text-[28px] text-base font-semibold">
                    {leaderBoard[0]?.name}
                  </h1>
                  <div className="flex gap-3 items-center justify-between white-text-div-folder">
                    <div>
                      <p>Aufrufe</p>
                      <h5>{leaderBoard[0]?.aufrufCount}</h5>
                    </div>
                    <div>
                      <p>Empfehlungen</p>
                      <h5>{leaderBoard[0]?.anzahlEmpfehlungen}</h5>
                    </div>
                    <div>
                      <p>Neukunden</p>
                      <h5>{leaderBoard[0]?.anzahlNeukunden}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="h-full">
            <div className="flex justify-center items-center   h-full ">
              {leaderBoard[1] && (
                <div className="my-10">
                  <div className="relative flex flex-col justify-center">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROEMCKQPj0-FtCQEGoafkYxbdycwzTTvZXIGxa09Bts_3s2w4NlZQUt43oYpg-xcotvFE&usqp=CAU"
                      className="w-[100px] h-[100px] rounded-full m-auto"
                      alt=""
                    />

                    <div className=" bg-[#868686] h-[30px]  w-[30px] text-center p-1 m-auto right-0 left-0 bottom-[-15px]   rounded-full text-white font-bold absolute">
                      2
                    </div>
                  </div>
                  <div className="bg-white rounded-[15px] p-3 text-center">
                    <h1 className="sm:text-[28px] text-base font-semibold">
                      {leaderBoard[1]?.name}
                    </h1>
                    <div className="flex gap-3 items-center justify-between white-text-div-folder">
                      <div>
                        <p>Aufrufe</p>
                        <h5>{leaderBoard[1]?.aufrufCount}</h5>
                      </div>
                      <div>
                        <p>Empfehlungen</p>
                        <h5>{leaderBoard[1]?.anzahlEmpfehlungen}</h5>
                      </div>
                      <div>
                        <p>Neukunden</p>
                        <h5>{leaderBoard[1]?.anzahlNeukunden}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="w-full overflow-auto">
        {/* <div className="flex flex-wrap items-center text-text-sec my-5 gap-3 primary:hidden ">
          Platz <HiArrowLongRight /> Name <HiArrowLongRight />
          Aufrufe <HiArrowLongRight /> Empfehlungen <HiArrowLongRight /> Anzahl{" "}
          <HiArrowLongRight /> Neukunden
        </div> */}
        <Table
          prefixCls="leaderboardtable"
          className="my-10"
          bordered={false}
          dataSource={[...leaderBoard]}
          pagination={false}
          rowKey={"uid"}
        >
          <Column
            title="Platz"
            dataIndex={"id"}
            className="font-bold sm:text-[18px] text-sm"
            render={(_, __, ___) => ___ + 1}
          ></Column>
          <Column
            title="Name"
            dataIndex={"name"}
            className="font-bold sm:text-[18px] text-sm"
          ></Column>
          <Column
            title="Telefonnummer des Promoters"
            dataIndex={"telefon"}
            className="font-bold sm:text-[18px] text-sm"
          ></Column>
          <Column
            title="Aufrufe"
            dataIndex={"aufrufCount"}
            className="font-bold sm:text-[18px] text-sm"
          ></Column>
          <Column
            title="Anzahl Empfehlungen"
            dataIndex={"anzahlEmpfehlungen"}
            className="font-bold sm:text-[18px] text-sm"
          ></Column>
          <Column
            title="Anzahl Neukunden"
            dataIndex={"anzahlNeukunden"}
            className="font-bold sm:text-[18px] text-sm"
          ></Column>
        </Table>
      </div>
    </div>
  );
};

export default Index;
