import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { selectUser } from "../redux/auth/selectors";
export function useCredentials() {
  const user = useSelector(selectUser);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (user && user?.user && user?.user?.accessLevels)
      Object.keys(user?.user?.accessLevels).forEach((e) => {
        const read = user?.user?.accessLevels[e].read;
        const write = user?.user?.accessLevels[e].write;
        if (read || write) {
          setAdmin(true);
        }
      });
  }, [user]);

  return { admin, user };
}
