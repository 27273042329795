import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicService from "../../service/PublicService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";

const Index = () => {
  const { user_id } = useParams();

  const [privacy, setPrivacy] = useState("");
  const [user, setUser] = useState({});

  const getPrivacyPolicy = async () => {
    try {
      const { data } = await PublicService.getCMS();
      setPrivacy(data?.CMS?.teilnahmebedingungen);
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = useCallback(async () => {
    try {
      const { data } = await PublicService.getUserName(user_id);
      setUser(data);
    } catch (error) {
      console.log(error);
    }
  }, [user_id]);

  useEffect(() => {
    getPrivacyPolicy();
  }, []);
  useEffect(() => {
    getUser();
  }, [getUser]);

  if (!privacy) {
    return <LoadingScreen />;
  }
  return (
    <div className=" p-10">
      <h1 className="text-xl font-bold text-center formattierterRechtsText">
        Teilnahmebedingungen
      </h1>
      <div
        className="my-10"
        dangerouslySetInnerHTML={{
          __html: privacy
            ?.replace?.(/{Partner Vorname}/g, user?.firstname)
            ?.replace?.(/{Partner Nachname}/g, user?.lastname),
        }}
      />
    </div>
  );
};

export default Index;
