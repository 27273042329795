import { Alert, Button, Checkbox, Radio, Space, message } from "antd";
import { Check, X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";
import SubscriptionService from "../../service/SubscriptionService";
import UserService from "../../service/UserService";
import { features, tiers } from "../Home/MakeYouFit";

const prices = {
  Basic: { twelveMonths: 39 },
  Standard: { twelveMonths: 79 },
  Premium: { twelveMonths: 149 },
};

export default function Component({ title }) {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState();
  const [checkboxFailed, setCheckboxFailed] = useState(false);
  const [adminTestPhase, setAdminTestPhase] = useState(7);
  const [selectedTier, setSelectedTier] = useState("Premium");
  const [checkbox1, setCheckbox1] = useState(false);
  const [config, setConfig] = useState({});

  const getAdminSetting = async () => {
    const { data } = await PublicService.getCMS();
    setConfig(data);
  };

  useEffect(() => {
    getAdminSetting();
  }, []);

  const getSubscriptionInfo = async () => {
    const { data } = await SubscriptionService.getSubscriptionInfo();
    console.log(data);
    setSubscription(data?.subscription);
  };

  React.useEffect(() => {
    title("Upgrade");
    setTimeout(() => title("Upgrade"), 200);
    getSubscriptionInfo();
  }, [title]);

  const { user } = useSelector(selectUser);

  useEffect(() => {
    const getAdminConfigure = async () => {
      const response = await PublicService.getAdminConfigure();
      setAdminTestPhase(response?.data?.config?.testPhaseDurationDays);
    };
    getAdminConfigure();
  }, []);

  useEffect(() => {
    if (user?.subscription?.paid) {
      navigate("/dashboard/profile");
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight + 5000);
      }, 1000);
    }
  }, [user, navigate]);

  const handleTierChange = (e) => {
    setSelectedTier(e.target.value);
  };

  const handleSubscribe = async () => {
    if (!checkbox1) {
      setCheckboxFailed(true);
      return;
    }

    const { data } = await SubscriptionService.subscribe(
      selectedTier,
      `${window.location.origin}/dashboard`
    );

    window.location.href = data.paymentLink;
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      {config?.CMS?.enableUpgradeVideo && (
        <div className="flex flex-col items-center justify-center  w-full">
          {config?.CMS?.upgradeVideoText && (
            <p
              className="mb-2 text-xl font-bold text-center"
              dangerouslySetInnerHTML={{
                __html: config?.CMS?.upgradeVideoText,
              }}
            />
          )}

          <video
            playsInline
            controls
            className="aspect-video w-full rounded-sm object-cover shadow-lg bg-black bg-opacity-50 p-1"
            poster={config.CMS.upgradeVideoThumbnail}
            style={{ cursor: "pointer" }}
          >
            <source
              src={config?.CMS?.upgradeVideoAtSubscriptionPage}
              type="video/mp4"
            />
            <source
              src={config?.CMS?.upgradeVideoAtSubscriptionPage}
              type="video/webm"
            />
            <source
              src={config?.CMS?.upgradeVideoAtSubscriptionPage}
              type="video/quicktime"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      <h1 className="text-2xl font-bold text-center mb-6 mt-6">
        Wähle Deinen Tarif
      </h1>

      <div className="mb-8 flex flex-col items-center gap-4">
        <Radio.Group onChange={handleTierChange} value={selectedTier}>
          <Radio.Button value="Basic">Basic</Radio.Button>
          <Radio.Button value="Standard">Standard</Radio.Button>
          <Radio.Button value="Premium">Premium</Radio.Button>
        </Radio.Group>

        <div className="flex items-center gap-2">
          <Checkbox
            checked={checkbox1}
            onChange={(e) => setCheckbox1(e.target.checked)}
          />
          <span className="text-sm">
            Ich akzeptiere die AGB's von EinGuterTipp
          </span>
        </div>

        <div className="text-center">
          <div className="text-2xl font-bold mb-2">
            {prices[selectedTier].twelveMonths},00€ mtl.
          </div>
          <Button
            type="primary"
            className="w-full max-w-xs h-10"
            onClick={handleSubscribe}
          >
            Abonnieren
          </Button>
        </div>

        {checkboxFailed && (
          <Alert
            type="error"
            message="Du musst allen Checkboxen zustimmen"
            className="w-full max-w-xs"
          />
        )}
      </div>

      <div className="rounded-[20px]  bg-[#0184f8] overflow-auto smx:pl-2">
        {/* Table Header */}
        <div className="grid grid-cols-4 mdx:grid-cols-[200px_1fr_1fr_1fr] min-w-[600px]">
          <div className="p-6 mdx:px-1 pb-0 mdx:min-w-[200px]">
            <h1 className="text-2xl mdx:text-lg font-semibold text-white">
              Hauptlizenz
            </h1>
            <h2 className="text-xl mdx:text-md font-bold mt-8 !text-[#000000]">
              Features
            </h2>
          </div>
          {tiers.map((tier) => (
            <div key={tier.name} className=" ">
              <h3 className="font-bold text-white w-[180px] mdx:w-[100px] mx-2 mdx:mx-auto mt-8 mb-2 text-center ">
                {tier.name}
              </h3>
              <div className="bg-[#001d47] w-[180px] mdx:w-[100px] mdx:mx-auto rounded-t-[20px] p-6   mx-2">
                <div className="text-white space-y-1">
                  <p className="text-xl font-bold flex justify-center whitespace-nowrap mdx:text-sm">
                    {tier.price}* mtl.
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Features */}
        <div className="min-w-[600px]">
          {features.map((feature) => (
            <div
              key={feature.name}
              className="grid grid-cols-4 mdx:grid-cols-[200px_1fr_1fr_1fr]"
            >
              <div className="px-6 mdx:px-1 py-1 text-white whitespace-nowrap">
                {feature.name}
              </div>
              {[feature.basic, feature.standard, feature.premium].map(
                (value, index) => (
                  <div
                    key={index}
                    className="bg-[#001d47] px-4 mdx:px-1 w-[180px] mdx:w-[100px] mdx:mx-auto py-1 mx-2 flex justify-center"
                  >
                    {typeof value === "boolean" ? (
                      value ? (
                        <Check className="w-6 h-6 text-green-400" />
                      ) : (
                        <X className="w-6 h-6 text-red-400" />
                      )
                    ) : (
                      <span className="text-white font-medium">{value}</span>
                    )}
                  </div>
                )
              )}
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className="grid grid-cols-4 mdx:grid-cols-[200px_1fr_1fr_1fr] min-w-[600px]">
          <div className="p-4" />
          {[1, 2, 3].map((i) => (
            <div
              key={i}
              className="bg-[#001d47] w-[180px] mdx:w-[100px] mdx:mx-auto rounded-b-[20px] mx-2 p-4"
            />
          ))}
        </div>
        <div className="p-4 text-sm text-[#000000] text-center font-bold min-w-[600px] ml-[215px]">
          *Preise zzgl. Mehrwertsteuer und einer Mindestlaufzeit von 12 Monaten
        </div>
      </div>
    </div>
  );
}
