import React, { useEffect, useState } from "react";
import PublicService from "../../service/PublicService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";

const Index = () => {
  const [privacy, setPrivacy] = useState("");

  const getPrivacyPolicy = async () => {
    try {
      const { data } = await PublicService.getPrivacy();
      setPrivacy(data.content);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  if (!privacy) {
    return <LoadingScreen />;
  }
  return (
    <div className=" p-10">
      <h1 className="text-xl font-bold text-center formattierterRechtsText">
        Datenschutz
      </h1>
      <div className="my-10" dangerouslySetInnerHTML={{ __html: privacy }} />
    </div>
  );
};

export default Index;
