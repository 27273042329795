const fields = [
  { value: "Finanzberatung", label: "Finanzberatung" },
  { value: "Versicherungsmakler", label: "Versicherungsmakler" },
  { value: "Immobilienfinanzierung", label: "Immobilienfinanzierung" },
  { value: "Versicherungsagentur", label: "Versicherungsagentur" },
  { value: "Strukturvertrieb Finanzen", label: "Strukturvertrieb Finanzen" },
  { value: "Strukturvertrieb sonstige", label: "Strukturvertrieb sonstige" },
  {
    value: "Energieberater (Strom+ Gas)",
    label: "Energieberater (Strom+ Gas)",
  },
  { value: "Berater / Coach", label: "Berater / Coach" },
  { value: "sonstige Vertriebe", label: "sonstige Vertriebe" },
];
const notiificationoconfig = {
  emailZustimmungFallig:
    " Benachrichtigung falls Neukunde nach ... Tagen noch kein Promoter ist (wiederkehrend bis Erledigung)",
  empfehlungUnbearbeitet:
    " Benachrichtigung falls neue Empfehlung länger als ... Tage unbearbeitet ist (wiederkehrend bis zur Erledigung)",
  empfehlungOhneStatusAnderung:
    " Benachrichtigung falls Empfehlung länger als ... Tage ein offener Vorgang ist. (wiederkehrend bis zur Erledigung)",
  empfehlungTerminiert:
    " Benachrichtigung falls Empfehlung länger als ... Tage terminiert ist.",
  redeemRequests: " Benachrichtigung Promoter Prämienanfrage (einmalig)",
  neueEmpfehlung: "Benachrichtigung falls neue Empfehlung eingetroffen ist",
  neuerKunde: "Benachrichtigung falls neuer Promoter eingetroffen ist",
  linkNichtGeklickt:
    " Benachrichtigung falls Promoter länger als ... Tage keine Aufrufe hat (einmalig)",
};
const Pramienkeys = {
  eurProKlick: "Prämie pro Klick",
  eurProEmpfehlung: "Prämie pro eingehender Empfehlung",
  eurProKunde: "Prämie pro Neukunde",
  mindestEinloseWert: "Mindestwert zum Einlösen",
};
export { Pramienkeys, fields, notiificationoconfig };
