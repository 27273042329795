import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class PromoterPublicService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/promoterPublic`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  createRedeemRequest(valueInEUR) {
    const value = parseInt(valueInEUR);
    if (isNaN(value)) throw new Error(`Not a number: ${valueInEUR}`);
    return this.api.post("/createRedeemRequest", { valueInEUR: value });
  }

  getPramienList() {
    return this.api.get("/getPramienList");
  }

  getPromotionslinks() {
    return this.api.get("/getPromotionslinks");
  }

  getStats() {
    return this.api.get("/getStats");
  }
  detail(id) {
    return this.api.get(`/detail?id=${id}`);
  }
  changeAllowances(data) {
    return this.api.put(`/changeAllowances`, data);
  }
}

export default new PromoterPublicService();
