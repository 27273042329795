import { Button, Dropdown, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import FloatLabel from "../../FloatingLabel";
import { CustomDropdownIndicator } from "../partner/sendMessageModal";

import { AiFillDelete } from "react-icons/ai";
import AsyncSelect from "react-select/async";
import AdminService from "../../../service/AdminService";
import TemplateService from "../../../service/TemplateService";
import { PopUpComponent } from "../../ConfirmPopup";
import HTMLTextEditor from "../../Dashboard/kampaign/HtmlEditor";

const Index = () => {
  const [timer, setTimer] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedPartner, setSelectedPartners] = useState([]);

  const [Zustimmung, setZustimmung] = useState("");
  const [selected, setSelected] = useState({ selected: false, id: "" });
  const [template, setTemplate] = useState("");
  const [formLoading, setFormLoading] = useState({
    send: false,
    save: false,
  });

  const [messageTemplates, setMessageTemplates] = useState([]);
  const inputChanged = (e) => {
    return new Promise((resolve, reject) => {
      try {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
          const user = await AdminService.userSearch({
            text: e,
          });

          setSelectOptions(
            user.data.users.map((e) => ({
              value: e._id,
              label: e?.firstname + e?.lastname,
            }))
          );

          resolve(
            user.data.users.map((e) => ({
              value: e._id,
              label: e?.firstname + e?.lastname,
            }))
          );
        }, 500);

        setTimer(newTimer);
      } catch (error) {}
    });
  };
  const selectAllPartners = async (id) => {
    try {
      const user = await AdminService.listUsers({
        furtherFilter: { "subscription.paid": true },
      });

      const allPromoters = user.data.users.map((e) => ({
        value: e._id,
        label: e?.firstname + e?.lastname,
      }));

      if (allPromoters.length > 0) {
        setSelectedPartners([...allPromoters]);
      }
      setSelectOptions(allPromoters);
    } catch (error) {}
  };
  const getTemplates = async () => {
    const { data } = await TemplateService.listTemplates();
    setMessageTemplates(data);
  };
  useEffect(() => {
    getTemplates();
  }, []);
  const variableSetter = (setter, variabletext) => {
    navigator.clipboard.writeText(variabletext);
    message.info("Kopiert");
    // setter((prev) => `${prev.slice(0, prev.length - 5)}  ${variabletext}</p>`);
  };
  const Variable = ({ setter }) => {
    return (
      <div className="rounded-3xl p-3 w-full flex overflow-auto shadow-inner whitespace-nowrap">
        <div
          className="text-trkis cursor-pointer text-[1rem]"
          onClick={() => variableSetter(setter, "{Partner Vorname}")}
        >{`{Partner Vorname} `}</div>
        <div
          className="text-trkis cursor-pointer text-[1rem]"
          onClick={() => variableSetter(setter, "{Partner Nachname}")}
        >{`{Partner Nachname} `}</div>
      </div>
    );
  };
  const sendMessage = async () => {
    setFormLoading({ send: true });
    if (selectedPartner.length === 0) {
      return message.error("Es ist mindestens ein Promoter erforderlich");
    }
    try {
      await AdminService.sendMessageToPartner({
        subject: Zustimmung,
        message: template,
        partners: selectedPartner?.map((e) => e.value),
      });
    } catch (error) {}
    setZustimmung("");
    setTemplate("");
    setFormLoading({ send: false });
  };
  const createNewTemplate = async () => {
    setFormLoading({
      save: true,
    });
    try {
      if (!selected.selected) {
        await TemplateService.createTemplate({
          subject: Zustimmung,
          message: template,
        });
      } else {
        await TemplateService.updateTemplate(selected.id, {
          subject: Zustimmung,
          message: template,
        });
      }

      getTemplates();
    } catch (error) {}
    setZustimmung("");
    setTemplate("");
    setFormLoading({
      save: false,
    });
  };
  return (
    <div>
      <h1 className="text-center text-xl font-semibold">
        Manuelle Admin-Kampagne
      </h1>
      <div className="p-8 rounded-11xl bg-[#fafafa] flex flex-col gap-8">
        <div className=" md:flex-nowrap flex-wrap flex flex-row items-start justify-start gap-[1.88rem]">
          <AsyncSelect
            className="!border-none react-select bg-transparent !text-xl !outline-none hover:!outline-none !shadow-none"
            placeholder="Suche nach einem Partner"
            loadOptions={inputChanged}
            cacheOptions
            defaultOptions
            options={selectOptions}
            value={selectedPartner}
            onChange={(e) => setSelectedPartners(e)}
            isMulti={true}
            // isClearable
            isSearchable
            classNamePrefix={"super-select"}
            components={{
              DropdownIndicator: CustomDropdownIndicator,
              IndicatorSeparator: () => <></>,
            }}
          />

          <Button
            className="bg-darkslateblue"
            type="primary "
            onClick={selectAllPartners}
          >
            Alle Aktiven auswählen
          </Button>
        </div>
        <div className=" flex justify-between  w-full ">
          <div className="w-full md:flex-nowrap flex-wrap flex  flex-row items-start justify-between gap-[1.88rem]">
            <div className="w-full  rounded-3xs px-3 flex flex-row  items-center justify-center gap-[0.63rem] border-[1px] border-solid border-grey-accent">
              <FloatLabel
                label={"Titel"}
                className={"w-full black-search"}
                keepitfocused
              >
                <Input
                  className="!border-none  bg-transparent w-full !outline-none hover:!outline-none !shadow-none"
                  value={Zustimmung}
                  onChange={(e) => setZustimmung(e.target.value)}
                />
              </FloatLabel>
            </div>
            <div className="flex justify-between gap-3 ">
              <Dropdown
                trigger={["click"]}
                className=" text-[20px] font-semibold w-full"
                menu={{
                  items: messageTemplates.map((e, i) => ({
                    key: i,
                    label: (
                      <div
                        className="flex justify-between"
                        onClick={() => {
                          setTemplate(e.message);
                          setZustimmung(e.subject);
                          setSelected({
                            selected: true,
                            id: e._id,
                          });
                        }}
                      >
                        <p>{e.subject}</p>
                        <AiFillDelete
                          onClick={() =>
                            PopUpComponent({
                              onConfirm: async () => {
                                await TemplateService.deleteTemplate(e._id);
                                setTemplate("");
                                setZustimmung("");
                                setMessageTemplates((prev) =>
                                  prev.filter((fe) => fe._id !== e._id)
                                );
                              },

                              heading:
                                "Bist Du sicher, dass Du die Vorlage löschen möchtest?",
                              subheading: "",
                              confirmButtonText: "Löschen",
                              cancelButtonText: "Abbrechen",
                            })
                          }
                        />
                      </div>
                    ),
                  })),
                }}
              >
                <Button
                  className="bg-darkslateblue text-sm font-normal"
                  type="primary "
                >
                  Vorlagen{" "}
                </Button>
              </Dropdown>
              <Button
                className="bg-darkslateblue "
                type="primary "
                onClick={() => [setTemplate("")]}
              >
                {" "}
                Zurücksetzen
              </Button>
            </div>
          </div>
        </div>
        <div>
          <Variable setter={setTemplate} />

          <div className="relative text-[1rem] text-text-sec w-full">
            <HTMLTextEditor
              label={"Nachricht"}
              value={template}
              onChange={(e) => {
                setTemplate(e);
              }}
              quillattributes={{
                className: "w-full",
              }}
              variables={["{Partner Vorname}", "{Partner Nachname}"]}
            />
          </div>
        </div>
        <Button
          type="primary"
          onClick={() => createNewTemplate()}
          className="w-full h-[42px] rounded-[10px]"
          loading={formLoading.save}
        >
          {!selected.selected ? "Vorlage hinzufügen" : "Vorlage speichern"}
        </Button>
        <div>
          <Button
            className="w-full h-[42px] rounded-[10px]"
            type="primary"
            onClick={sendMessage}
            loading={formLoading.send}
          >
            Absenden
          </Button>
        </div>
      </div>
      {/* <h1 className="text-center text-xl font-semibold my-8">
        Automatisierte Admin-Kampagnen
      </h1>
      <div className="p-8 flex flex-col gap-8">
        {Object.keys(Data).map((e, i) => (
          <CollapseItem
            title={
              <div className="flex items-center trkisswitch gap-8 text-ellipsis ">
                <Switch
                  checked={adminConfig[e]?.status}
                  onChange={(ce) => handleChange(e, ce)}
                />
                <p className="text-ellipsis overflow-hidden whitespace-nowrap">
                  {Data[e]}
                </p>
              </div>
            }
            key={i}
            line={false}
          >
            <div className="p-6">
              <FloatLabel
                label={"Nachricht Email"}
                keepitfocused
                className={"w-full"}
              >
                <input
                  className="outline-none  rounded-3xs overflow-hidden flex text-[1rem] flex-row py-[8px] px-[0.94rem] items-center justify-center border-[1px] border-solid border-grey-accent w-full bg-transparent"
                  value={adminConfig[e]?.message}
                  onChange={(ce) => handleChange(e, ce.target.value)}
                />
              </FloatLabel>
            </div>
          </CollapseItem>
        ))}

        <Button
          type="primary"
          className="w-full h-[40px] font-bold"
          onClick={handleSave}
        >
          Speichern
        </Button>
      </div> */}
    </div>
  );
};

export default Index;
