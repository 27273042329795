import { Checkbox, message } from "antd";
import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../redux/auth/actions.js";
import { store } from "../../../redux/store.js";
import AuthService from "../../../service/AuthService.js";
import UserService from "../../../service/UserService.js";
import { storeToken } from "../../../utils/LocalStorageHandler.js";
import FloatingLabel from "../../FloatingLabel/index";
import LoadingButton from "../../LoadingButton/LoadingButton.jsx";
const Index = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    confirmPass: "",
    checkbox: false,
    checkbox2: false,
  });
  const navigate = useNavigate();
  const [seePass, setSeePass] = useState(true);
  const [seeConfirmPass, setConfirmSeePass] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    try {
      if (formData.confirmPass !== formData.password) {
        return message.error(
          "Passwort und Bestätigungspasswort sind nicht identisch"
        );
      }
      if (!formData.checkbox) {
        return message.error(
          "Bitte akzeptiere die Allgemeinen Geschäftsbedingungen"
        );
      }
      if (!formData.checkbox2) {
        return message.error(
          "Bitte bestätige, dass Du ein Gewerbebetreibender bist"
        );
      }
      await AuthService.register(formData);

      const res = await AuthService.login(formData);
      storeToken({
        access_token: res.data.accessToken,
        refresh_token: res.data.refreshToken,
      });
      const me = await UserService.me();

      if (!me?.data) return;

      store.dispatch(login(me.data));
      navigate("/dashboard/");
    } catch (error) {
      setFormData({
        companyName: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        confirmPass: "",
        checkbox: false,
        checkbox2: false,
      });
    }
    setFormLoading(false);
  };
  return (
    <div className="flex justify-center items-center tertiary:p-[100px] p-0 sm:p-[10px]">
      <div className="min-h-[750px] tertiary:block hidden w-[30%] bg-[linear-gradient(135deg,#0084F7_0%,#1189F2_100%)] rounded-[20px]">
        <div className="w-[210px] h-[30px] m-10">
          <img src="/images/logo-domain-04_black 1.png" alt="" />
        </div>
        <div className="m-10 my-[6rem]">
          <div className="text-primary text-white font-bold my-10">
            Jetzt registrieren & 14 Tage kostenlos testen
          </div>
          <div>
            <ul className="gray-list-second flex flex-col gap-[20px] ">
              <li className="flex items-center text-xl font-semibold text-[#d6d6d6]">
                <p>Empfehlungen melden sich bei Dir</p>
              </li>
              <li className="flex items-center text-xl font-semibold text-[#d6d6d6]">
                <p>100% DSVGO Konform</p>
              </li>
              <li className="flex items-center text-xl font-semibold text-[#d6d6d6]">
                <p>clevere KI für noch mehr Empfehlungen</p>
              </li>
              <li className="flex items-center text-xl font-semibold text-[#d6d6d6]">
                <p>automatisiertes Prämiensystem für Deine Kunden</p>
              </li>
              <li className="flex items-center text-xl font-semibold text-[#d6d6d6]">
                <p>Leistungsformel zur Verbesserung Deines Arbeitskreislaufs</p>
              </li>
              <li className="flex items-center text-xl font-semibold text-[#d6d6d6]">
                <p>Planbares Neugeschäft</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className=" tertiary:w-[50%] tertiary:text-start w-full  text-center min-h-[750px] tertiary:items-start items-center  px-10 flex flex-col justify-between">
        <div>
          <h1 className="text-primary font-bold">Registrieren</h1>
          <div className="flex  sm:text-xl text-base font-semibold text-[#868686] ">
            <p>
              Bereits einen Account?{" "}
              <Link to="/login" className="text-[#0084F7]">
                Anmelden
              </Link>
            </p>
          </div>
        </div>
        <form
          className="mt-20 max-w-[712px] grid gap-[30px]"
          onSubmit={handleSubmit}
        >
          <div className="flex sm:gap-3 gap-[30px] sm:flex-nowrap flex-wrap tertiary:justify-start justify-between">
            <FloatingLabel
              value={formData.firstname}
              label={"Vorname"}
              unFocusClass="!top-[16px] !text-[16px]"
              className={
                "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
              }
            >
              <input
                required
                value={formData.firstname}
                name="firstname"
                type="text"
                onChange={handleChange}
                className="tertiary:w-[310px] w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
              />
            </FloatingLabel>
            <FloatingLabel
              value={formData.lastname}
              label={"Nachname"}
              unFocusClass="!top-[16px] !text-[16px]"
              className={
                "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
              }
            >
              <input
                required
                value={formData.lastname}
                type="text"
                onChange={handleChange}
                name="lastname"
                className="tertiary:w-[310px] w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
              />
            </FloatingLabel>
          </div>

          <FloatingLabel
            label={"E-Mail Adresse"}
            value={formData.email}
            unFocusClass="!top-[16px] !text-[16px]"
            className={"sm:my-[12px] m-0"}
          >
            <input
              required
              value={formData.email}
              name="email"
              type="email"
              onChange={handleChange}
              className="border-[1px]  text-[20px] font-semibold border-[#d6d6d6] w-full tertiary:w-[80%] rounded-[10px] p-3 outline-none bg-transparent  "
            />
          </FloatingLabel>
          <FloatingLabel
            label={"Telefonnummer"}
            value={formData.phone}
            unFocusClass="!top-[16px] !text-[16px]"
            className={"sm:my-[12px] m-0"}
          >
            <input
              required
              value={formData.phone}
              name="phone"
              type="text"
              onChange={handleChange}
              className="border-[1px]  text-[20px] font-semibold border-[#d6d6d6] w-full tertiary:w-[80%] rounded-[10px] p-3 outline-none bg-transparent  "
            />
          </FloatingLabel>
          <FloatingLabel
            label={"Firma"}
            value={formData.companyName}
            unFocusClass="!top-[16px] !text-[16px]"
            className={"sm:my-[12px] m-0"}
          >
            <input
              required
              value={formData.companyName}
              name="companyName"
              type="text"
              onChange={handleChange}
              className="border-[1px]  text-[20px] font-semibold border-[#d6d6d6] w-full tertiary:w-[80%] rounded-[10px] p-3 outline-none bg-transparent  "
            />
          </FloatingLabel>
          <div className="flex sm:gap-3 gap-[30px] sm:flex-nowrap flex-wrap">
            <FloatingLabel
              keepitfocused
              label={"Passwort"}
              value={formData.password}
              unFocusClass="!top-[16px] !text-[16px]"
              className={
                "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
              }
            >
              <input
                required
                type={!seePass ? "text" : "password"}
                value={formData.password}
                name="password"
                onChange={handleChange}
                className="tertiary:w-[310px] w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
              />
              {seePass ? (
                <AiOutlineEyeInvisible
                  onClick={() => setSeePass(!seePass)}
                  className="font-semibold cursor-pointer  !text-[20px]"
                />
              ) : (
                <AiOutlineEye
                  onClick={() => setSeePass(!seePass)}
                  className="font-semibold cursor-pointer  !text-[20px]"
                />
              )}
            </FloatingLabel>
            <FloatingLabel
              keepitfocused
              label={"Passwort bestätigen"}
              value={formData.confirmPass}
              unFocusClass="!top-[16px] !text-[16px]"
              className={
                "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
              }
            >
              <input
                required
                type={!seeConfirmPass ? "text" : "password"}
                value={formData.confirmPass}
                name="confirmPass"
                id="reg-pass"
                onChange={handleChange}
                className="tertiary:w-[310px] w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
              />
              {seeConfirmPass ? (
                <AiOutlineEyeInvisible
                  onClick={() => setConfirmSeePass(!seeConfirmPass)}
                  className="font-semibold cursor-pointer  !text-[20px]"
                />
              ) : (
                <AiOutlineEye
                  onClick={() => setConfirmSeePass(!seeConfirmPass)}
                  className="font-semibold cursor-pointer  !text-[20px]"
                />
              )}
            </FloatingLabel>
          </div>
          <div className="flex items-center justify-start tertiary:gap-4 gap-1">
            <Checkbox
              value={formData.checkbox}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, checkbox: e.target.checked }))
              }
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Ich bestätige die AGBs und Datenschutzbestimmungen.
            </p>
          </div>
          <div className="flex items-center justify-start tertiary:gap-4 gap-1">
            <Checkbox
              value={formData.checkbox2}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  checkbox2: e.target.checked,
                }))
              }
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Ich bestätige, dass ich Gewerbetreibender bin.
            </p>
          </div>
          <LoadingButton text={"Loslegen"} loading={formLoading} />
        </form>
      </div>
    </div>
  );
};

export default Index;
