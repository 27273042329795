import React from "react";

const CustomImageLoader = ({ src, classNames }) => {
  return (
    <div
      className={classNames}
      style={{
        backgroundImage: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 0%",
        backgroundSize: "cover",
      }}
    ></div>
  );
};

export default CustomImageLoader;
