import React from "react";

const PortraitAndText = ({ className, landingPageData }) => {
  const {
    transformed_accompanyingTextTwo,
    transformed_accompanyingTextThree,
    primary_image,
  } = landingPageData;

  return (
    <div
      className={`${className} min-h-[250px] px-4 xs:px-7 gap-7 primary:px-40 container-hide-scrollbar`}
    >
      <div
        className="float-right w-1/2 ml-4 rounded-sm aspect-[9/10] max-h-[300px]"
        style={{
          backgroundImage: `url(${primary_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="box-border w-full pr-4 md:w-1/2 min-h-[300px]">
        <div className="font-bold">
          <p className="pb-4">{transformed_accompanyingTextTwo}</p>
          <p>{transformed_accompanyingTextThree}</p>
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default PortraitAndText;
