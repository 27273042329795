import { Button, Divider, Skeleton, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  AiFillCaretLeft,
  AiOutlinePlayCircle,
  AiTwotonePlayCircle,
} from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import PublicService from "../../service/PublicService";

const Lessons = () => {
  const { chapterId, id } = useParams();
  const [newvideo, setnewvideo] = React.useState();
  const [tutorial, setTutorial] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chapter, setChapter] = useState(null);
  const navigate = useNavigate();
  const getTutorialById = async (chapterId, id) => {
    try {
      const { data } = await PublicService.detailTutorial(chapterId);
      setTutorial({ ...data });
      setnewvideo(data?.video_src?.find((e) => e?._id === id));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  }, [newvideo]);

  const getChapter = useCallback(async () => {
    try {
      const { data } = await PublicService.detailTutorial(chapterId);
      setChapter({ ...data });
      setnewvideo(data?.video_src?.[0]);
    } catch (error) {
      console.log(error);
    }
  }, [chapterId]);

  const clickhandle = (element) => {
    setnewvideo(element);
  };

  React.useEffect(() => {
    getTutorialById(chapterId, id);
  }, [chapterId, id]);
  React.useEffect(() => {
    getChapter();
  }, [getChapter]);
  if (!tutorial) {
    return <Skeleton active />;
  }
  const prevVideo = () => {
    const videos = [...tutorial?.video_src];

    for (let i = 0; i < videos?.length; i++) {
      if (videos[i]?._id === newvideo?._id) {
        setnewvideo(videos[i - 1]);
        return;
      }
    }
  };
  const nextVideo = () => {
    const videos = [...tutorial?.video_src];

    for (let i = 0; i < videos?.length; i++) {
      if (videos[i]?._id === newvideo?._id) {
        setnewvideo(videos[i + 1]);
        return;
      }
    }
  };

  if (loading) return <Skeleton active />;
  return (
    <div className="h-[120vh] flex-col xl:flex-row flex xl:p-4 p-0 gap-14 ">
      <div className="w-full flex flex-col">
        <Space
          className="mb-4 flex items-center cursor-pointer"
          onClick={() => navigate(`/dashboard/chapter/${chapterId}`)}
        >
          <AiFillCaretLeft size={25} />
          <h1 className="font-bold text-xl">Kapitel: {chapter?.title}</h1>
        </Space>

        <Space className="mb-4 flex items-center cursor-pointer">
          <h1 className="font-bold text-xl">Lektion: {newvideo?.title}</h1>
        </Space>

        <video
          controls
          className="w-full rounded-11xl overflow-hidden"
          playsInline={true}
          poster={newvideo?.thumbnail}
          preload="metadata"
        >
          <source src={newvideo?.url} type="video/mp4" />
        </video>
        <div className="my-3 flex justify-between">
          <Button
            onClick={prevVideo}
            disabled={newvideo?._id === tutorial?.video_src[0]?._id}
          >
            Vorherige
          </Button>
          <Button
            onClick={nextVideo}
            disabled={
              newvideo?._id ===
              tutorial?.video_src[tutorial?.video_src?.length - 1]?._id
            }
          >
            Nächste
          </Button>
        </div>
        <div
          alignItems={"flex-start"}
          className="flex flex-col items-start w-full p-2"
          p={8}
        >
          <div>{newvideo?.description}</div>
        </div>
        <div className="xl:w-[500px]   items-stretch gap-2  flex flex-col w-full  overflow-auto">
          <Divider />

          {newvideo?.pdfs?.length > 0 && (
            <>
              <h1 className="font-bold text-lg">Inhalte zum Herunterladen</h1>
              <div className="h-[600px] overflow-auto flex flex-col gap-5">
                {newvideo?.pdfs?.map((e, i) => (
                  <a
                    rel="noreferrer"
                    href={e?.url}
                    target="_blank"
                    className="bg-darkslateblue text-white  rounded-lg py-2 px-3 shadow-inner flex justify-between items-center"
                    key={i}
                  >
                    {e?.name}
                  </a>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="xl:p-4 p-0">
        <h1 className="text-xl font-bold">Lektionen</h1>
        <div className="my-2 h-[600px] overflow-auto">
          {tutorial?.video_src?.map((element, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  clickhandle(element);
                }}
                className="cursor-pointer bg-[#fafafa] p-2 rounded-2xl shadow-inner"
              >
                <video
                  src={element?.url}
                  poster={element?.thumbnail}
                  className="rounded-xl max-w-full h-[200px] min-w-full bg-slate-500 cursor-pointer"
                />

                <div>
                  {newvideo?.url === element?.url ? (
                    <div className="font-bold flex  items-center text-ellipsis mt-3 whitespace-nowrap">
                      <AiTwotonePlayCircle />
                      Läuft gerade
                    </div>
                  ) : (
                    <div className="font-bold flex  items-center text-ellipsis mt-3 whitespace-nowrap">
                      <AiOutlinePlayCircle />
                      {element?.title}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Lessons;
