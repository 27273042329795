import { Button, Dropdown, Input, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import FloatLabel from "../../FloatingLabel";

import TemplateService from "../../../service/TemplateService";

import { AiFillDelete } from "react-icons/ai";

import AdminService from "../../../service/AdminService";
import { PopUpComponent } from "../../ConfirmPopup";
import HTMLTextEditor from "../../Dashboard/kampaign/HtmlEditor";
export const CustomDropdownIndicator = () => {
  return (
    <div className="custom-dropdown-indicator">
      <img
        className=" w-[20px]  shrink-0"
        alt=""
        src="/images/icons8lupe2-1.png"
      />
    </div>
  );
};

const MessagePartnerModal = ({ open, close, Partner_id }) => {
  const [selected, setSelected] = useState({ selected: false, id: "" });

  const variableSetter = (setter, variabletext) => {
    navigator.clipboard.writeText(variabletext);
    message.info("Kopiert");
    // setter((prev) => `${prev.slice(0, prev.length - 5)}  ${variabletext}</p>`);
  };

  const [Zustimmung, setZustimmung] = useState("");

  const [template, setTemplate] = useState("");

  const [messageTemplates, setMessageTemplates] = useState([]);
  const [formDataLoading, setFormDataLoading] = useState({
    message: false,
    template: false,
  });

  const getTemplates = async () => {
    const { data } = await TemplateService.listTemplates();
    setMessageTemplates(data);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const createNewTemplate = async () => {
    setFormDataLoading({ template: true });
    try {
      if (!selected.selected) {
        await TemplateService.createTemplate({
          subject: Zustimmung,
          message: template,
        });
      } else {
        await TemplateService.updateTemplate(selected.id, {
          subject: Zustimmung,
          message: template,
        });
      }

      getTemplates();
    } catch (error) {}
    setFormDataLoading({ template: false });
  };

  const sendMessage = async () => {
    setFormDataLoading({ message: true });
    try {
      await AdminService.sendMessageToPartner({
        subject: Zustimmung,
        message: template,
        partners: [Partner_id],
      });
    } catch (error) {}
    setFormDataLoading({ message: false });
  };

  const Variable = ({ setter }) => {
    return (
      <div className="rounded-3xl p-3 w-full flex overflow-auto shadow-inner whitespace-nowrap">
        <div
          className="text-trkis cursor-pointer text-[1rem]"
          onClick={() => variableSetter(setter, "{Partner Vorname}")}
        >{`{Partner Vorname} `}</div>
        <div
          className="text-trkis cursor-pointer text-[1rem]"
          onClick={() => variableSetter(setter, "{Partner Nachname}")}
        >{`{Partner Nachname} `}</div>
      </div>
    );
  };
  return (
    <Modal
      open={open}
      onCancel={close}
      okText="Absenden"
      onOk={sendMessage}
      okButtonProps={{ loading: formDataLoading.message }}
    >
      <div className=" w-full  text-center text-[1.75rem] text-text-prim font-label-medium">
        <div className="  flex flex-col items-start justify-start gap-[2.19rem]">
          <div className=" font-semibold inline-block w-full">
            Manuelle Nachrichten an Deine Partner
          </div>

          <div className="w-full  text-left text-[1.25rem]">
            <div className=" top-[0rem] left-[0rem] flex flex-col items-start justify-start">
              <div className="w-full flex flex-col items-start justify-start">
                <div className="w-full rounded-11xl bg-kacheln-bg flex flex-col py-[1.56rem] px-[2.5rem] box-border items-start justify-start">
                  <div className="w-full flex flex-col items-start justify-start">
                    <div className="w-full flex flex-col items-start justify-start gap-[1.88rem]">
                      <div className=" flex justify-between  w-full ">
                        <div className="w-full md:flex-nowrap flex-wrap flex  flex-row items-start justify-between gap-[1.88rem]">
                          <div className="w-full  rounded-3xs px-3 flex flex-row  items-center justify-center gap-[0.63rem] border-[1px] border-solid border-grey-accent">
                            <FloatLabel
                              label={"Betreff"}
                              className={"w-full black-search"}
                              keepitfocused
                            >
                              <Input
                                className="!border-none  bg-transparent w-full !outline-none hover:!outline-none !shadow-none"
                                value={Zustimmung}
                                onChange={(e) => setZustimmung(e.target.value)}
                              />
                            </FloatLabel>
                          </div>
                          <div className="flex justify-between gap-3 ">
                            <Dropdown
                              trigger={["click"]}
                              className=" text-[20px] font-semibold w-full"
                              menu={{
                                items: messageTemplates.map((e, i) => ({
                                  key: i,
                                  label: (
                                    <div
                                      className="flex justify-between"
                                      onClick={() => {
                                        setTemplate(e.message);
                                        setZustimmung(e.subject);
                                        setSelected({
                                          selected: true,
                                          id: e._id,
                                        });
                                      }}
                                    >
                                      <p>{e.subject}</p>
                                      <AiFillDelete
                                        onClick={() =>
                                          PopUpComponent({
                                            onConfirm: async () => {
                                              await TemplateService.deleteTemplate(
                                                e._id
                                              );

                                              setSelected({
                                                selected: false,
                                                id: "",
                                              });
                                              setMessageTemplates((prev) =>
                                                prev.filter(
                                                  (fe) => fe._id !== e._id
                                                )
                                              );
                                            },

                                            heading:
                                              "Bist Du sicher, dass Du die Vorlage löschen möchtest?",
                                            subheading: "",
                                            confirmButtonText: "Löschen",
                                            cancelButtonText: "Abbrechen",
                                          })
                                        }
                                      />
                                    </div>
                                  ),
                                })),
                              }}
                            >
                              <Button
                                className="bg-darkslateblue text-sm font-normal"
                                type="primary "
                              >
                                {" "}
                                Vorlagen
                              </Button>
                            </Dropdown>
                            <Button
                              className="bg-darkslateblue "
                              type="primary "
                              onClick={() => [
                                setTemplate(""),
                                setZustimmung(""),
                                setSelected({ selected: false, id: "" }),
                              ]}
                            >
                              {" "}
                              Zurücksetzen
                            </Button>
                          </div>
                        </div>
                      </div>

                      <Variable setter={setTemplate} />

                      <div className="relative text-[1rem] text-text-sec w-full">
                        <HTMLTextEditor
                          label={"Nachricht"}
                          value={template}
                          onChange={(e) => {
                            setTemplate(e);
                          }}
                          quillattributes={{
                            className: "w-full",
                          }}
                          variables={[
                            "{Partner Vorname}",
                            "{Partner Nachname}",
                          ]}
                        />
                      </div>
                      <Button
                        type="primary"
                        loading={formDataLoading.template}
                        onClick={() => createNewTemplate()}
                        className="text-center  w-full font-semibold rounded-[10px]"
                      >
                        {!selected.selected
                          ? "Vorlage hinzufügen"
                          : "Vorlage speichern"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MessagePartnerModal;
