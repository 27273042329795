const Drag = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2187 13.8216L10.2187 7.54883C10.2187 7.16933 10.5267 6.86133 10.9062 6.86133C11.2858 6.86133 11.5937 7.16933 11.5937 7.54883L11.5937 13.8216C11.5937 14.2011 11.2857 14.5091 10.9062 14.5091C10.5267 14.5091 10.2187 14.2014 10.2187 13.8216Z"
      fill="#868686"
    />
    <path
      d="M7.8125 9.71772C7.8125 9.53828 7.88262 9.3585 8.02184 9.22375L10.4281 6.89381C10.6945 6.63566 11.118 6.63566 11.3844 6.89381L13.7907 9.22375C14.0636 9.48741 14.0705 9.92259 13.8065 10.1959C13.5421 10.4685 13.1069 10.4753 12.8343 10.2113L10.9062 8.34478L8.97815 10.2117C8.70556 10.476 8.27003 10.4688 8.00603 10.1962C7.87678 10.0625 7.8125 9.88994 7.8125 9.71772Z"
      fill="#868686"
    />
    <path
      d="M14 12.292C14 12.4715 13.9299 12.6513 13.7907 12.786L11.3844 15.116C11.118 15.3741 10.6945 15.3741 10.4281 15.116L8.02185 12.786C7.74891 12.5224 7.74204 12.0872 8.00604 11.8139C8.27038 11.5413 8.70557 11.5344 8.97816 11.7984L10.9063 13.665L12.8343 11.7981C13.1069 11.5337 13.5425 11.541 13.8065 11.8135C13.9357 11.9473 14 12.1198 14 12.292Z"
      fill="#868686"
    />
    <path
      d="M18.5625 11C18.5625 15.1766 15.1766 18.5625 11 18.5625C6.82344 18.5625 3.4375 15.1766 3.4375 11C3.4375 6.82344 6.82344 3.4375 11 3.4375C15.1766 3.4375 18.5625 6.82344 18.5625 11ZM4.8125 11C4.8125 14.4172 7.58278 17.1875 11 17.1875C14.4172 17.1875 17.1875 14.4172 17.1875 11C17.1875 7.58278 14.4172 4.8125 11 4.8125C7.58278 4.8125 4.8125 7.58278 4.8125 11Z"
      fill="#868686"
    />
  </svg>
);
export { Drag };
