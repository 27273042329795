import React, { useState } from "react";
import { Collapse } from "react-collapse";

const CollapseItem = ({ title, desc }) => {
  const [open, setOpen] = useState(false);

  const handletoggle = () => {
    setOpen(!open);
  };

  return (
    <div
      className="cursor-pointer transition animate-fade-left  my-10  bg-[#FAFAFA] rounded-[1.25rem]   border-[1px] border-[#D8D8D8]"
      onClick={handletoggle}
    >
      <div className="  p-[1.875rem]">
        <div className="flex justify-between items-center ">
          <h1 className="font-semibold sm:text-[1.25rem] text-base ">
            {title}
          </h1>
          <img
            src="/images/icons8pfeil-ausklappen3-1.png"
            className={`transition-all ${open && "rotate-90"}`}
            alt=""
          />
        </div>
      </div>
      {open && (
        <div className="px-[1.875rem] w-full">
          <hr className="bg-[#D8D8D8] text-[#D8D8D8] w-[100%] h-[2px]" />
        </div>
      )}
      <Collapse
        isOpened={open}
        s
        className={`transition-all    animate-duration-1000 animate-dissolve`}
      >
        <p
          className={`p-[1.875rem]  transition-all sm:text-[1rem]  text-sm font-semibold dissolve animate-fade-down animate-duration-1000`}
        >
          {desc}
        </p>
      </Collapse>
    </div>
  );
};

export default CollapseItem;
