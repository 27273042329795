import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class QuestionService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/questions`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  listQuestions(landingpageId) {
    console.log("QuestionService.listQuestions()")
    return this.api.get("/list", { params: { landingpage_id: landingpageId } });
  }

  createQuestion(questionData) {
    return this.api.post("/create", questionData);
  }

  updateQuestion(questionId, updatedData) {
    return this.api.put("/update", updatedData, { params: { id: questionId } });
  }
  deleteQuestion(questionId) {
    return this.api.delete("/deleteQuestion/" + questionId);
  }

  wipeAndAdd(ladingpageId, data) {
    console.log("QuestionService.wipeAndAdd()", data)
    return this.api.post(`/wipeAndAdd?id=${ladingpageId}`, data);
  }


}

export default new QuestionService();
