import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class EmpfehlungService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/empfehlung`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  list() {
    return this.api.get("/list");
  }

  detail(id) {
    return this.api.get(`/detail?id=${id}`);
  }

  create(data) {
    return this.api.post("/create", data);
  }

  update(id, data) {
    return this.api.put(`/update?id=${id}`, {
      ...data,
      baseUrl: window.location.origin,
    });
  }

  getNotes(id) {
    return this.api.get(`/getNotes?id=${id}`);
  }

  updateNotes(id, data) {
    return this.api.put(`/updateNotes?id=${id}`, data);
  }
  deleteLead(id) {
    return this.api.delete(`/deleteLead?id=${id}`);
  }

  convertToPromoter(id, data) {
    return this.api.post(`/convertToPromoter?id=${id}`, {
      ...data,
      baseUrl: window.location.origin,
    });
  }
}

export default new EmpfehlungService();
