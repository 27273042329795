import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LandingPageService from "../../service/LandingPageService";
import PublicService from "../../service/PublicService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";

const Index = () => {
  const { id } = useParams();
  const [Impressum, setImpressum] = useState("");

  const getConditions = useCallback(async () => {
    try {
      const { data } = await LandingPageService.detail(id);

      setImpressum(
        data?.specificImpressumText?.length > 0
          ? data?.specificImpressumText
          : data.user.defaultImpressumText
      );
    } catch (error) {
      console.log(error);
    }
  }, [id]);
  useEffect(() => {
    getConditions();
  }, [getConditions]);
  function createMarkup() {
    return { __html: Impressum };
  }

  if (!Impressum) {
    return <LoadingScreen />;
  }
  return (
    <div className="p-5 wordbreak">
      <h1 className="text-xl font-bold text-center formattierterRechtsText">
        Impressum
      </h1>
      <div className="my-10" dangerouslySetInnerHTML={createMarkup()}></div>
    </div>
  );
};

export default Index;
