import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Table,
  Typography,
  Upload,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CrudService from "../../../service/CrudService";
import UploadToCloudService from "../../../service/UploadToCloudService";

const Testimonials = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [modalVisible, setModalVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [pagination.current]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await CrudService.search(
        "Testimonial",
        pagination.pageSize,
        pagination.current,
        {}
      );
      const items = response.data.items.map((item, index) => ({
        ...item,
        rank: item.rank || index + 1,
      }));
      setData(items.sort((x, y) => x.rank - y.rank));
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Laden");
    }
    setLoading(false);
  };

  const handleCreate = () => {
    setEditingRecord(null);
    form.resetFields();
    setModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await CrudService.delete("Testimonial", id);
      message.success("Erfolgreich gelöscht");
      fetchData();
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Löschen");
    }
  };

  const handleSave = async () => {
    setFormLoading(true);
    if (uploading) return;
    setUploading(true);
    try {
      const values = await form.validateFields();

      if (values.image && values.image.file) {
        const info = await UploadToCloudService.upload(
          values.image.file.originFileObj
        );
        values.image = info.secure_url;
      }

      if (editingRecord) {
        await CrudService.update("Testimonial", editingRecord._id, values);
        message.success("Erfolgreich gespeichert");
      } else {
        await CrudService.create("Testimonial", values);
        message.success("Erfolgreich erstellt");
      }
      fetchData();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Speichern");
    } finally {
      setFormLoading(false);
      setUploading(false);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const newData = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    setData(newData);

    const reorderedData = newData.map((item, index) => ({
      ...item,
      rank: index + 1,
    }));
    try {
      await Promise.all(
        reorderedData.map((item) =>
          CrudService.update("Testimonial", item._id, { rank: item.rank })
        )
      );
      fetchData();
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Speichern der Reihenfolge");
    }
  };

  return (
    <>
      <div className="p-4">
        <Button type="primary" onClick={handleCreate} className="mb-4">
          Neues Testimonial
        </Button>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="testimonials">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: snapshot.isDragging
                            ? "#e6f7ff"
                            : "white",
                          padding: "8px",
                          margin: "0 0 8px 0",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                          minWidth: 275,
                        }}
                      >
                        <div className="flex items-center justify-between ">
                          <div className="flex gap-2 items-center primary:flex-row flex-col">
                            <img
                              src={item.image}
                              alt="avatar"
                              style={{ width: 40, height: 40 }}
                              className="rounded-full"
                            />
                            <Typography.Text>{item.name}</Typography.Text>
                            <div>•</div>
                            <div className="max-w-[100px] truncate">
                              {item.jobTitle}
                            </div>
                            <div>•</div>
                            <div className="max-w-[150px] truncate">
                              {item.reviewText}
                            </div>
                          </div>
                          <Space className="primary:flex-row flex-col">
                            <Button
                              type="link"
                              onClick={() => handleEdit(item)}
                            >
                              Bearbeiten
                            </Button>
                            <Popconfirm
                              title="Sicher?"
                              onConfirm={() => handleDelete(item._id)}
                            >
                              <Button type="link" danger>
                                Löschen
                              </Button>
                            </Popconfirm>
                          </Space>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Modal
        open={modalVisible}
        title={editingRecord ? "Testimonial bearbeiten" : "Neues Testimonial"}
        onCancel={() => setModalVisible(false)}
        onOk={handleSave}
        okButtonProps={{ loading: formLoading }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="image"
            label="Bild"
            rules={[{ required: true, message: "Bitte ein Bild hochladen" }]}
          >
            <Upload listType="picture" maxCount={1}>
              <Button icon={<UploadOutlined />}>Bild hochladen</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Bitte den Namen eingeben" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="jobTitle"
            label="Titel/Job"
            rules={[
              { required: true, message: "Bitte den Titel/Job eingeben" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reviewText"
            label="Bewertungstext"
            rules={[
              { required: true, message: "Bitte den Bewertungstext eingeben" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="active" label="Aktiv" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Testimonials;
