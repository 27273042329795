// MarkText-Funktion, die den Eingabestring und das Array der zu markierenden Wörter akzeptiert
export const markText = (inputString, wordsToMark, color = "blue") => {
  // Escape special characters for use in a regular expression
  const escapeRegExp = (string) =>
    string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Erstellen Sie einen regulären Ausdruck, um die Wörter zu finden, die markiert werden sollen
  const regex = new RegExp(
    `(${wordsToMark.map(escapeRegExp).join("|")})`,
    "gi"
  );

  // Teilen Sie den Eingabestring in ein Array von Teilen auf, wobei die markierten Wörter beibehalten werden
  const parts = inputString.split(regex);

  // Erstellen Sie ein neues Array mit JSX-Elementen, wobei die markierten Wörter in <span> Tags gewickelt sind
  return parts.map((part, index) =>
    regex.test(part) ? (
      <span key={index} style={{ color: color }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};
