import { Button } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import React from "react";

const Header = ({
  onArrowClick,
  onButtonClick,
  className,
}) => {
  return (
    <div className={className}>
      {/** Topbar */}
      <div className="flex items-center justify-between pb-10">
        {/** Arrow */}
        <div className="xl:text-primary text-xl text-[#868686] flex-1">
          <BsArrowLeft onClick={onArrowClick} className="cursor-pointer" />
        </div>

        {/** Logo */}
        <div className="flex-1">
          <img src={"/images/logo.png"} alt="" width="50" className="m-auto" />
        </div>

        {/** Button */}
        <div className="relative flex-1 text-right">
          <Button
            type="primary"
            className="bg-[#001D47]"
            onClick={onButtonClick}
          >
            Vorschau
          </Button>

          {/** Arrow-Img */}
          <img
            src="/images/zeichenflache-24-x-6.png"
            className="absolute right-[12%] top-[70px] hidden tertiary:block"
            alt="arrow-img"
          />
        </div>
      </div>

      {/** Textbox  */}
      <div className="text-base font-semibold text-center lg:text-9xl sm: sm:text-xl">
        <p className="pb-10">
        Erstelle mit wenigen Klicks und Uploads <br /> deine Online Visitenkarte als Landingpage.
        </p>
        <p>Über Vorschau kannst Du immer direkt das Ergebnis sehen.</p>
      </div>
    </div>
  );
};

export default Header;
