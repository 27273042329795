const storeToken = ({ access_token, refresh_token }) => {
  localStorage.setItem(
    "einguter_token",
    JSON.stringify({ access_token, refresh_token })
  );
};
const getToken = () => {
  try {
    const { access_token, refresh_token } = JSON?.parse?.(
      localStorage.getItem("einguter_token")
    );
    return { access_token, refresh_token };
  } catch (error) {
    return { access_token: "", refresh_token: "" };
  }
};
const removeToken = () => {
  localStorage.removeItem("einguter_token");
};

export { getToken, removeToken, storeToken };
