import Compressor from "compressorjs";

export const compressImageToMaxSize = async (base64Image, maxSizeInBytes) => {
  let compressedImage = base64Image;
  let quality = 0.8; 

  while (true) {
    const blob = dataURItoBlob(compressedImage);

    if (blob.size <= maxSizeInBytes) {
      return compressedImage; 
    }

    compressedImage = await new Promise((resolve, reject) => {
      new Compressor(blob, {
        quality,
        success: (compressedBlob) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(compressedBlob);
        },
        error: reject,
      });
    });

    quality -= 0.1; 
    if (quality < 0.2) break; 
  }
  return compressedImage;
};

// Hilfsfunktion zur Umwandlung von base64 in Blob
const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};
