import { message } from "antd";
import jwt_decode from "jwt-decode";
import { logout, setLoading } from "../redux/auth/actions";
import { store } from "../redux/store";
import { getToken, storeToken } from "../utils/LocalStorageHandler";
import { getBackendUrl } from "./getBackendUrl";
message.config({ prefixCls: "antd-toast-custom" });
const refreshFunc = async () => {
  const { access_token, refresh_token } = getToken();

  if (access_token) {
    const decodedToken = jwt_decode(access_token);
    if (5000 + decodedToken.exp * 1000 <= new Date()) {
      var raw = JSON.stringify({
        accessToken: access_token,
        refreshToken: refresh_token,
      });

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
      };

      const result = await fetch(
        `${getBackendUrl()}/auth/refresh`,
        requestOptions
      );
      const response = await result.json();

      if (!response?.accessToken) {
        store.dispatch(logout());
        window.location.href = "/";
        return;
      }

      storeToken({
        access_token: response?.accessToken,
        refresh_token: response?.refreshToken,
      });
    }
  }

  return { access_token, refresh_token };
};

const middleField = (api, customHeaders = {}) => {
  try {
    api.interceptors.response.use(
      (response) => {
        store.dispatch(setLoading(false));

        if (response?.data?.message) {
          if (response?.data?.type) {
            message.info(response.data.message);
            return response;
          }
          message.success(response.data.message);
        }
        return response;
      },
      async (error) => {
        store.dispatch(setLoading(false));
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            if (data.message === "Benutzer nicht gefunden") {
              window.location.href = "/";
              return;
            }
            if (
              data.message === "Du musst noch deine Email-Adresse bestätigen"
            ) {
              store.dispatch(logout());
              window.location.href = "/";
              return;
            }
            if (data.message === "Zugriffsberechtigung steht aus") {
              message.info(
                "Dein Zugang ist aktuell eingeschränkt, bitte wende dich an den Support."
              );
            }

            if (
              data.message === "Invalid access_token" ||
              data.message?.toLowerCase?.() === "jwt malformed"
            ) {
              store.dispatch(logout());
              window.location.href = "/";
              return;
            }

            if (data.message?.toLowerCase?.() === "jwt expired") {
              await refreshFunc();
              window.location.reload();
            }

            if (
              ![
                "jwt must be provided",
                "jwt expired",
                "Zugriffsberechtigung steht aus",
              ].includes(data.message)
            )
              message.error(data.message);
          }
        }
        return Promise.reject(error);
      }
    );

    api.interceptors.request.use(
      async (config) => {
        store.dispatch(setLoading(true));

        const { access_token, refresh_token } = await refreshFunc();
        const specificHeaders = customHeaders[config.url] || {};
        return {
          ...config,
          headers: {
            ...specificHeaders,
            access_token,
            refresh_token,
          },
        };
      },
      (error) => {
        store.dispatch(setLoading(false));
        return Promise.reject(error);
      }
    );
  } catch (error) {}
};
const imageuploadmiddleField = (api) => {
  try {
    api.interceptors.response.use(
      (response) => {
        store.dispatch(setLoading(false));

        if (response?.data?.message) {
          if (response?.data?.type) {
            message.info(response.data.message);
            return response;
          }
          message.success(response.data.message);
        }
        return response;
      },
      async (error) => {
        store.dispatch(setLoading(false));
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            if (
              data.message === "Invalid access_token" ||
              data.message?.toLowerCase?.() === "jwt malformed"
            ) {
              store.dispatch(logout());
              window.location.href = "/";
              return;
            }

            if (data.message?.toLowerCase?.() === "jwt expired") {
              await refreshFunc();
              window.location.reload();
              return;
            }

            message.error(data.message);
          }
        }
        return Promise.reject(error);
      }
    );
  } catch (error) {}
};
export { imageuploadmiddleField, middleField };
