import React, { useState } from "react";

import { Link } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP";
const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  return (
    <nav className="w-full ">
      <div className="justify-between sm:w-[90%] xl:px-4 w-full mx-auto md:items-center md:flex max-w-[99vw]">
        <div>
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <Link to={"/"} className="block">
              <div className="w-[200px] min-w-[200px] max-w-[200px]">
                <MainLogo className="brightness-125 mx-auto transition-all !w-[150px] !min-w-[157px]" />
                <div className="font-normal text-sm ml-5">
                  <p className="whitespace-nowrap">…denn etwas gutes kann</p>
                  <p className="whitespace-nowrap">man weiterempfehlen!</p>
                </div>
              </div>
            </Link>
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 "
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center w-full pb-3 mt-8 md:block md:pb-0 animate-fade-down md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 text-center md:flex md:space-x-6 md:space-y-0">
              <li className=" hover:text-blue-900">
                <a href={"#uberuns"} className="font-bold">
                  Über Uns
                </a>
              </li>

              <li className=" hover:text-blue-900">
                <a href={"#pricing"} className="font-bold">
                  Preise
                </a>
              </li>

              <div className="flex flex-col mt-3 space-y-3 md:hidden">
                <Link to={"/register"} className="font-bold">
                  Registrieren
                </Link>
                <Link
                  to="/login"
                  className="py-[12px] mx-[70px] xs:mx-0 xs:px-[20px]  bg-[#001D47]  text-white rounded-[10px] "
                >
                  Anmelden{" "}
                </Link>
              </div>
            </ul>
          </div>
        </div>
        <div className="hidden space-x-5 md:inline-block">
          <Link to="/register" className="font-bold">
            Registrieren
          </Link>
          <Link
            to="/login"
            className="py-[12px] px-[20px] bg-[#001D47]  text-white rounded-[10px] "
          >
            Anmelden{" "}
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
