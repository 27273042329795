import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class ImageUploadService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/cloudinary`;
    this.cloudUrl = this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api, {
      "/upload": {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async upload(file, type = "auto") {
    console.log("uploadChunked()");

    try {
      const chunkSize = 6 * 1024 * 1024; // 5MB chunks
      const totalChunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;
      let res;
      const uniqueUploadId = `${file.name}-${Date.now()}`; // Unique identifier for the upload

      // Transformation options for video compression
      const transformationOptions = {
        transformation: "vc_h264,w_2000,c_limit",
      };
      const apiKeyRes = await this.api.post("/signatureUpload", {
        toSign: transformationOptions,
      });
      const { apiKey, signature, timestamp } = apiKeyRes.data;

      // Initialize chunk upload
      const cloudinaryUrl = `https://api.cloudinary.com/v1_1/dcwsmnhb9/${type}/upload`;
      const urlWithTransformations = `${cloudinaryUrl}?${new URLSearchParams(
        transformationOptions
      )}`;

      while (currentChunk < totalChunks) {
        const start = currentChunk * chunkSize;
        const end = Math.min(file.size, start + chunkSize) - 1;
        const chunk = file.slice(start, end + 1);
        const contentRange = `bytes ${start}-${end}/${file.size}`;
        console.log(start, end);

        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);
        formData.append("timestamp", timestamp);
        formData.append("chunk", currentChunk + 1);
        formData.append("chunks", totalChunks);

        const response = await fetch(urlWithTransformations, {
          method: "POST",
          body: formData,
          headers: {
            "X-Unique-Upload-Id": uniqueUploadId,
            "Content-Range": contentRange,
          },
        });

        if (!response.ok) {
          throw new Error(`Upload failed at chunk ${currentChunk + 1}`);
        }

        res = await response.json();
        console.log(
          `Chunk ${currentChunk + 1}/${totalChunks} uploaded successfully:`,
          res
        );
        currentChunk++;
      }

      console.log("Upload successful");
      return { secure_url: res.secure_url, duration: res.duration }; // Return the final URL
    } catch (error) {
      console.error("Error during upload:", error.message);
      throw error;
    }
  }

  async delete(url, type) {
    console.log("deleteResource()");
    const public_id = url.split("/").pop().split(".")[0];

    try {
      const signatureRes = await this.api.post("/signatureDelete", {
        toSign: { public_id: public_id },
      });
      const { apiKey, signature, timestamp } = signatureRes.data;

      const formData = new FormData();
      formData.append("signature", signature);
      formData.append("timestamp", timestamp);
      formData.append("api_key", apiKey);
      formData.append("public_id", public_id);

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dcwsmnhb9/${type}/destroy`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Löschen fehlgeschlagen");
      }

      const data = await response.json();
      console.log("Löschen erfolgreich:", data);

      return data;
    } catch (error) {
      console.error("Fehler beim Löschen:", error.message);
      throw error;
    }
  }

  async uploadBase64Image(base64Images, type = "image") {
    try {
      const response = await this.api.post("/uploadBase64", {
        base64Images,
        type,
      });
      return response.data;
    } catch (error) {
      console.error("Fehler beim Hochladen des Base64-Bildes:", error);
      throw error;
    }
  }

  async deleteBase64Image(publicId, type = "image") {
    try {
      const response = await this.api.post("/deleteBase64", { publicId, type });
      return response.data;
    } catch (error) {
      console.error("Fehler beim Löschen des Bildes:", error);
      throw error;
    }
  }
}

export default new ImageUploadService();
