import { Button, Dropdown, Input, Modal, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import FloatLabel from "../../FloatingLabel";

import TemplateService from "../../../service/TemplateService";

import { AiFillDelete } from "react-icons/ai";
import CronService from "../../../service/CronService";

import { PopUpComponent } from "../../ConfirmPopup";
import HTMLTextEditor from "../kampaign/HtmlEditor";
export const CustomDropdownIndicator = () => {
  return (
    <div className="custom-dropdown-indicator">
      <img
        className=" w-[20px]  shrink-0"
        alt=""
        src="/images/icons8lupe2-1.png"
      />
    </div>
  );
};
const variableSetter = (setter, variabletext) => {
  navigator.clipboard.writeText(variabletext);
  message.info("Kopiert");
  // setter((prev) => `${prev.slice(0, prev.length - 5)}  ${variabletext}</p>`);
};

const Variable = ({ setter }) => {
  return (
    <div className="rounded-3xl p-3 w-full flex overflow-auto shadow-inner whitespace-nowrap">
      <div
        className="text-trkis cursor-pointer text-[1rem]"
        onClick={() => variableSetter(setter, "{Partner Vorname}")}
      >{`{Partner Vorname} `}</div>
      <div
        className="text-trkis cursor-pointer text-[1rem]"
        onClick={() => variableSetter(setter, "{Partner Nachname}")}
      >{`{Partner Nachname} `}</div>
    </div>
  );
};
const Index = ({ open, close, promoter_id }) => {
  const [setTemplates] = useState(false);

  const [selected, setSelected] = useState({ selected: false, id: "" });

  const [Zustimmung, setZustimmung] = useState("");

  const [template, setTemplate] = useState(`Hallo {Promoter Vorname},

  auch Du warst der Meinung dass man etwas Gutes wie mich weiterempfehlen sollte. Ich hoffe, das ist noch so?! ;o) 
  
  EinGuterTipp ermöglicht Dir das weiterempfehlen ohne Aufwand und vergibt tolle Prämien an Dich. 
  
  Leider kann ich Dir weder Deinen persönlichen Empfehlungslink schicken, noch Prämien an Dich auszahlen, solange Du keine Einwilligung abgibst. Deshalb hier nochmal der Link (LINK) für dich. Sobald das erledigt ist, erhältst Du kurzfristig Deinen persönlichen Promotionslink und kannst stets Dein Guthaben einsehen und einlösen. 
  
  Wenn Du doch noch Fragen hast, dann melde Dich kurz bei mir. 
  
  Viele Grüße {Partner Vorname}`);

  const [messageTemplates, setMessageTemplates] = useState([]);

  const getTemplates = async () => {
    const { data } = await TemplateService.listTemplates();
    if (data?.length === 0) {
      return setMessageTemplates([
        {
          key: "nodata",
          label: (
            <div className="flex justify-center items-center py-2">
              keine Vorlagen
            </div>
          ),
        },
      ]);
    }
    setMessageTemplates(data);
  };

  useEffect(() => {
    if (open) {
      getTemplates();
    }
  }, [open]);

  const createNewTemplate = async () => {
    try {
      if (!selected.selected) {
        await TemplateService.createTemplate({
          subject: Zustimmung,
          message: template,
        });
      } else {
        await TemplateService.updateTemplate(selected.id, {
          subject: Zustimmung,
          message: template,
        });
      }

      getTemplates();
    } catch (error) {}
  };

  const sendMessage = async () => {
    try {
      CronService.sendMessageToPromoters({
        subject: Zustimmung,
        message: template,
        promoters: [promoter_id],
      })
        .then(close)
        .catch(close);
    } catch (error) {
      close();
    }
  };

  return (
    <Modal open={open} onCancel={close} okText="Absenden" onOk={sendMessage}>
      <div className="w-full flex flex-col items-start justify-start gap-[1.88rem]">
        <div className=" flex justify-between  w-full ">
          <div className="w-full  flex-wrap flex  flex-row items-start justify-between gap-[1.88rem]">
            <div className="w-full  rounded-3xs px-3 flex flex-row  items-center justify-center gap-[0.63rem] border-[1px] border-solid border-grey-accent">
              <FloatLabel
                label={"Betreff"}
                className={"w-full black-search"}
                keepitfocused
              >
                <Input
                  className="!border-none  bg-transparent w-full !outline-none hover:!outline-none !shadow-none"
                  value={Zustimmung}
                  onChange={(e) => setZustimmung(e.target.value)}
                />
              </FloatLabel>
            </div>
            <div className="flex justify-between gap-3 flex-grow relative">
              <Dropdown
                trigger={["click"]}
                className=" text-[20px] font-semibold w-full flex-grow"
                menu={{
                  items:
                    messageTemplates?.length === 0
                      ? [
                          {
                            key: "loading",
                            label: (
                              <div className="flex justify-center items-center py-2">
                                <Spin />
                              </div>
                            ),
                          },
                        ]
                      : messageTemplates.map((e, i) => ({
                          key: i,
                          label: (
                            <div
                              className="flex justify-between"
                              onClick={() => {
                                setTemplate(e.message);
                                setZustimmung(e.subject);
                                setTemplates(false);
                                setSelected({
                                  selected: true,
                                  id: e._id,
                                });
                              }}
                            >
                              <p>{e.subject}</p>
                              <AiFillDelete
                                onClick={() =>
                                  PopUpComponent({
                                    onConfirm: async () => {
                                      await TemplateService.deleteTemplate(
                                        e._id
                                      );
                                      setSelected({
                                        selected: false,
                                        id: "",
                                      });
                                      setMessageTemplates((prev) =>
                                        prev.filter((fe) => fe._id !== e._id)
                                      );
                                    },

                                    heading:
                                      "Bist Du sicher, dass Du die Vorlage löschen möchtest?",
                                    subheading: "",
                                    confirmButtonText: "Löschen",
                                    cancelButtonText: "Abbrechen",
                                  })
                                }
                              />
                            </div>
                          ),
                        })),
                }}
              >
                <Button
                  className="bg-darkslateblue text-sm font-normal flex-grow w-full"
                  type="primary "
                  onClick={() => setTemplates((prev) => !prev)}
                >
                  {" "}
                  Vorlagen
                </Button>
              </Dropdown>
              <Button
                className="bg-darkslateblue flex-grow w-full"
                type="primary "
                onClick={() => [
                  setTemplate(""),
                  setZustimmung(""),
                  setSelected({ selected: false, id: "" }),
                ]}
              >
                {" "}
                Zurücksetzen
              </Button>
            </div>
          </div>
        </div>

        <Variable setter={setTemplate} />

        <div className="relative text-[1rem] text-text-sec w-full">
          <HTMLTextEditor
            label={"Nachricht"}
            value={template}
            onChange={(e) => {
              setTemplate(e);
            }}
            quillattributes={{
              className: "w-full",
            }}
            variables={["{Partner Vorname}", "{Partner Nachname}"]}
          />
        </div>
        <Button
          type="primary"
          onClick={() => createNewTemplate()}
          className="text-center h-[50px] w-full text-xl font-semibold rounded-[10px]"
        >
          {!selected.selected ? "Vorlage hinzufügen" : "Vorlage speichern"}
        </Button>
      </div>
    </Modal>
  );
};

export default Index;
