import {
  Button,
  Card,
  Dropdown,
  Input,
  Skeleton,
  Switch,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  AiFillDelete,
  AiOutlineCopy,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import AsyncSelect from "react-select/async";
import CronService from "../../../service/CronService";

import moment from "moment";
import { Link } from "react-router-dom";
import PublicService from "../../../service/PublicService";
import TemplateService from "../../../service/TemplateService";
import UserService from "../../../service/UserService";
import { copyToClipboard } from "../../../utils/CopyToClipboard";
import { aboTarif } from "../../../utils/divers";
import { PopUpComponent } from "../../ConfirmPopup";
import FloatLabel from "../../FloatingLabel";
import ClipboardButtonsBar from "./ClipboardButtonsBar";
import CollapseItem from "./CollapseItem";
import HTMLTextEditor from "./HtmlEditor";
export const CustomDropdownIndicator = () => {
  return (
    <div className="custom-dropdown-indicator">
      <img
        className=" w-[20px]  shrink-0"
        alt=""
        src="/images/icons8lupe2-1.png"
      />
    </div>
  );
};

const Index = () => {
  const quillRef = useRef();
  const [emailTemplate, setEmailTemplate] = useState("");
  const [user, setUser] = useState({});

  const [timer, setTimer] = useState(null);
  const [selectedPromoter, setSelectedPromoters] = useState([]);
  const [selected, setSelected] = useState({ selected: false, id: "" });
  const [selectOptions, setSelectOptions] = useState([]);
  const [formLoading, setFormLoading] = useState({
    add: false,
    send: false,
    delete: false,
    addText: false,
  });
  const inputChanged = (e) => {
    return new Promise((resolve, reject) => {
      try {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
          const user = await UserService.searchUsers(e);

          setSelectOptions(
            user.data.promoterUsers.map((e) => ({
              value: e._id,
              label: e?.firstname + e?.lastname,
            }))
          );

          resolve(
            user.data.promoterUsers.map((e) => ({
              value: e._id,
              label: e?.firstname + e?.lastname,
            }))
          );
        }, 500);

        setTimer(newTimer);
      } catch (error) {}
    });
  };
  const selectAllPromoters = async (id) => {
    try {
      const user = await UserService.searchUsers("");

      const allPromoters = user.data.promoterUsers.map((e) => ({
        value: e._id,
        label: e?.firstname + e?.lastname,
      }));

      if (allPromoters.length > 0) {
        setSelectedPromoters([...allPromoters]);
      }
      setSelectOptions(allPromoters);
    } catch (error) {}
  };

  const [versandTemplate, setVersandTemplate] = useState(``);
  const [Zustimmung, setZustimmung] = useState("");

  const [template, setTemplate] = useState("");
  const [adminTestPhase, setAdminTestPhase] = useState(7);
  const getAdminConfigure = async () => {
    const response = await PublicService.getAdminConfigure();
    setAdminTestPhase(response?.data?.config?.testPhaseDurationDays);
  };
  React.useEffect(() => {
    getAdminConfigure();
  }, []);

  const [messageTemplates, setMessageTemplates] = useState([]);

  const getTemplates = async () => {
    const { data } = await TemplateService.listTemplates();
    setMessageTemplates(data);
  };
  const getUser = async () => {
    const { data } = await UserService.me();
    setUser(data.user);

    setEmailTemplate(
      `<p>${data?.user?.promotionsLinkVersandNachricht.replace?.(
        "\n",
        "<br/>"
      )}</p>`
    );
    setVersandTemplate(
      `<p>${data?.user?.promotionWhatsApp?.replace?.("\n", "<br/>")}</p>`
    );
    setTemplate(data?.user?.emailTemplateText);
  };
  useEffect(() => {
    getTemplates();
    getUser();
  }, []);

  const createNewTemplate = async () => {
    setFormLoading({ add: true });
    try {
      if (!selected.selected) {
        await TemplateService.createTemplate({
          subject: Zustimmung,
          message: template,
        });
      } else {
        await TemplateService.updateTemplate(selected.id, {
          subject: Zustimmung,
          message: template,
        });
      }

      getTemplates();
    } catch (error) {}
    setTemplate("");
    setZustimmung("");
    setFormLoading({ add: false });
  };

  const sendMessage = async () => {
    setFormLoading({ send: true });
    if (selectedPromoter.length === 0) {
      return message.error("Es ist mindestens ein Promoter erforderlich");
    }
    try {
      await CronService.sendMessageToPromoters({
        subject: Zustimmung,
        message: template,
        promoters: selectedPromoter,
      });
    } catch (error) {}
    setTemplate("");
    setZustimmung("");
    setFormLoading({ send: false });
  };

  const updateVersand = async () => {
    const { data } = await UserService.me();
    await UserService.updateUserInfo({
      ...data.user,
      promotionsLinkVersandNachricht: emailTemplate,
    });
  };
  const whatsAppTemplateUpdate = async () => {
    setFormLoading({ addText: true });
    const { data } = await UserService.me();
    console.log(versandTemplate);
    await UserService.updateUserInfo({
      ...data.user,
      promotionWhatsApp: versandTemplate,
    });
    setFormLoading({ addText: false });
  };

  const isTestPhase = (
    user?.firstAccessAt ? moment(user?.firstAccessAt) : moment()
  )
    .add(adminTestPhase, "days")
    .isAfter(moment());

  if (!isTestPhase && !user?.subscription) return <Skeleton active />;
  return (
    <div className="relative w-full  text-center text-[1.75rem] text-text-prim font-label-medium">
      {!isTestPhase && aboTarif(user) < 2 && (
        <>
          <div
            style={{ zIndex: 99999999999999999 }}
            className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-md"
          />
          <Card
            style={{ zIndex: 99999999999999999 }}
            className="absolute top-[200px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 text-center"
          >
            <h2 className="text-2xl font-bold mb-4">Upgrade benötigt</h2>
            <p className="mb-4">
              Um auf diesen Bereich zugreifen zu können, musst du auf eine
              höhere Abonnementstufe upgraden.
            </p>
            <Link to="/dashboard/upgrade">
              <Button type="primary" className="bg-[#001D47] h-[40px] mr-2">
                Upgrade
              </Button>
            </Link>
          </Card>
        </>
      )}
      <div className="  flex flex-col items-start justify-start gap-[2.19rem]">
        <div className="inline-block w-full font-semibold ">
          Manuelle Nachrichten an Deine Promoter
        </div>

        <div className="w-full  text-left text-[1.25rem]">
          <div className=" top-[0rem] left-[0rem] flex flex-col items-start justify-start">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="w-full rounded-11xl bg-kacheln-bg flex flex-col py-[1.56rem]  box-border items-start justify-start">
                <div className="flex flex-col items-start justify-start w-full">
                  <div className="w-full flex flex-col items-start justify-start gap-[1.88rem]">
                    <div className="flex justify-between w-full ">
                      <div className=" md:flex-nowrap flex-wrap flex flex-row items-start justify-start gap-[1.88rem]">
                        <AsyncSelect
                          className="!border-none react-select bg-transparent !text-xl !outline-none hover:!outline-none !shadow-none"
                          placeholder="Suche nach einem Promoter"
                          loadOptions={inputChanged}
                          cacheOptions
                          defaultOptions
                          options={selectOptions}
                          value={selectedPromoter}
                          onChange={(e) => setSelectedPromoters(e)}
                          isMulti={true}
                          // isClearable
                          isSearchable
                          classNamePrefix={"super-select"}
                          components={{
                            DropdownIndicator: CustomDropdownIndicator,
                            IndicatorSeparator: () => <></>,
                          }}
                        />

                        <Button
                          className="bg-darkslateblue"
                          type="primary "
                          onClick={selectAllPromoters}
                        >
                          Alle Promoter auswählen
                        </Button>
                      </div>
                      <Tooltip title="Sende Emails an Einen, Mehrere oder alle Deine Promoter! Plane Aktionen und speichere Dir wiederkehrende Vorlagen ab.">
                        <Button
                          type="default"
                          className="max-w-[40px] min-w-[40px] h-[40px] p-0  hidden xs:flex justify-center items-center rounded-[10px] text-center border-[1px] border-[#d8d8d8]"
                        >
                          <AiOutlineInfoCircle fontSize={20} />
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="flex justify-between w-full ">
                      <div className="w-full md:flex-nowrap flex-wrap flex  flex-row items-start justify-between gap-[1.88rem]">
                        <div className="w-full  rounded-3xs px-3 flex flex-row  items-center justify-center gap-[0.63rem] border-[1px] border-solid border-grey-accent">
                          <FloatLabel
                            label={"Betreff"}
                            className={"w-full black-search"}
                            keepitfocused
                          >
                            <Input
                              className="!border-none  bg-transparent w-full !outline-none hover:!outline-none !shadow-none"
                              value={Zustimmung}
                              onChange={(e) => setZustimmung(e.target.value)}
                            />
                          </FloatLabel>
                        </div>
                        <div className="flex justify-between gap-3 ">
                          <Dropdown
                            trigger={["click"]}
                            className=" text-[20px] font-semibold w-full"
                            menu={{
                              items: messageTemplates.map((e, i) => ({
                                key: i,
                                label: (
                                  <div
                                    className="flex justify-between"
                                    onClick={() => {
                                      setTemplate(e.message);
                                      setZustimmung(e.subject);
                                      setSelected({
                                        selected: true,
                                        id: e._id,
                                      });
                                    }}
                                  >
                                    <p>{e.subject}</p>
                                    <AiFillDelete
                                      onClick={() =>
                                        PopUpComponent({
                                          onConfirm: async () => {
                                            setFormLoading({ delete: true });
                                            await TemplateService.deleteTemplate(
                                              e._id
                                            );

                                            setSelected({
                                              selected: false,
                                              id: "",
                                            });
                                            setMessageTemplates((prev) =>
                                              prev.filter(
                                                (fe) => fe._id !== e._id
                                              )
                                            );
                                            setTemplate(" ");
                                            setZustimmung("");
                                            setFormLoading({ delete: false });
                                          },

                                          heading:
                                            "Bist Du sicher, dass Du die Vorlage löschen möchtest?",
                                          subheading: "",
                                          confirmButtonText: "Löschen",
                                          cancelButtonText: "Abbrechen",
                                        })
                                      }
                                    />
                                  </div>
                                ),
                              })),
                            }}
                          >
                            <Button
                              className="text-sm font-normal bg-darkslateblue"
                              type="primary "
                            >
                              {" "}
                              Vorlagen
                            </Button>
                          </Dropdown>
                          <Button
                            className="bg-darkslateblue "
                            type="primary "
                            onClick={() => [
                              setTemplate(""),
                              setZustimmung(""),
                              setSelected({ selected: false, id: "" }),
                            ]}
                          >
                            {" "}
                            Zurücksetzen
                          </Button>
                        </div>
                      </div>
                    </div>

                    <ClipboardButtonsBar
                      names={[
                        "Promoter Vorname",
                        "Promoter Nachname",
                        "Partner Vorname",
                        "Partner Nachname",
                        "Promoterseite",
                        "Promolink",
                      ]}
                    />

                    <div className="relative text-[1rem] text-text-sec w-full">
                      {/* <FloatLabel label={"Nachricht"} keepitfocused>
                        <textarea
                          value={template}
                          onChange={(e) => {
                            setTemplate(e.target.value);
                          }}
                          style={{ height: "400px" }}
                          onMouseMove={(e) => {
                            setFirstSelectionEnd(e.target.selectionStart);
                          }}
                          className="  rounded-3xs overflow-hidden flex  flex-row py-[1.13rem] px-[0.94rem] items-center justify-center border-[1px] border-solid border-grey-accent w-full bg-transparent"
                        />
                      </FloatLabel> */}
                      <HTMLTextEditor
                        label={"Nachricht"}
                        value={template}
                        onChange={(e) => {
                          setTemplate(e);
                        }}
                        quillattributes={{
                          className: "w-full",
                        }}
                        variables={[
                          "{Promoter Vorname}",
                          "{Promoter Nachname}",
                          "{Partner Vorname}",
                          "{Partner Nachname}",
                          "{Promoterseite}",
                          "{Promolink}",
                        ]}
                      />
                    </div>
                    <Button
                      type="primary"
                      onClick={() => createNewTemplate()}
                      loading={formLoading.add}
                      className="text-center h-[50px] w-full text-xl font-semibold rounded-[10px]"
                    >
                      {!selected.selected
                        ? "Vorlage hinzufügen"
                        : "Vorlage speichern"}
                    </Button>
                    <Button
                      onClick={sendMessage}
                      loading={formLoading.send}
                      type={
                        user?.subscription?.tier === "Basic" ||
                        !user?.subscription?.tier
                          ? null
                          : "primary"
                      }
                      disabled={
                        user?.subscription?.tier === "Basic" ||
                        !user?.subscription?.tier
                      }
                      className={`text-center h-[50px] w-full text-xl font-semibold rounded-[10px] !text-[#ffffff] ${
                        user?.subscription?.tier === "Basic" ||
                        !user?.subscription?.tier
                          ? "!bg-[#ababab]"
                          : ""
                      }`}
                    >
                      Absenden{" "}
                      {(user?.subscription?.tier === "Basic" ||
                        !user?.subscription?.tier) && (
                        <span className="text-sm">(Upgrade benötigt)</span>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inline-block w-full font-semibold ">
          Automatisierte Nachrichten an Deine Promoter
        </div>
        <div className="w-full flex flex-col items-start justify-start gap-[1.88rem]">
          <div className="w-full text-left">
            <CollapseItem
              notopen
              title={
                <div className="flex items-center gap-3 trkisswitch">
                  <Switch
                    className="scale-125"
                    checked={
                      user?.notificationConfiguration?.neuesGuthaben?.app &&
                      user?.notificationConfiguration?.neuesGuthaben?.email
                    }
                    onChange={(e) =>
                      UserService.updateUserInfo({
                        ...user,
                        notificationConfiguration: {
                          ...user.notificationConfiguration,
                          neuesGuthaben: { app: e, email: e },
                        },
                      }).then((e) => {
                        getUser();
                      })
                    }
                  />
                  <div className="  font-semibold md:text-[20px] primary:text-[28px] text-[16px]">
                    Infomail zu neuem Guthaben
                  </div>
                </div>
              }
              icon={<></>}
              defaultOpen={false}
            ></CollapseItem>
          </div>
          <div className="w-full text-left">
            <CollapseItem
              title={
                <div className="flex items-center gap-3 trkisswitch">
                  <Switch
                    className="scale-125"
                    checked={
                      user?.notificationConfiguration?.promotionsLinkVersand
                        ?.app &&
                      user?.notificationConfiguration?.promotionsLinkVersand
                        ?.email
                    }
                    onChange={(e) =>
                      UserService.updateUserInfo({
                        ...user,
                        notificationConfiguration: {
                          ...user.notificationConfiguration,
                          promotionsLinkVersand: { app: e, email: e },
                        },
                      }).then((e) => {
                        getUser();
                      })
                    }
                  />
                  <div className="  font-semibold md:text-[20px] primary:text-[28px] text-[16px]">
                    Promotionlink Versand
                  </div>
                </div>
              }
              icon={
                <Tooltip title="Sobald Du einen Promolink für einen Promoter erstellst, erhält er diese Nachricht per Email. Du kannst sie nach Deinen Wünschen anpassen.">
                  <Button
                    type="default"
                    className="w-[40px] h-[40px] p-0 hidden xs:flex justify-center items-center rounded-[10px] text-center border-[1px] border-[#d8d8d8]"
                  >
                    <AiOutlineInfoCircle fontSize={20} />
                  </Button>
                </Tooltip>
              }
            >
              <div className="my-2">
                <ClipboardButtonsBar
                  names={[
                    "Promoter Vorname",
                    "Promoter Nachname",
                    "Partner Vorname",
                    "Partner Nachname",
                    "Promoterseite",
                    "Promolink",
                  ]}
                />
              </div>
              <div className="w-full flex  items-start justify-start gap-[1.88rem] py-[1rem]">
                {typeof emailTemplate === "string" && (
                  <HTMLTextEditor
                    label={"Nachricht Email"}
                    value={emailTemplate}
                    onChange={(e) => {
                      setEmailTemplate(e);
                    }}
                    quillattributes={{
                      className: "w-full",
                    }}
                    variables={[
                      "{Promoter Vorname}",
                      "{Promoter Nachname}",
                      "{Partner Vorname}",
                      "{Partner Nachname}",
                      "{Promoterseite}",
                      "{Promolink}",
                    ]}
                  />
                )}
              </div>
              <Button
                type="primary"
                onClick={updateVersand}
                className="w-full h-[50px] text-xl font-semibold rounded-[10px]"
              >
                Speichern
              </Button>
            </CollapseItem>
          </div>
        </div>

        <div className="flex w-full text-left">
          <CollapseItem
            title={
              <div className="flex items-center gap-3 trkisswitch">
                <div className="  font-semibold md:text-[20px] primary:text-[28px]  text-[16px]">
                  Promotionlink Begleittext
                </div>
              </div>
            }
            icon={
              <Tooltip title="Kopierst Du den Link in der Registerkarte des Promoters wird er in diesen Text eingebettet. So kannst ihn direkt per Whatsapp versenden.">
                <Button
                  type="default"
                  className="w-[40px] h-[40px] p-0 hidden xs:flex justify-center items-center rounded-[10px] text-center border-[1px] border-[#d8d8d8] "
                >
                  <AiOutlineInfoCircle fontSize={20} />
                </Button>
              </Tooltip>
            }
          >
            <div className="flex flex-col items-start justify-start gap-[1.88rem] py-4">
              <div className=" text-[1.25rem] font-semibold flex items-center w-full">
                Die Formulierung ist bewusst aus Sicht des Promoters gewählt. So
                kann er Deine Nachricht direkt kopieren und ohne Aufwand X-fach
                weiterleiten.
              </div>
              <ClipboardButtonsBar
                names={[
                  "Promoter Vorname",
                  "Promoter Nachname",
                  "Partner Vorname",
                  "Partner Nachname",
                  "Promoterseite",
                  "Promolink",
                ]}
              />
              <div className="relative w-full text-[1rem]">
                {typeof versandTemplate === "string" && (
                  <HTMLTextEditor
                    label={"Nachricht WhatsApp"}
                    value={versandTemplate}
                    onChange={(e) => {
                      setVersandTemplate(e);
                    }}
                    quillattributes={{
                      className: "w-full",
                    }}
                    options={{ ref: quillRef }}
                    variables={[
                      "{Promoter Vorname}",
                      "{Promoter Nachname}",
                      "{Partner Vorname}",
                      "{Partner Nachname}",
                      "{Promoterseite}",
                      "{Promolink}",
                    ]}
                  />
                )}
              </div>
            </div>
            <div className="flex gap-1">
              <Button
                type="primary"
                onClick={whatsAppTemplateUpdate}
                className="w-full h-[50px] text-xl font-semibold rounded-[10px]"
                loading={formLoading.addText}
              >
                Speichern
              </Button>

              <Tooltip title="Kopiert" color="green" trigger="click">
                <Button
                  type="primary"
                  onClick={() => {
                    copyToClipboard(
                      quillRef?.current?.editingArea?.textContent
                    );
                  }}
                  className="w-[70px] h-[50px] flex justify-center center items-center text-white text-xl font-semibold rounded-[10px]"
                >
                  <AiOutlineCopy />
                </Button>
              </Tooltip>
            </div>
          </CollapseItem>
        </div>
      </div>
    </div>
  );
};

export default Index;
