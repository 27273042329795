export const extractBase64Images = (htmlContent) => {
  const imgTagRegex = /<img[^>]+src="data:image\/[^;]+;base64[^">]+"[^>]*>/g;
  const srcRegex = /src="([^"]+)"/;

  const imgTags = htmlContent.match(imgTagRegex) || [];
  const images = imgTags.map((imgTag) => {
    const srcMatch = imgTag.match(srcRegex);
    return srcMatch ? srcMatch[1] : null;
  });

  return images.filter((src) => src !== null);
};
