import React from "react";
import { Button } from "antd";

const LoadingButton = ({ text, loading, className = "", ...props }) => {
  return (
    <Button
      htmlType="submit"
      type="primary"
      loading={loading}
      className={`w-full rounded-[15px] bg-darkslateblue text-white p-[12px_20px] text-center text-xl font-semibold !border-none !shadow-none !h-auto ${className}`}
    >
      {text}
    </Button>
  );
};

export default LoadingButton;
