import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { MainLogo } from "../../../../assets/Svgs/GUTERTIPP";

const LoadingScreen = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-10 font-bold">
      <div>
        <Spin />
      </div>
      Einen Moment bitte{dots}
    </div>
  );
};

export default LoadingScreen;
