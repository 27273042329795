import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP.js";
import { login } from "../../../redux/auth/actions.js";
import { store } from "../../../redux/store.js";
import AuthService from "../../../service/AuthService.js.js";
import User from "../../../service/UserService.js";
import { storeToken } from "../../../utils/LocalStorageHandler.js";
import FloatingLabel from "../../FloatingLabel/index";
import LoadingButton from "../../LoadingButton/LoadingButton.jsx";

const Index = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [formLoading, setFormLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const [formData, setFormData] = useState({
    resetToken: "",
    newPassword: "",
    checkbox: false,
  });
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    try {
      await AuthService.resetPassword(formData);

      navigate("/login/");
    } catch (error) {
      setFormData({
        resetToken: "",
        newPassword: "",
        checkbox: false,
      });
    }
    setFormLoading(false);
  };
  return (
    <div className="flex items-center justify-center py-[100px]">
      <div className="   w-full  text-center   items-center  px-10 flex flex-col justify-between">
        <MainLogo
          style={{
            transform: width < 500 ? "scale(2)" : "scale(2.5)",
            margin: "2rem auto",
          }}
        />
        <div>
          <h1 className="sm:text-primary text-9xl font-bold">
            Passwort zurücksetzen
          </h1>
        </div>
        <form className="mt-20 w-full grid gap-[30px]" onSubmit={handleSubmit}>
          <FloatingLabel
            label={"Code"}
            value={formData.resetToken}
            unFocusClass="!top-[16px] !text-[16px]"
            className={"sm:my-[12px] m-0"}
          >
            <input
              required
              value={formData.resetToken}
              name="resetToken"
              type="text"
              onChange={handleChange}
              className="border-[1px]  text-[20px] font-semibold border-[#d6d6d6] w-full rounded-[10px] p-3 outline-none bg-transparent  "
            />
          </FloatingLabel>
          <FloatingLabel
            label={"Neues Kennwort"}
            unFocusClass="!top-[16px] !text-[16px]"
            value={formData.newPassword}
            className={"sm:my-[12px] m-0"}
          >
            <input
              required
              value={formData.newPassword}
              onChange={handleChange}
              name="newPassword"
              type="password"
              className="border-[1px]  text-[20px] font-semibold border-[#d6d6d6] w-full rounded-[10px] p-3 outline-none bg-transparent  "
            />
          </FloatingLabel>

          <LoadingButton text={"Abschicken"} loading={formLoading} />
        </form>
      </div>
    </div>
  );
};

export default Index;
