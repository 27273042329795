import { Button, Input, Modal, Segmented, Select, Table } from "antd";
import Column from "antd/es/table/Column";
import moment from "moment";
import { async } from "q";
import React, { useEffect, useState } from "react";
import AdminService from "../../../service/AdminService";

export const dateOption = {
  day: "numeric",
  month: "short",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

const Index = () => {
  const [tableData, setTableData] = useState([]);
  const [beforeDay, setBeforeDay] = useState(0);
  const [ticketValue, setTicketValue] = useState("");
  const [searchData, setSearchData] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (data) => {
    setMessage(data);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const ticketArray = [
    { value: "", label: "Alle Tickets" },
    { value: false, label: "Offen" },
    { value: true, label: "Geschlossen" },
  ];

  // let debounceTimer = null;

  const modifyData = (item, idx) => {
    item.id = idx + 1;
    if (item && item.user && item.user.length) {
      item.name = item.user[0].firstname + " " + item.user[0].lastname;
      item.status = item.closed ? "Geschlossen" : "Offen";
    }
  };

  const searchSupportTickets = async () => {
    const { data } = await AdminService.searchSupportTickets({
      beforeDay,
      ticketValue,
      searchData,
    });
    console.log(data);
    data.forEach((item, idx) => {
      modifyData(item, idx);
    });
    setTableData(data);
  };

  useEffect(() => {
    return () => {
      console.log("cleared");
      clearTimeout(debounceTimer);
    };
  }, []);

  useEffect(() => {
    searchSupportTickets();
  }, [beforeDay, ticketValue]);

  useEffect(() => {}, [tableData]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchData(value);
    clearTimeout(debounceTimer);
    const tmpTimer = setTimeout(() => {
      searchSupportTickets();
      console.log("timer occured");
    }, 2000);
    setDebounceTimer(tmpTimer);
  };

  return (
    <div>
      <div className="overflow-auto flex items-center">
        <Segmented
          className="mx-0 xs:mx-5 my-6  font-medium text-[14px] rounded-[10px]"
          defaultValue={0}
          options={[
            {
              label: (
                <div className=" px-[10px] rounded-me rounded-[6px] py-[7px]">
                  Alle
                </div>
              ),
              value: 0,
            },
            {
              label: (
                <div className="px-[10px] rounded-me rounded-[6px] py-[7px]">
                  Älter als 7 Tage
                </div>
              ),
              value: 7,
            },
          ]}
          onChange={(value) => {
            setBeforeDay(value);
          }}
        />
        <Select
          className=""
          value={{
            value: ticketValue,
            label: ticketArray.find((item) => item.value === ticketValue).label,
          }}
          options={[
            { value: "", label: "Alle Tickets" },
            { value: false, label: "Offen" },
            { value: true, label: "Geschlossen" },
          ]}
          onChange={(value) => {
            setTicketValue(value);
          }}
        />
        <Input
          placeholder={"Suchen"}
          className="w-[300px] min-w-[100px] mx-[20px] "
          value={searchData}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <div className="w-full bg-[#fafafa] rounded-11xl support-table overflow-auto">
          <Table
            bordered={false}
            dataSource={tableData}
            pagination={false}
            rowKey={"uid"}
          >
            <Column
              title="Ticket ID"
              dataIndex={"ticketid"}
              className="font-bold text-[18px]"
            ></Column>
            <Column
              title="Name"
              dataIndex={"name"}
              className="font-bold text-[18px]"
            ></Column>
            <Column
              title="Email"
              dataIndex={"email"}
              className="font-bold text-[18px]"
              render={(_) => <a href={`mailto:${_}`}>{_}</a>}
            ></Column>
            <Column
              title="Thema"
              dataIndex={"theme"}
              className="font-bold text-[18px]"
            ></Column>
            <Column
              title="Status"
              dataIndex={"closed"}
              className="font-bold text-[18px]"
              render={(_) => (
                <div style={{ color: _ ? "#E81900" : "#0084F7" }}>
                  {_ ? "Geschlossen" : "Offen"}
                </div>
              )}
            ></Column>
            <Column
              title="Erstellt am"
              dataIndex={"createdAt"}
              className="font-bold text-[18px]"
              render={(_) => <div>{moment(_).format("D.M.YYYY HH:mm")}</div>}
            ></Column>
            <Column
              title="Aktion"
              className="font-bold text-[18px]"
              dataIndex={"actionButton"}
              render={(text, record) => (
                <div className="flex">
                  <Button
                    className="mr-[4px]"
                    onClick={async () => {
                      AdminService.changeSupportTicketStatus({
                        ticket_id: record._id,
                        value: !record.closed,
                      })
                        .then(({ data }) => {
                          console.log(data);
                          const index = tableData.findIndex(
                            (item) => item._id === record._id
                          );
                          if (index >= 0)
                            tableData[index].closed = !tableData[index].closed;
                          tableData[index] = {
                            ...tableData[index],
                            closed: data.closed,
                            status: data.closed ? "Geschlossen" : "Offen",
                          };
                          // modifyData(data, index)
                          console.log(tableData);
                          setTableData([
                            ...tableData.slice(0, index),
                            { ...tableData[index] },
                            ...tableData.slice(index + 1),
                          ]);
                        })
                        .catch((err) => {});
                    }}
                  >
                    {!record.closed ? "Schließen" : "Öffnen"}
                  </Button>
                  <Button
                    onClick={() => {
                      showModal(record.message);
                    }}
                  >
                    Nachricht sehen
                  </Button>
                </div>
              )}
            ></Column>
          </Table>
        </div>
      </div>
      <Modal
        title="Message"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>{message}</p>
      </Modal>
    </div>
  );
};

export default Index;
