import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex  justify-left sm:justify-between font-semibold text-[12px] sm:text-[16px]  gap-5 sm:gap-20 my-5 w-full px-5">
      <h1 className="flex-1 whitespace-wrap">© Copyright EinGuterTipp</h1>
      <div className="flex flex-1 items-center gap-2 sm:gap-10 justify-end">
        <Link to="/impressum">
          <span>Impressum</span>
        </Link>
        <Link to={"/datenschutz"}>Datenschutz</Link>
        <Link to={"/agb"}>AGB</Link>
      </div>
    </div>
  );
};

export default Footer;
