import { Button } from "antd";
import React from "react";
import { confirmAlert } from "react-confirm-alert";

import { MdOutlineClose } from "react-icons/md";
const PopUpComponent = ({
  onConfirm = () => {},
  onCancel = () => {},
  heading,
  subheading,
  confirmButtonText,
  cancelButtonText = "Abbrechen",
  hideButtons = false,
  checkbox,
}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          style={{ zIndex: 900000000000000 }}
          className={`white-text-div-folder bg-white rounded-2xl p-[20px_30px] flex flex-col justify-center items-center gap-5 md:w-[600px]  w-[90%] m-auto relative`}
        >
          <div
            className="absolute w-[48px] h-[48px] rounded-[10px] right-[-12px] top-[-12px] bg-white flex justify-center items-center border border-[#d6d6d6] cursor-pointer"
            onClick={onClose}
          >
            <MdOutlineClose fontSize={30} />
          </div>
          <div>
            <h5>{heading}</h5>
            <p className="my-1">{subheading}</p>
            {checkbox && checkbox}
          </div>
          {!hideButtons && (
            <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
              <Button
                className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
                onClick={async () => {
                  await onConfirm();
                  onClose();
                }}
              >
                {confirmButtonText}
              </Button>
              <Button
                type="primary"
                className="h-[46px] w-[250px] rounded-[10px]"
                onClick={async (e) => {
                  onClose(e);
                  await onCancel();
                }}
              >
                {cancelButtonText}
              </Button>
            </div>
          )}
        </div>
      );
    },
    buttons: [
      {
        label: "Yes",
        onClick: () => alert("Click Yes"),
      },
      {
        label: "No",
        onClick: () => alert("Click No"),
      },
    ],
  });
};
export { PopUpComponent };
