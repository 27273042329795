import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class PromotionsLinkService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/promotionslink`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  list(params) {
    return this.api.get("/list", { params });
  }

  create(promotionslinkData) {
    return this.api.post("/create", {
      ...promotionslinkData,
      baseUrl: window.location.origin,
    });
  }

  update(id, promotionslinkData) {
    return this.api.put(`/update?id=${id}`, promotionslinkData);
  }
  delete(id) {
    return this.api.delete(`/delete?id=${id}`);
  }
}

export default new PromotionsLinkService();
