import EGTStats from "./EGTStats";
import LandingPageTable from "./LandingPageTable";

const Index = () => {
  return (
    <div className="flex flex-col gap-8 overflow-x-hidden items-center justify-center w-full">
      <div className="self-start">
        <h1 className="text-4xl">EGT Landing Page</h1>
      </div>
      <EGTStats />
      <div className="self-start">
        <h1 className="text-4xl">User Statistiken</h1>
      </div>
      <LandingPageTable />
    </div>
  );
};

export default Index;
