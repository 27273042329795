export const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
  >
    <path
      d="M33 3.11816C32.4617 3.11816 31.9224 3.31246 31.5357 3.70227L24.317 10.9734C23.5146 11.7819 23.5205 13.0876 24.329 13.8899C25.1365 14.6922 26.4443 14.6873 27.2455 13.8798L30.9375 10.1617V33.0526C30.9375 34.1911 31.8615 35.1151 33 35.1151C34.1385 35.1151 35.0625 34.1911 35.0625 33.0526V10.1617L38.7545 13.8798C39.1577 14.2861 39.6877 14.4901 40.2188 14.4901C40.7447 14.4901 41.2698 14.289 41.671 13.8899C42.4795 13.0865 42.4854 11.7819 41.683 10.9734L34.4643 3.70227C34.0776 3.31246 33.5383 3.11816 33 3.11816ZM15.4688 17.5315C12.0563 17.5315 9.28125 20.3066 9.28125 23.719V46.4065C9.28125 49.8189 12.0563 52.594 15.4688 52.594H50.5312C53.9437 52.594 56.7188 49.8189 56.7188 46.4065V23.719C56.7188 20.3066 53.9437 17.5315 50.5312 17.5315H38.1562V21.6565H50.5312C51.6687 21.6565 52.5938 22.5815 52.5938 23.719V46.4065C52.5938 47.544 51.6687 48.469 50.5312 48.469H15.4688C14.3313 48.469 13.4062 47.544 13.4062 46.4065V23.719C13.4062 22.5815 14.3313 21.6565 15.4688 21.6565H27.8438V17.5315H15.4688Z"
      fill="currentColor"
    />
  </svg>
);
