import LandingPageService from "../service/LandingPageService";
import UploadToCloudService from "../service/UploadToCloudService";

export const PROTECTED_URLS = [
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1731606489/zlymhflpolovpke4jq7v.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1731609988/ofpq2fy83uixt9goicwf.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1731609988/f0uuwcmdwbaujdlrktam.png",
];

export const handleUnusedImages = async (
  landingPageId,
  updatedEditorContents
) => {
  let currentImageUrls = [];
  updatedEditorContents.forEach((content) => {
    const urls = extractImageUrls(content);
    currentImageUrls.push(...urls);
  });

  const { data } = await LandingPageService.detail(landingPageId);
  const savedImageUrls = data.imageList || [];

  const unusedImages = savedImageUrls.filter(
    (url) => !currentImageUrls.includes(url) && !PROTECTED_URLS.includes(url)
  );

  await deleteUnusedImages(unusedImages);

  return currentImageUrls;
};

const extractImageUrls = (htmlContent) => {
  const imgTagRegex = /<img[^>]+src="([^">]+)"[^>]*>/g;
  const imageUrls = [];
  let match;

  while ((match = imgTagRegex.exec(htmlContent))) {
    imageUrls.push(match[1]);
  }

  return imageUrls;
};

const deleteUnusedImages = async (unusedImages) => {
  for (const imageUrl of unusedImages) {
    const publicId = getPublicIdFromUrl(imageUrl);
    await UploadToCloudService.deleteBase64Image(publicId);
  }
};

const getPublicIdFromUrl = (url) => {
  const urlParts = url.split("/");
  const fileName = urlParts.pop();
  const [publicId] = fileName.split(".");
  return publicId;
};
