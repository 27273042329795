import { Switch } from "antd";
import React from "react";

const SwitchTitle = ({ checked, onChange, children, deactivated }) => {
  return (
    <div className="flex flex-wrap-reverse items-center justify-between gap-5 trkisswitch sm:flex-nowrap">
      <div className="">
        <Switch checked={checked} onChange={onChange} disabled={deactivated} />
      </div>

      <div className="flex  flex-1 justify-start xl:w-[75%] w-full items-center xl:flex-nowrap flex-wrap xl:gap-0 gap-2">
        <div className="flex text-base font-semibold text-left lg:text-9xl sm:text-xl gap-[15px]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SwitchTitle;
