import React from "react";

import { Button, Skeleton } from "antd";
import classNames from "classnames";
import moment from "moment/moment";
import InfiniteScroll from "react-infinite-scroll-component";
import NotificationService from "../../service/NotificationService";
import {
  GreenTicketFill,
  NewOrangeFill,
  RejectedIcon,
} from "../AdminDashboard/Home/Svgs";
import {
  BlueDownArrow,
  Folder,
  GoldEuro,
  Head,
  PeopleBlue,
  StopWatch,
  Warning,
} from "../Dashboard/Home/Svgs";

export const ICONSELECTOR = ({ type }) => {
  return (
    {
      neuerKunde: PeopleBlue(),
      emailZustimmungFallig: Head(),
      linkNichtGeklickt: (
        <img
          src="/notificationicons/icons8-mausklick-links.svg"
          alt=""
          srcSet=""
        />
      ),
      linkAngeklickt: (
        <img src="/notificationicons/icons8-mausklick.svg" alt="" srcSet="" />
      ),
      neuerPromoterWartetAufLink: (
        <img src="/notificationicons/icons8-link-2 1.svg" alt="" srcSet="" />
      ),
      empfehlungTerminiert: StopWatch(),
      empfehlungOhneStatusAnderung: StopWatch(),

      canceled_subscription: RejectedIcon(),
      new_support_ticket: GreenTicketFill(),
      pramien: GoldEuro(),
      empfehlungUnbearbeitet: Folder(),
      "promoter-neues-guthaben": BlueDownArrow(),
      "promoter-neuerlink": BlueDownArrow(),
      new_registration: NewOrangeFill(),
      "promoter-emailbestatigt": (
        <img src="/notificationicons/icons8-arbeitsablauf 2.svg" alt="" />
      ),
      warning: Warning(),
      neueEmpfehlung: (
        <img
          src="/images/morflaxstudio81@2x.png"
          className="scale-125"
          width={26}
          height={26}
          alt=""
        />
      ),
      // "promoter-emailbestatigung": BlueDownArrow(),
    }[type] ?? (
      <img
        src="/images/morflaxstudio81@2x.png"
        className="scale-125"
        width={26}
        height={26}
        alt=""
      />
    )
  );
};
const Notifications = ({
  data,
  notificationInboundPage,
  totalPages,
  read,
  setNotificationInboundPage,
  getNotifications,
  isInbound,
}) => {
  return (
    <InfiniteScroll
      dataLength={data?.length || 0} //This is important field to render the next data
      style={{ overflowX: "hidden" }}
      next={() => {
        setNotificationInboundPage((prev) => {
          return prev + 1;
        });
      }}
      hasMore={notificationInboundPage !== totalPages}
      loader={<Skeleton active />}
    >
      <Button
        type="primary"
        onClick={async () => {
          await NotificationService.markAllAsRead({ isInbound });
          await getNotifications();
        }}
      >
        Alle als gelesen markieren
      </Button>
      <div className="flex flex-col gap-6 py-10 px-2">
        {data?.map((e, i) => {
          return (
            <div
              key={i}
              id={`notification-item-${i}`}
              onClick={(x) => {
                x.preventDefault();
                x.stopPropagation();
                read(e);
              }}
              className={`relative rounded-[20px] p-[10px] gap-2 bg-[#FAFAFA] cursor-pointer flex ${
                !e.hasRead && " border-[1px] border-[#868686]"
              }`}
            >
              <div className="flex items-center justify-center">
                <ICONSELECTOR type={e.type} />
              </div>
              <div>
                <p
                  className={classNames("sm:text-base text-xs ", {
                    "w-[95%]": !e?.hasRead,
                  })}
                >
                  {e.text}
                </p>
                <p className="text-[#868686] sm:text-base text-xs">
                  {moment(e.createdAt).fromNow()}
                </p>
              </div>
              {!e.hasRead && (
                <img
                  src="/notificationicons/icons-8-neu-34.svg"
                  className="absolute unreadNewIcon sm:top-[-20px] top-[-15px] sm:right-[-20px] z-50 right-[-10px] sm:w-[80px] w-[50px] "
                  alt="img"
                />
              )}
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
};

export default Notifications;
