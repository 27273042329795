const egtID = process.env.REACT_APP_GOOGLE_ANALYTICS_LP;
const userID = process.env.REACT_APP_GOOGLE_ANALYTICS_USERLP;

export const sendEventEGT = ({ category, action, label, value }) => {
  if (typeof window.gtag === "function") {
    window.gtag("event", action, {
      send_to: egtID,
      event_category: category || "default_category",
      event_label: label || "default_label",
      value: value || 0,
    });
  } else {
    console.warn("gtag function not available");
  }
};

export const sendEventUser = ({
  category,
  action,
  label,
  value,
  landingPageId,
}) => {
  if (typeof window.gtag === "function") {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
      send_to: userID,
      landingPageId: landingPageId,
    });
  } else {
    console.warn("gtag function not available");
  }
};

export const handlePlay = (
  videoRef,
  setWatchtime,
  firstPlay,
  setFirstPlay,
  landingPageId,
  label,
  user,
  egt
) => {
  if (firstPlay) {
    if (user && !egt) {
      sendEventUser({
        category: "video",
        action: `play_${label}`,
        label: label,
        value: 1,
        landingPageId: landingPageId,
      });
    } else if (!user && egt) {
      sendEventEGT({
        category: "video",
        action: "play",
        label: label,
        value: 1,
      });
    }
    setFirstPlay(false);
  }
  setWatchtime(videoRef.current.currentTime);
};

export const handlePause = (
  videoRef,
  watchTime,
  setWatchTime,
  landingPageId,
  label,
  user,
  egt
) => {
  const currentTime = videoRef.current.currentTime;
  const timeSpent = currentTime - watchTime;
  const totalDuration = videoRef.current.duration;
  const percentageWatched = (timeSpent / totalDuration) * 100;

  setWatchTime((prevTime) => prevTime + timeSpent);
  if (user && !egt) {
    sendEventUser({
      category: "video",
      action: `watchtime_${label}`,
      label: label,
      value: percentageWatched,
      landingPageId: landingPageId,
    });
  } else if (!user && egt) {
    sendEventEGT({
      category: "video",
      action: `watchtime_${label}`,
      label: label,
      value: percentageWatched,
    });
  }
};

export const handleEnded = (
  videoRef,
  watchTime,
  setWatchTime,
  landingPageId,
  label,
  user,
  egt
) => {
  const currentTime = videoRef.current.currentTime;
  const timeSpent = currentTime - watchTime;
  const totalDuration = videoRef.current.duration;
  const percentageWatched = (timeSpent / totalDuration) * 100;
  setWatchTime((prevTime) => prevTime + timeSpent);
  if (user && !egt) {
    sendEventUser({
      category: "video",
      action: `watchtime_${label}`,
      label: label,
      value: percentageWatched,
      landingPageId: landingPageId,
    });
  } else if (!user && egt) {
    sendEventEGT({
      category: "video",
      action: `watchtime_${label}`,
      label: label,
      value: percentageWatched,
    });
  }
};
