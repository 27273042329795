import React from "react";
import { sendEventUser } from "../../../../../utils/analytics";
import { imageBackground } from "../../../../../utils/setImageBackground";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";
import BlackArrow from "./BlackArrow";

const HeroStromGas = ({ landingPageData }) => {
  const {
    transformed_accompanyingTextOne,
    transformed_accompanyingTextTwo,
    transformed_accompanyingTextThree,
    transformed_introductoryText,
    transformed_whatsappText,
    whatsappNumber,
    background_image,
    logoSrc,
    primary_image,
    whatsappButtonTextOne,
    persentationVideo,
    thumbnail,
    activateWhatsApp,
    activateBackgroundIntroductoryText,
    activateBackgroundAccompanyingTextTwo,
    activateBackgroundAccompanyingTextThree,
    promoter,
    introductoryText,
  } = landingPageData;

  const promoterReplacements = promoter
    ? {
        "{Promoter Vorname}": `<span>${promoter.firstname}</span>`,
        "{Promoter Nachname}": `<span>${promoter.lastname}</span>`,
      }
    : {
        "{Promoter Vorname}": `<span>{Promoter Vorname}</span>`,
        "{Promoter Nachname}": `<span>{Promoter Nachname}</span>`,
      };

  const replacedIntroText = introductoryText
    .replace("{Promoter Vorname}", promoterReplacements["{Promoter Vorname}"])
    .replace(
      "{Promoter Nachname}",
      promoterReplacements["{Promoter Nachname}"]
    );

  const withBackground = (set) => (set ? "bg-[#000000] p-1 bg-opacity-50" : "");

  return (
    <div className="relative w-full p-5 pt-16 secondary:p-40 secondary:pb-20">
      {logoSrc && (
        <img
          src={logoSrc}
          alt="logo"
          className="absolute h-[60px] secondary:h-[120px] left-1/2 translate-x-[-50%] top-2"
        />
      )}

      <div
        style={{ ...imageBackground(background_image) }}
        className="absolute top-0 bottom-0 left-0 right-0 z-[-2]"
      />

      <h1
        className={`relative whitespace-pre-line m-auto text-2xl font-semibold text-center text-white mb-14 primary:w-3/4 secondary:text-4xl max-w-[1350px] ${withBackground(
          transformed_introductoryText && activateBackgroundIntroductoryText
        )}`}
        dangerouslySetInnerHTML={{ __html: replacedIntroText }}
      ></h1>

      <BlackArrow
        show={
          transformed_introductoryText && activateBackgroundIntroductoryText
        }
        type={"stormgas"}
      />

      {/* Desktop View */}
      <div className="hidden pt-8 text-white secondary:flex gap-14 max-w-[1350px] m-auto !mb-40">
        <div className="flex flex-col justify-center flex-1 gap-10">
          <div
            className={`text-xl font-semibold secondary:text-2xl whitespace-pre-line ${withBackground(
              transformed_accompanyingTextTwo &&
                activateBackgroundAccompanyingTextTwo
            )}`}
            dangerouslySetInnerHTML={{
              __html: transformed_accompanyingTextOne,
            }}
          ></div>
          <div
            className={`text-xl font-semibold secondary:text-2xl whitespace-pre-line ${withBackground(
              transformed_accompanyingTextThree &&
                activateBackgroundAccompanyingTextThree
            )}`}
            dangerouslySetInnerHTML={{
              __html: transformed_accompanyingTextThree,
            }}
          ></div>
        </div>

        <div
          className="relative h-[500px] w-[400px] rounded-none border-2 border-white"
          style={{ ...imageBackground(primary_image) }}
        >
          <Bubble
            className="absolute bottom-[-25%] left-[-40%] w-[80%] whitespace-pre-line"
            text={transformed_accompanyingTextOne}
          />
        </div>
      </div>

      {/* Video Section */}
      <Video
        url={persentationVideo}
        thumbnail={thumbnail}
        className="secondary:hidden"
        textWhite
        rounded={false}
        type="stormgas"
        heading={landingPageData.transformed_introVideoHeader}
        textBackground={landingPageData.activateTextWithBackground}
      />

      <WhatsappButton
        number={whatsappNumber}
        whatsappText={transformed_whatsappText}
        className="pt-14 secondary:hidden"
        text={whatsappButtonTextOne}
        activateWhatsApp={activateWhatsApp}
        type="stormgas"
        sendGoogleAnalytics={() =>
          sendEventUser({
            category: "button",
            action: "click_wa1",
            label: "wa1",
            value: 1,
            landingPageId: landingPageData._id,
          })
        }
      />

      {/* Scroll Button */}
      <a href="#middle" className="block mt-auto pt-14 secondary:p-0">
        <img
          src="/images/icons-8-doppelt-runter-21.png"
          alt=""
          className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto"
        />
      </a>
    </div>
  );
};

const Bubble = ({ text, className }) => (
  <div
    className={`${className} p-10 text-xl font-semibold text-[#000000] bg-white rounded-full`}
    dangerouslySetInnerHTML={{ __html: text }}
  ></div>
);

export default HeroStromGas;
