import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { PopUpComponent } from "../../../ConfirmPopup";
import Inputs from "../../shared/Input";
import Box from "../Layout/Box";
import Button from "../shared/Button";
import CollapseItem from "../shared/CollapseItem";
import MediaUpload from "../shared/MediaUpload";

const Questions = forwardRef(({ questionData }, ref) => {
  const MAX = 8;
  const imageRefs = useRef([]);
  const { id: ladingpageId } = useParams();
  const [questions, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET": {
        return action.payload.questions;
      }
      case "SET_QUESTION_TEXT": {
        return state.map((q, index) => {
          if (index === action.payload.questionId) {
            return { ...q, questionText: action.payload.questionText };
          } else {
            return q;
          }
        });
      }
      case "SET_ANSWER": {
        return state.map((q, index) => {
          if (index === action.payload.questionId) {
            const updatedAnswerOptions = q.answerOptions.map(
              (option, optionIndex) => {
                if (optionIndex === action.payload.answerId) {
                  return { ...option, text: action.payload.value };
                } else {
                  return option;
                }
              }
            );

            return { ...q, answerOptions: updatedAnswerOptions };
          } else {
            return q;
          }
        });
      }
      case "SET_NICE_TO_HAVE": {
        return state.map((q, index) => {
          if (index === action.payload.questionId) {
            const updatedAnswerOptions = q.answerOptions.map(
              (option, optionIndex) => {
                if (optionIndex === action.payload.answerId) {
                  return {
                    ...option,
                    consideredNiceToHaveAnswer: action.payload.value,
                  };
                } else {
                  return option;
                }
              }
            );

            return { ...q, answerOptions: updatedAnswerOptions };
          } else {
            return q;
          }
        });
      }
      case "SET_DELETE_ANSWER": {
        return state.map((q, index) => {
          if (index === action.payload.questionId) {
            const updatedAnswerOptions = q.answerOptions.filter(
              (_, optionIndex) => optionIndex !== action.payload.answerId
            );

            return { ...q, answerOptions: updatedAnswerOptions };
          } else {
            return q;
          }
        });
      }
      case "SET_IMAGE": {
        return state.map((q, index) => {
          if (index === action.payload.questionId) {
            return { ...q, backgroundImage: action.payload.backgroundImage };
          } else {
            return q;
          }
        });
      }
      case "ADD_ANSWER": {
        return state.map((q, index) => {
          if (index === action.payload.questionId) {
            const newAnswerOptions = [
              ...q.answerOptions,
              { text: "", consideredNiceToHaveAnswer: true },
            ];
            return { ...q, answerOptions: newAnswerOptions };
          } else {
            return q;
          }
        });
      }
      case "DELETE_QUESTION": {
        const questionIndexToDelete = action.payload.questionId;
        return state.filter((_, index) => index !== questionIndexToDelete);
      }
      case "ADD_QUESTION": {
        const newQuestion = {
          landingpage_id: ladingpageId,
          questionText: "Neue Frage hier eingeben",
          backgroundImage: "/landingPageBgs/finance.svg",
          answerOptions: [
            { text: "Positive Antwort", consideredNiceToHaveAnswer: true },
            { text: "Negative Antwort", consideredNiceToHaveAnswer: false },
          ],
        };

        return [...state, newQuestion];
      }

      default:
        return state;
    }
  }, questionData);

  // Update imageRefs when questions change
  useEffect(() => {
    imageRefs.current = Array.from(
      { length: questions.length },
      (_, index) => imageRefs.current[index]
    );
  }, [questions]);

  useImperativeHandle(ref, () => ({
    async save() {
      console.log("Questions.save()");

      // Saving images
      const questionsWithNewImages = await Promise.all(
        imageRefs.current.map(async (ref, index) => {
          const newImage = await ref?.saveLocalToCloud?.();
          return {
            ...questions[index],
            backgroundImage: newImage.success
              ? newImage.secure_url
              : newImage.unchanged_secure_url,
          };
        })
      );

      // Aktualisiere die Landing Page mit den neuen Bild-URLs
      return {
        questionData: questionsWithNewImages,
      };
    },
  }));

  return (
    <>
      {questions.map((question, id) => (
        <Question
          imageUploadId={"landing-page-config-change-video-" + id + 1}
          key={id}
          title={`Frage ${id + 1}`}
          question={question}
          onChangeQuestionText={(e) => {
            dispatch({
              type: "SET_QUESTION_TEXT",
              payload: {
                questionId: id,
                questionText: e.target.value,
              },
            });
          }}
          onChangeAnswer={(value, answerId) => {
            dispatch({
              type: "SET_ANSWER",
              payload: {
                value: value,
                answerId: answerId,
                questionId: id,
              },
            });
          }}
          onChangeNiceToHave={(value, answerId) => {
            dispatch({
              type: "SET_NICE_TO_HAVE",
              payload: {
                value: value,
                answerId: answerId,
                questionId: id,
              },
            });
          }}
          onDeleteAnswer={(answerId) => {
            dispatch({
              type: "SET_DELETE_ANSWER",
              payload: {
                answerId: answerId,
                questionId: id,
              },
            });
          }}
          addAnswer={() => {
            dispatch({
              type: "ADD_ANSWER",
              payload: {
                questionId: id,
              },
            });
          }}
          deleteQuestion={() => {
            PopUpComponent({
              onConfirm: () => {
                dispatch({
                  type: "DELETE_QUESTION",
                  payload: {
                    questionId: id,
                  },
                });
              },
              heading: "Bist Du sicher, dass Du diese Frage löschen möchtest?",
              confirmButtonText: "Löschen",
            });
          }}
          imageRef={(ref) => {
            imageRefs.current[id] = ref;
          }}
          backgroundImage={question.backgroundImage}
          setBackgroundImage={(newBackgroundImage) =>
            dispatch({
              type: "SET_IMAGE",
              payload: {
                questionId: id,
                backgroundImage: newBackgroundImage,
              },
            })
          }
        />
      ))}
      <Button
        onClick={() => dispatch({ type: "ADD_QUESTION" })}
        text={"+ Frage Hinzufügen"}
        light
        display={questions.length < MAX}
      />
    </>
  );
});

const Question = ({
  title,
  question,
  onChangeQuestionText,
  imageUploadId,
  onChangeAnswer,
  onChangeNiceToHave,
  onDeleteAnswer,
  addAnswer,
  deleteQuestion,
  imageRef,
  backgroundImage,
  setBackgroundImage,
}) => {
  const { questionText, answerOptions } = question;

  return (
    <Box>
      <CollapseItem title={title}>
        {/** Frage und Image upload  */}
        <div className="grid gap-4 my-4 primary:grid-cols-2">
          <Inputs
            label="Frage"
            value={questionText}
            onChange={onChangeQuestionText}
            type="textarea"
          />

          <MediaUpload
            url={backgroundImage}
            setUrl={setBackgroundImage}
            id={imageUploadId}
            textSrcThere="Hintergrundbild ändern"
            textSrcNotThere="Hintergrundbild hochladen"
            ref={imageRef}
          />
        </div>

        {/** All questions */}
        <div className="grid gap-4 my-4 primary:grid-cols-2">
          {answerOptions.map(({ consideredNiceToHaveAnswer, text }, id) => (
            <Inputs
              key={id}
              consideredNiceToHaveAnswer={consideredNiceToHaveAnswer}
              value={text}
              label={
                consideredNiceToHaveAnswer
                  ? "Antwortmöglichkeit"
                  : "Antwortmöglichkeit (ablehnend)"
              }
              onChange={(e) => onChangeAnswer(e.target.value, id)}
              onChangeNiceToHave={(value) => onChangeNiceToHave(value, id)}
              withDropdown={true}
              onDeleteAnswer={() => onDeleteAnswer(id)}
            />
          ))}
        </div>

        {/**Buttons */}
        <Button
          onClick={() => addAnswer()}
          text={"+ Weitere Antwort hinzufügen"}
          className={"mb-4"}
          light
        />
        <Button onClick={() => deleteQuestion()} text={"Frage Löschen"} />
      </CollapseItem>
    </Box>
  );
};

export default Questions;
