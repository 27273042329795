import React from "react";
import { imageBackground } from "../../../../utils/setImageBackground";
import Video from "../video/VideoOld";
import WhatsappButton from "../../../_NEW/LandingPage/shared/WhatsappButton";
import BlackArrow from "../../../_NEW/LandingPage/sections/hero/BlackArrow";
import { sendEventUser } from "../../../../utils/analytics";

const HeroStromGas = ({ landingPageData }) => {
  const withBackground = (set) => {
    return set ? "bg-black p-1 bg-opacity-50" : "";
  };

  const {
    transformed_accompanyingTextOne,
    transformed_accompanyingTextTwo,
    transformed_accompanyingTextThree,
    transformed_introductoryText,
    transformed_whatsappText,
    whatsappNumber,
    background_image,
    logo,
    primary_image,
    whatsappButtonTextOne,
    persentationVideo,
    thumbnail,
    activateWhatsApp,
    activateBackgroundIntroductoryText,
    activateBackgroundAccompanyingTextTwo,
    activateBackgroundAccompanyingTextThree,
    logoSrc,
  } = landingPageData;
  return (
    <div className="relative w-full p-5 pt-16 secondary:p-40 secondary:pb-20">
      {logoSrc && (
        <img
          src={logoSrc}
          alt="logo"
          className="absolute h-12 secondary:h-14 left-1/2 translate-x-[-50%] top-2"
        />
      )}

      <div
        style={{
          ...imageBackground(background_image),
        }}
        className="absolute top-0 bottom-0 left-0 right-0 z-[-2]"
      />

      <h1
        className={`relative whitespace-pre-line m-auto text-2xl font-semibold text-center text-white mb-14 primary:w-3/4 secondary:text-4xl max-w-[1350px] ${withBackground(
          transformed_introductoryText != "" &&
            activateBackgroundIntroductoryText
        )}`}
      >
        {transformed_introductoryText}
        <BlackArrow
          show={
            transformed_introductoryText != "" &&
            activateBackgroundIntroductoryText
          }
          type={"stormgas"}
        />
      </h1>

      {/**Desktop */}
      <div className="hidden pt-8 text-white secondary:flex gap-14 max-w-[1350px] m-auto !mb-40">
        <div className="flex flex-col justify-center flex-1 gap-10">
          <div
            className={`text-xl font-semibold secondary:text-2xl whitespace-pre-line ${withBackground(
              transformed_accompanyingTextTwo != "" &&
                activateBackgroundAccompanyingTextTwo
            )}`}
          >
            {transformed_accompanyingTextTwo}
          </div>
          <div
            className={`text-xl font-semibold secondary:text-2xl whitespace-pre-line ${withBackground(
              transformed_accompanyingTextThree != "" &&
                activateBackgroundAccompanyingTextThree
            )}`}
          >
            {transformed_accompanyingTextThree}
          </div>
        </div>

        <div
          className="relative h-[500px] w-[400px] rounded-none border-2 border-white"
          style={{
            ...imageBackground(primary_image),
          }}
        >
          <Bubble
            className="absolute bottom-[-25%] left-[-40%] w-[80%] whitespace-pre-line"
            text={transformed_accompanyingTextOne}
          />
        </div>
      </div>

      {/** Video */}
      <Video
        url={persentationVideo}
        thumbnail={thumbnail}
        className="secondary:hidden"
        textWhite
        rounded={false}
        type="stormgas"
        heading={landingPageData.transformed_introVideoHeader}
        textBackground={landingPageData.activateTextWithBackground}
      />

      <WhatsappButton
        number={whatsappNumber}
        whatsappText={transformed_whatsappText}
        className="pt-14 secondary:hidden"
        text={whatsappButtonTextOne}
        activateWhatsApp={activateWhatsApp}
        type="stormgas"
        sendGoogleAnalytics={() =>
          sendEventUser({
            category: "button",
            action: `click_wa1`,
            label: "wa1",
            value: 1,
            landingPageId: landingPageData._id,
          })
        }
      />

      {/** Button */}
      <a href="#middle" className="block mt-auto pt-14 secondary:p-0">
        <img
          src="/images/icons-8-doppelt-runter-21.png"
          alt=""
          className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto"
        />
      </a>
    </div>
  );
};

const Bubble = ({ text, className }) => (
  <div
    className={`${className} p-10 text-xl font-semibold text-black bg-white rounded-full`}
  >
    {text}
  </div>
);
export default HeroStromGas;
