import React from "react";
const ReponsiveYellowFace = () => {
  return (
    <div className="flex flex-col bg-kacheln-bg rounded-11xl mb-32 mt-12 sm:mt-32    text-text-prim font-h1  border-[2px] border-solid border-grey-accent box-border h-[900px]  primary:h-[419px]  justify-center items-center gap-10 md:gap-20  w-[95%] max-w-6xl mx-auto">
      <div className="w-full h-full flex primary:flex-row primary:justify-around flex-col mt-20 sm:mt-12  items-center">
        <div className="relative primary:order-2">
          <b className="secondary:text-primary text-responsiveHeading">
            <p className="m-0">klassisches </p>
            <p className="m-0">Empfehlungs-</p>
            <p className="m-0">marketing</p>
          </b>
          <img
            className="absolute primary:bottom-[-100px] primary:left-[-170px] bottom-[-50px] left-[-130px]  object-cover"
            alt=""
            src="/images/morflaxstudio12@2x.png"
          />
          <img
            className="z-50 absolute primary:top-[-210.87px] primary:left-[0.61px]  top-[-90.87px] left-[-50px]  object-cover"
            alt=""
            src="/images/morflaxstudio121@2x.png"
          />
          <img
            className="absolute primary:bottom-[-100px] primary:right-[-100px]  bottom-[-50px] right-[-100px] object-cover"
            alt=""
            src="/images/morflaxstudio11@2x.png"
          />
        </div>
        <div className="relative   primary:order-1  text-chocolate">
          {/* <YellowFace /> */}

          <div className="relative primary:h-[600px] ">
            <div className="relative secondary:scale-100 xl:scale-90 scale-75  rounded-481xl [background:linear-gradient(135.84deg,_#ffbf6e,_#ff7a00)] w-[420px] h-[420px] flex justify-center items-center">
              <img
                className=" m-auto w-[184px] h-[189px] object-cover"
                alt=""
                src="/images/morflaxstudio17-1@2x.png"
              />
              <div className="absolute top-[-30px]  whitespace-nowrap left-[-30px] text-[20px] max-w-[350px]  rounded-xl bg-white shadow-md overflow-hidden flex flex-col py-[21px] px-[25px] justify-start border-[1.5px] border-solid border-grey-accent  text-center  yellow-card-box-shadow primary:left-[-30px] !items-center">
                Nummern abgrasen
              </div>
              <div className="absolute top-[calc((52.5px*1)-30px)]  whitespace-nowrap max-w-[350px]  text-[20px]  right-0 rounded-xl bg-white shadow-md overflow-hidden flex flex-col py-[21px] px-[25px] justify-start border-[1.5px] border-solid border-grey-accent  text-center  yellow-card-box-shadow primary:right-[-100px] !items-center">
                Freunde anquatschen
              </div>
              <div className="absolute primary:top-[calc((52.5px*2)-30px)] top-[calc((230px+80px))] whitespace-nowrap text-[20px] max-w-[350px]  left-[-30px] rounded-xl bg-white shadow-md overflow-hidden flex flex-col py-[21px] px-[25px] justify-start border-[1.5px] border-solid border-grey-accent  text-center  yellow-card-box-shadow primary:left-[-150px] !items-center">
                Ich muss etwas tun
              </div>
              <div className="absolute primary:top-[calc((52.5px*3)-30px)] top-[calc((350px))] whitespace-nowrap max-w-[350px]  text-[20px] right-[-30px] rounded-xl bg-white shadow-md overflow-hidden flex flex-col py-[21px] px-[25px] justify-start border-[1.5px] border-solid border-grey-accent  text-center  yellow-card-box-shadow primary:right-[-200px] !items-center">
                Visitenkarten verteilen
              </div>
              <div className="absolute primary:top-[calc((52.5px*4)-30px)] top-[calc((400px+30px))] whitespace-nowrap max-w-[350px]  text-[20px] left-[0] rounded-xl bg-white shadow-md overflow-hidden flex flex-col py-[21px] px-[25px] justify-start border-[1.5px] border-solid border-grey-accent  text-center  yellow-card-box-shadow primary:left-[-170px] !items-center">
                Prämien eher willkürlich
              </div>

              <div className="absolute primary:top-[calc((52.5px*5)-30px)] top-[calc((500px))] whitespace-nowrap max-w-[350px]  text-[20px] right-[0] rounded-xl bg-white shadow-md overflow-hidden flex flex-col py-[21px] px-[25px] justify-start border-[1.5px] border-solid border-grey-accent  text-center  yellow-card-box-shadow primary:right-[-170px] !items-center">
                schlechte Erfahrungen
              </div>
              <div className="absolute primary:top-[calc((52.5px*6))] top-[calc((600px-30px))]  whitespace-nowrap max-w-[350px]   text-[20px]  primary:left-auto left-[0] rounded-xl bg-white shadow-md overflow-hidden flex flex-col py-[21px] px-[25px]  justify-start border-[1.5px] border-solid border-grey-accent  text-center  yellow-card-box-shadow primary:right-[-100px] !items-center">
                penetrante Vermittler
              </div>
              <div className="absolute primary:top-[calc((52.5px*7)-30px)] top-[calc((700px-30px))] whitespace-nowrap max-w-[350px]  text-[20px] right-[0] rounded-xl bg-white shadow-md overflow-hidden flex flex-col py-[21px] px-[25px] justify-start border-[1.5px] border-solid border-grey-accent  text-center  yellow-card-box-shadow primary:left-[-100px] !items-center">
                fühle mich verantwortlich
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReponsiveYellowFace;
