export default function constructWhatsappLink(nummer, text) {
  // Entferne alle nicht-numerischen Zeichen aus der Nummer
  let cleanedNumber = nummer.replace(/\D/g, "");

  // Encodiere den Text für die URL
  let encodedText = encodeURIComponent(text);

  // Konstruiere den WhatsApp-Link
  let whatsappLink = `https://wa.me/${cleanedNumber}?text=${encodedText}`;

  return whatsappLink;
}
