export function stripObjectKeep(obj, keysToKeep) {
  return keysToKeep.reduce((acc, key) => {
    if (obj.hasOwnProperty(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export function stripObjectDiscard(obj, keysToDiscard) {
  const strippedObj = { ...obj };
  keysToDiscard.forEach((key) => delete strippedObj[key]);
  return strippedObj;
}
