import { Button, Card, Skeleton, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineAdsClick } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../../redux/auth/selectors";
import AnalyticService from "../../../service/AnalyticService";
import LandingPageService from "../../../service/LandingPageService";
import QuestionService from "../../../service/QuestionService";
import { checkIsOldPage } from "../../../utils/checkIsOldPage";
import { aboTarif } from "../../../utils/divers";
import { PopUpComponent } from "../../ConfirmPopup";

const LandingPageCard = ({ data, getLandingPages }) => {
  const [stats, setStats] = useState();
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [analyticsData, setAnalyticsdata] = useState({
    pageview: 0,
    click_wa1: 0,
    click_wa2: 0,
    click_wa3: 0,
    click_qf: 0,
    click_q1: 0,
    click_q2: 0,
    click_q3: 0,
    click_q4: 0,
    click_q5: 0,
    click_q6: 0,
    click_q7: 0,
    click_q8: 0,
    click_recall: 0,
    play_v1: 0,
    play_v2: 0,
    watchtime_v1: 0,
    watchtime_v2: 0,
  });
  const [waActivated, setWaActivated] = useState(true);
  const [closingVideoActivated, setClosingVideoActivated] = useState(true);
  const [questionLength, setQuestionLength] = useState(0);
  const [IsOldPage, setIsOldPage] = useState(true);
  const { user } = useSelector(selectUser);

  const [loadingConfig, setLoadingConfig] = useState(true);

  const cacheAnalyticsData = (landingPageId, data) => {
    const cacheKey = `analytics_${landingPageId}`;
    const cachedData = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
  };

  const getCachedAnalyticsData = (landingPageId) => {
    const cacheKey = `analytics_${landingPageId}`;
    const cachedData = localStorage.getItem(cacheKey);
    if (!cachedData) return null;

    const parsedData = JSON.parse(cachedData);

    const cacheDuration = 2 * 60 * 60 * 1000;

    if (Date.now() - parsedData.timestamp < cacheDuration) {
      return parsedData.data;
    }

    return null;
  };

  useEffect(() => {
    const getLandingPages = async () => {
      const { data: statdata } = await LandingPageService.getStats(data?._id);
      setStats(statdata);
    };

    const getLPData = async () => {
      const detailLP = await LandingPageService.detail(data?._id);
      const { activateClosingVideo, waActivated } = detailLP.data;
      if (!activateClosingVideo) {
        setClosingVideoActivated(false);
      } else if (waActivated) {
        setWaActivated(false);
      }

      const relevantKeys = [
        "accompanyingTextOne",
        "introVideoHeader",
        "introductoryText",
        "accompanyingTextTwo",
        "accompanyingTextThree",
        "questionaireIntroductionText",
      ];
      const hasOldPage = relevantKeys.some(
        (key) => detailLP.data[key] && checkIsOldPage(detailLP.data[key])
      );
      if (hasOldPage) {
        setIsOldPage(false);
      }
      await new Promise((resolve) => setTimeout(resolve, 300));
      setLoadingConfig(false);
    };

    const getQuestionLength = async () => {
      const questions = await QuestionService.listQuestions(data?._id);
      setQuestionLength(questions.data.length);
    };
    getQuestionLength();
    getLandingPages();
    getLPData();
  }, [data]);

  // Landing Page Analytics
  useEffect(() => {
    const getGoogleAnalyticsStatistic = async () => {
      setLoadingAnalytics(true);
      try {
        const cachedData = getCachedAnalyticsData(data?._id);

        if (cachedData?.clicks === 0) {
          return;
        }
        if (cachedData) {
          setAnalyticsdata(cachedData);
          setLoadingAnalytics(false);
          return;
        }

        const response = await AnalyticService.getUserAnalytics(data?._id);

        if (response?.status === 200 && response.data?.length) {
          const analyticsData1 = response.data;

          const newAnalyticsData = {
            click_wa1: analyticsData1[0]?.Click_wa1 || 0,
            click_wa2: analyticsData1[1]?.Click_wa2 || 0,
            click_wa3: analyticsData1[2]?.click_wa3 || 0,
            click_qf: analyticsData1[3]?.click_qf || 0,
            click_recall: analyticsData1[4]?.click_recall || 0,
            click_q1: analyticsData1[5]?.click_q0 || 0,
            click_q2: analyticsData1[6]?.click_q1 || 0,
            click_q3: analyticsData1[7]?.click_q2 || 0,
            click_q4: analyticsData1[8]?.click_q3 || 0,
            click_q5: analyticsData1[13]?.click_q4 || 0,
            click_q6: analyticsData1[14]?.click_q5 || 0,
            click_q7: analyticsData1[15]?.click_q6 || 0,
            click_q8: analyticsData1[16]?.click_q7 || 0,
            play_v1: analyticsData1[9]?.Play_v1 || 0,
            play_v2: analyticsData1[10]?.play_v2 || 0,
            watchtime_v1: analyticsData1[11]?.Watchtime_v1 || 0,
            watchtime_v2: analyticsData1[12]?.watchtime_v2 || 0,
          };

          setAnalyticsdata(newAnalyticsData);

          cacheAnalyticsData(data?._id, newAnalyticsData);
        } else {
          cacheAnalyticsData(data?._id, { clicks: 0 });
          console.log("No analytics data available");
        }
      } catch (error) {
        console.error("Error fetching analytics data", error);
      } finally {
        setLoadingAnalytics(false);
      }
    };

    if (data?._id) {
      getGoogleAnalyticsStatistic();
    }
  }, [data?._id]);

  return (
    <div className="w-full gap-6 primary:flex-nowrap flex-wrap-reverse flex p-5 rounded-mini bg-white box-border border-[1px] border-solid border-grey-accent">
      <div className="w-full grid gap-3 ">
        <div className="font-semibold">{data?.name}</div>
        <div className="flex gap-3 xl:flex-nowrap flex-wrap items-center lg:justify-start justify-center">
          <div className="sm:flex-nowrap flex-wrap  md:grid grid-cols-[2fr_2fr_1fr_1fr] flex flex-row items-center justify-between secondary:w-[60%] tertiary:w-[40%] sm:gap-[2.5rem] gap-[10px] text-[0.88rem] text-text-sec">
            <div className="flex flex-col text-nowrap items-start  justify-start gap-[0.63rem]">
              <div className=" font-medium">Erstellt Am</div>
              <div className="flex flex-col   items-start justify-start text-[14px] sm:text-[1rem] text-text-prim">
                <div className=" font-semibold">
                  {moment(data.createdAt).format("D.M.YYYY")}
                </div>
              </div>
            </div>
            <div className=" flex flex-col text-nowrap items-start  justify-start gap-[0.63rem]">
              <div className=" font-medium">Geändert Am</div>
              <div className="flex flex-col   items-start justify-start text-[14px] sm:text-[1rem] text-text-prim w-full">
                <div className=" font-semibold">
                  {moment(data.updatedAt).format("D.M.YYYY")}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[0.63rem] flex-shrink">
              <div className=" font-medium">Aufrufe</div>
              <div className="flex flex-col items-start justify-start text-[14px] sm:text-[1rem] text-text-prim">
                <div className=" font-semibold">{stats?.clicks}</div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[0.63rem] flex-basis-[10%] flex-shrink">
              <div className=" font-medium">Statistik</div>
              <div className="flex flex-col   items-start justify-start text-[14px] sm:text-[1rem] text-text-prim">
                <div className=" font-semibold">
                  <Tooltip
                    overlayClassName="min-w-max bg-blue-main"
                    placement="bottom"
                    title={
                      <div className="flex flex-col gap-4 items-center p-4 relative">
                        {aboTarif(user) < 3 && (
                          <>
                            <div
                              style={{ zIndex: 99999999999999999 }}
                              className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-md"
                            />
                            <Card
                              style={{ zIndex: 99999999999999999 }}
                              className="absolute top-[220px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 text-center"
                            >
                              <h2 className="text-2xl font-bold mb-4">
                                Upgrade benötigt
                              </h2>
                              <p className="mb-4">
                                Um auf die Statistiken zugreifen zu können,
                                musst du auf eine höhere Abonnementstufe
                                upgraden.
                              </p>
                              <Link to="/dashboard/upgrade">
                                <Button
                                  type="primary"
                                  className="bg-[#001D47] h-[40px] mr-2"
                                >
                                  Upgrade
                                </Button>
                              </Link>
                            </Card>
                          </>
                        )}

                        <h4 className="text-3xl">Statistiken</h4>
                        {loadingAnalytics ? (
                          <Skeleton />
                        ) : (
                          <div className="flex flex-row gap-14 flex-nowrap text-nowrap">
                            <ul className="flex flex-col flex-nowrap gap-4">
                              <AiOutlineClockCircle className="w-8 h-8" />

                              <li>
                                <h1 className="sm:text-xl text-sm">
                                  Watchtime Kernvideo
                                </h1>
                                <h2>{analyticsData.watchtime_v1}%</h2>
                              </li>
                              {closingVideoActivated ? (
                                <li>
                                  <h1 className="sm:text-xl text-sm">
                                    Watchtime Abschlussvideo
                                  </h1>
                                  <h2>{analyticsData.watchtime_v2}%</h2>
                                </li>
                              ) : null}
                              {waActivated ? (
                                <li>
                                  <h1 className="sm:text-xl text-sm">
                                    WA Sektion 1
                                  </h1>
                                  <h2>{analyticsData.click_wa1}%</h2>
                                </li>
                              ) : null}
                              {waActivated ? (
                                <li>
                                  <h1 className="sm:text-xl text-sm">
                                    WA Sektion 2
                                  </h1>
                                  <h2>{analyticsData.click_wa3}%</h2>
                                </li>
                              ) : null}
                              {waActivated ? (
                                <li>
                                  <h1 className="sm:text-xl text-sm">
                                    WA Formular
                                  </h1>
                                  <h2>{analyticsData.click_wa2}%</h2>
                                </li>
                              ) : null}
                              <li>
                                <h1 className="sm:text-xl text-sm">
                                  Aufrufe Kernvideo
                                </h1>
                                <h2>{analyticsData.play_v1}</h2>
                              </li>
                            </ul>
                            <ul className="flex flex-col flex-nowrap gap-4">
                              <MdOutlineAdsClick className="w-8 h-8" />
                              <li>
                                <h1 className="sm:text-xl text-sm">
                                  Fragen ansehen
                                </h1>
                                <h2>{analyticsData.click_qf}%</h2>
                              </li>
                              {Array.from(
                                { length: questionLength },
                                (_, index) => (
                                  <li key={index}>
                                    <h1 className="sm:text-xl text-sm">
                                      Frage {index + 1}
                                    </h1>
                                    <h2>
                                      {analyticsData[`click_q${index + 1}`] ||
                                        0}
                                      %
                                    </h2>
                                  </li>
                                )
                              )}
                              {closingVideoActivated ? (
                                <li>
                                  <h1 className="sm:text-xl text-sm">
                                    Aufrufe Abschlussvideo
                                  </h1>
                                  <h2>{analyticsData.play_v1}</h2>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        )}
                      </div>
                    }
                  >
                    <Button className="size-5">i</Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-auto w-full md:flex-nowrap font-semibold flex-wrap gap-[1.25rem]  flex flex-row items-center justify-center  text-[1rem] text-white">
            <Tooltip
              title={
                IsOldPage
                  ? `Es ist ein neuerer Landingpage-Konfigurator verfügbar!
Deshalb können für diese Version keine Änderungen mehr vorgenommen werden. Erstelle dazu bitte eine neue Landingpage. Diese Seite bleibt über die Links Deiner Promoter aber weiterhin aufrufbar.`
                  : ""
              }
            >
              <div
                className={`m-auto rounded-3xs bg-[#001D47] overflow-hidden flex flex-col py-[0.91rem] px-[1.25rem] items-center justify-center transition-opacity duration-300 ${
                  loadingConfig
                    ? "opacity-50"
                    : IsOldPage
                    ? "opacity-50"
                    : "cursor-pointer"
                }`}
              >
                <Link
                  to={`/landingpageconfig/${data?.theme}/${data._id}`}
                  className={`${
                    IsOldPage ? "pointer-events-none" : ""
                  } transition-all duration-300`}
                  onClick={IsOldPage ? (e) => e.preventDefault() : undefined}
                >
                  {loadingConfig ? (
                    <div className="flex items-center gap-2">
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                      <span>Laden...</span>
                    </div>
                  ) : (
                    "Konfigurieren"
                  )}
                </Link>
              </div>
            </Tooltip>

            <div
              className=" m-auto rounded-3xs cursor-pointer bg-[#E81900] overflow-hidden flex flex-col py-[0.91rem] px-[1.25rem] items-center justify-center text-center"
              onClick={() =>
                PopUpComponent({
                  confirmButtonText: "Löschen",
                  onConfirm: async () => {
                    await LandingPageService.deleteLandingPage(data._id);
                    await getLandingPages();

                    try {
                    } catch (error) {}
                  },
                  heading: (
                    <>
                      Möchtest du deine Landingpage
                      <span className="mx-1 text-blue-main">
                        “{data.name}”
                      </span>{" "}
                      wirklich löschen?
                    </>
                  ),
                  subheading: (
                    <div className="text-red-500 font-bold">
                      Wenn du dich dazu entscheidest, diese Landingpage zu
                      löschen, werden auch alle zu dieser Seite gehörenden
                      Promolinks in den Promoterkarten gelöscht. Dieser Vorgang
                      ist endgültig und kann NICHT rückgängig gemacht werden!
                    </div>
                  ),
                })
              }
            >
              Löschen
            </div>
          </div>
        </div>
      </div>
      <div
        className="m-auto cursor-pointer transition-all image-overlay-eye-container relative w-full xl:w-[250px] h-[118px] overflow-hidden"
        onClick={() => {
          window.open(
            `/landingpage/${data?.theme}/${data._id}/viewmode`,
            "_blank"
          );
        }}
      >
        <img
          className="w-full   xl:w-[250px] h-[118px] rounded-xl   object-cover"
          alt=""
          src={data?.background_image}
        />
        <img
          className="animate-fade  image-overlay-eye inset-0  w-full aspect-[9/16]   xl:w-[250px]  h-[118px] bg-[rgba(255,255,255,0.4)] absolute rounded-xl  "
          alt=""
          src="/images/overlay-eye-img.svg"
        />
      </div>
    </div>
  );
};

export default LandingPageCard;
