import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class NotificationService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/notifications`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }
  getInboundMessages({ limit, pages, type }) {
    let url = `/getInboundMessages?limit=${limit}&page=${pages}`;
    if (type) url += `&type=${type}`;
    return this.api.get(url);
  }
  readInboundMessage(id) {
    return this.api.put(`/readInboundMessage?id=${id}`);
  }
  getOutboundMessages({ limit, pages }) {
    return this.api.get(`/getOutboundMessages?limit=${limit}&page=${pages}`);
  }
  getPramienNotification() {
    return this.api.get("/getPramienNotification");
  }
  setNotificationConfiguration(configuration) {
    return this.api.put("/setNotificationConfiguration", configuration);
  }
  markAllAsRead(query) {
    return this.api.put("/markAllAsRead", query);
  }
  getNotificationConfiguration() {
    return this.api.get("/getNotificationConfiguration");
  }
}

export default new NotificationService();
