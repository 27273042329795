import { LOADING, SETCACHE, REMOVECACHE } from "./constants";

export const setCACHE = (cache) => ({
  type: SETCACHE,
  payload: cache,
});
export const removeCACHE = () => ({
  type: REMOVECACHE,
  payload: null,
});

export const setLoading = (loading) => ({
  type: LOADING,
  payload: loading,
});
