import { Skeleton } from "antd";
import React from "react";
import AnswerComponents from "./AnswerComponents";

const QuestionareComponent = ({
  bg,
  question,
  answers,
  modelBg,
  index,
  total,
  visible,
  step,
  rounded,
  setWrongAnswers,
  setAnswerDetails,
  totalRejectionAnswers,
  id,
}) => {
  const [pressed, setPressed] = React.useState(false);
  const bgSeter = () => {
    if (!bg)
      return {
        background: `rgba(0,0,0,0.3)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "norepeat",
      };
    return {
      backgroundImage: `url(${bg})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "norepeat",
    };
  };

  return (
    visible && (
      <div
        className="w-full h-[100vh] flex justify-center items-center overflow-y-auto"
        style={bgSeter()}
      >
        <div
          className={`sm:w-[80%] w-full m-auto   p-[10px] sm:p-[30px] ${
            rounded ? " rounded-[20px]" : "[0px]"
          } `}
          style={{ background: modelBg, marginInline: 20, paddingBlock: 20 }}
        >
          <h1
            className="p-3 text-xl font-semibold text-center text-white rounded-sm bg-opacity-70 sm:text-9xl bg-slate-500"
            style={
              modelBg === "rgba(241, 243, 246, 0.8)"
                ? { color: "#001D47", fontWeight: 700 }
                : {}
            }
          >
            {question}
          </h1>
          <div className="my-5 sm:my-10">
            {answers?.map?.((ae, i) => (
              <AnswerComponents
                id={id}
                index={index}
                ae={ae}
                answers={answers}
                question={question}
                totalRejectionAnswers={totalRejectionAnswers}
                rounded={rounded}
                pressed={pressed}
                setPressed={setPressed}
                setAnswerDetails={setAnswerDetails}
                setWrongAnswers={setWrongAnswers}
                step={step}
                total={total}
                key={i}
              />
            ))}
          </div>
          <div>
            <div className="my-2 text-xl font-semibold text-center">
              Fortschritt:{index}/{total}
            </div>

            <div className="w-full h-[5px] rounded-xl overflow-hidden bg-white">
              <div
                className="h-full bg-trkis "
                style={{ width: `${(index / total) * 100}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default QuestionareComponent;
