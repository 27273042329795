import { LineChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import React, { useEffect, useRef, useState } from "react";
import UserService from "../../../service/UserService";

const EChart = () => {
  echarts.use([GridComponent, LineChart, CanvasRenderer]);
  const [recommend, setRecommend] = useState(true);
  const [customers, setCustomers] = useState(true);
  const [option, setoptions] = useState({
    title: {
      text: "Statistiken",
      show: true,
      textStyle: {
        fontSize: 50,
      },
    },
    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        dataView: { readOnly: false },
        magicType: { type: ["line", "bar"] },
        restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      data: ["Jan", "", "Mar", "", "Mai", "", "Jul", "", "Sep", "", "Nov", ""],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Empfehlungen",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: "line",
        smooth: true,
        symbol: "none",
        color: "#658FCD",
      },
      {
        name: "Kunden",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: "line",
        smooth: true,
        symbol: "none",
        color: "#40C945",
      },
    ],
  });
  const chartRef = useRef();
  const getYearlyStats = async () => {
    const { data } = await UserService.getMonthlyStatsForYear();
    console.log(data.monthlyEmpfehlungen, data.monthlyKunden);
    setoptions((prev) => ({
      ...prev,
      series: [
        {
          name: "Empfehlungen",
          data: data.monthlyEmpfehlungen,
          type: "line",
          smooth: true,
          symbol: "none",
          color: "#658FCD",
        },
        {
          name: "Kunden",
          data: data.monthlyKunden,
          type: "line",
          smooth: true,
          symbol: "none",
          color: "#40C945",
        },
      ],
    }));
  };
  useEffect(() => {
    getYearlyStats();
  }, []);
  useEffect(() => {
    const chart = echarts?.init(chartRef?.current);
    chart.setOption({
      ...option,
      series: [
        ...option.series.filter((e) => {
          if (e.name === "Empfehlungen") {
            return recommend;
          } else if (e.name === "Kunden") {
            return customers;
          } else {
            return true;
          }
        }),
      ],
    });
    window.addEventListener("resize", () => {
      chart.resize();
    });

    return () => {
      window.removeEventListener("resize", () => {
        chart.resize();
      });

      chart.dispose();
    };
  }, [customers, option, recommend, chartRef]);

  const chartStyle = {
    backgroundColor: "#F5F5F5",
    borderRadius: "30px",
    height: "318px",
  };

  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
        borderRadius: "30px",
      }}
    >
      <div className="flex transition-all items-center md:flex-nowrap flex-wrap justify-between">
        <h1 className="sm:text-[28px] text-xl font-semibold mx-10 md:ml-[4.3rem] mt-10">
          {" "}
          Statistiken
        </h1>
        <div className="flex items-center gap-3 mx-10 sm:mt-10">
          <div className="flex items-center  gap-3 transition-all">
            <div
              className={`${
                recommend ? "bg-[#658FCD]" : "bg-[gray]"
              } w-[14px] h-[14px] rounded-full cursor-pointer transition-all`}
              onClick={() => setRecommend(!recommend)}
            />
            <p className="sm:text-[18px] text-xs font-medium cursor-pointer   ">
              Empfehlungen
            </p>
          </div>
          <div className="flex items-center gap-3 transition-all">
            <div
              className={`${
                customers ? "bg-[#40C945]" : "bg-[gray]"
              } w-[14px] h-[14px] rounded-full cursor-pointer transition-all`}
              onClick={() => setCustomers(!customers)}
            />
            <p className="sm:text-[18px] text-xs font-medium cursor-pointer">
              Kunden
            </p>
          </div>
        </div>
      </div>
      <>
        <div
          ref={chartRef}
          style={chartStyle}
          className="transition-all   m-auto max-w-[1200px]"
        />
      </>
    </div>
  );
};

export default EChart;
