import React, { useEffect, useState } from "react";
import PublicService from "../../service/PublicService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";

const Index = () => {
  const [privacy, setPrivacy] = useState("");

  const getConditions = async () => {
    try {
      const { data } = await PublicService.getTerms();

      setPrivacy(data.content);
    } catch (error) {}
  };
  useEffect(() => {
    getConditions();
  }, []);

  if (!privacy) {
    return <LoadingScreen />;
  }
  return (
    <div className="p-10 formattierterRechtsText">
      <h1 className="text-xl font-bold text-center">AGB</h1>
      <div className="my-10" dangerouslySetInnerHTML={{ __html: privacy }} />
    </div>
  );
};

export default Index;
