import React from 'react'

const Box = ({children, className}) => {
  return (
    <div className={`rounded-11xl bg-[#fafafa] grid gap-10 p-8 ${className}`}>
      {children}
    </div>
  )
}

export default Box
