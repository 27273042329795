import React, { useState } from "react";
import { Collapse } from "react-collapse";

const CollapseItem = ({ title, children, icon, line = true }) => {
  const [open, setOpen] = useState(false);

  const handletoggle = () => {
    setOpen(!open);
  };

  return (
    <div className="cursor-pointer transition animate-fade-left w-full   my-10  bg-[#FAFAFA] rounded-[1.25rem]   ">
      <div className="  p-[1.875rem]" onClick={handletoggle}>
        <div className="flex justify-between items-center ">
          <h1 className="font-semibold text-[1.25rem]">{title}</h1>
          <div className="flex justify-between items-center">
            {icon}
            <img
              src="/images/icons8pfeil-ausklappen3-1.png"
              className={`transition-all ${open && "rotate-90"}`}
              alt=""
            />
          </div>
        </div>
      </div>
      {open && line && (
        <div className="px-[1.875rem] w-full">
          <hr className="bg-[#D8D8D8] text-[#D8D8D8] w-[100%] h-[2px]" />
        </div>
      )}
      <Collapse
        isOpened={open}
        className={`transition-all   animate-duration-1000 animate-dissolve`}
      >
        {children}
      </Collapse>
    </div>
  );
};

export default CollapseItem;
