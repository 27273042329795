import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP";
import MovingHearts from "../../MovingHearts/MovingHearts";

const NotFound = () => {
  const location = useLocation();

  // Function to parse query parameters
  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  // Get the 'note' query parameter
  const note = getQueryParam("note");
  return (
    <div>
      <MovingHearts />
      <div className="flex justify-center items-center flex-col text-center h-full relative z-40">
        <style>
          {`
            #root{
                position:relative;
                height:100vh;
                display:flex;
                justify-content:center;
                align-items:center;
                overflow:hidden;
                
            }
            
            
            
            `}
        </style>
        {note ? (
          <div className="font-bold backdrop-blur p-10 rounded-xl		">{note}</div>
        ) : (
          <MainLogo
            className="brightness-125 mx-auto transition-all sm:scale-[2.5] scale-[2]"
            style={{
              margin: "2rem auto",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NotFound;
