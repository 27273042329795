import React, { useState } from "react";
import { sendEventUser } from "../../utils/analytics";
const AnswerComponents = ({
  step,
  setWrongAnswers,
  total,
  answers,
  question,
  ae,
  setAnswerDetails,
  rounded,
  pressed,
  setPressed,
  totalRejectionAnswers,
  id,
  index,
}) => {
  const googleAnalyticsHandler = () => {
    sendEventUser({
      category: "button",
      action: `click_q${index}`,
      label: `q${index}`,
      value: 1,
      landingPageId: id,
    });
  };
  return (
    <button
      disabled={pressed}
      className="transition-all  my-1 sm:my-2 cursor-pointer w-full text-left bg-white p-[20px_25px] font-semibold text-base sm:text-xl select-none"
      onMouseDown={(e) => {
        e.target.classList.add("opacity-50");
      }}
      onMouseUp={(e) => {
        e.target.classList.remove("opacity-50");
      }}
      onClick={(e) => {
        googleAnalyticsHandler();
        if (pressed) {
          return;
        }
        setPressed(true);
        if (!ae?.consideredNiceToHaveAnswer) {
          setWrongAnswers((prev) => {
            const newCount = prev.count + 1;
            const shouldShowNegativeFeedback =
              newCount === totalRejectionAnswers;
            return {
              ...prev,
              count: newCount,
              showFormWithRedText: shouldShowNegativeFeedback,
            };
          });
        }
        e.target.classList.remove("bg-white");
        e.target.classList.add("bg-[#40c945]");
        setTimeout(() => {
          step();
          if (setAnswerDetails)
            setAnswerDetails((prev) => [
              ...prev,
              {
                question_text: question,
                answers,
                selectedAnswer: ae.text,
              },
            ]);
        }, 1000);
      }}
      style={{ borderRadius: rounded ? "15px" : "0px" }}
    >
      {ae.text}
    </button>
  );
};

export default AnswerComponents;
