import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Box from "../components/_NEW/LandingPageConfig/Layout/Box";
import Header from "../components/_NEW/LandingPageConfig/Layout/Header";
import CalendlyConfig from "../components/_NEW/LandingPageConfig/Sections/CalendlyConfig";
import ClosingVideoConfig from "../components/_NEW/LandingPageConfig/Sections/ClosingVideoConfig";
import HeroConfig from "../components/_NEW/LandingPageConfig/Sections/HeroConfig";
import IntroductionToClient from "../components/_NEW/LandingPageConfig/Sections/IntroductionToClient";
import LocalReviews from "../components/_NEW/LandingPageConfig/Sections/LocalReviews";
import NameConfig from "../components/_NEW/LandingPageConfig/Sections/NameConfig";
import Pramien from "../components/_NEW/LandingPageConfig/Sections/Pramien";
import Questions from "../components/_NEW/LandingPageConfig/Sections/Questions";
import Rechtliches from "../components/_NEW/LandingPageConfig/Sections/Rechtliches";
import TextConfig from "../components/_NEW/LandingPageConfig/Sections/TextConfig";
import WhatsAppConfig from "../components/_NEW/LandingPageConfig/Sections/WhatsAppConfig";
import Button from "../components/_NEW/LandingPageConfig/shared/Button";
import IconButton from "../components/_NEW/LandingPageConfig/shared/IconButton";
import { useMessageContext } from "../context/messageContext";
import { setLoading } from "../redux/auth/actions";
import { selectUser } from "../redux/auth/selectors";
import { store } from "../redux/store";
import LandingPageService from "../service/LandingPageService";
import localReviewService from "../service/LocalReviewService";
import QuestionService from "../service/QuestionService";
import UploadToCloudService from "../service/UploadToCloudService";
import { aboTarif } from "../utils/divers";
import { extractBase64Images } from "../utils/extractBase64Images";
import { handleUnusedImages } from "../utils/handleUnusedImages";
import { compressImageToMaxSize } from "../utils/helperImageCompression";
import { replaceBase64WithCloudinaryUrls } from "../utils/replaceBase64WithCloudinaryUrls";

const LandingPageConfig = () => {
  const { id } = useParams();
  const { type, id: ladingpageId } = useParams();
  const navigate = useNavigate();
  const { showMessage, clearMessage } = useMessageContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [landingPageData, setLandingPageData] = useState(null);
  const [localReviewsData, setLocalReviewsData] = useState(null);
  const [questionData, setQuestionData] = useState(null);

  // Sections
  const heroConfigRef = useRef(null);
  const localReviewsRef = useRef(null);
  const introductionToClient = useRef(null);
  const textConfigRef = useRef(null);
  const questionsRef = useRef(null);
  const closingVideoRef = useRef(null);
  const rechtliches = useRef(null);
  const pramienRef = useRef(null);
  const whatsAppConfigRef = useRef(null);
  const calendlyConfigRef = useRef(null);
  const nameConfig = useRef(null);
  const { user } = useSelector(selectUser);

  const saveAll = async () => {
    console.log("saveAll()");

    // Ladezustand setzen
    setIsSaving(true);
    store.dispatch(setLoading(true));
    showMessage("loading", "Speichern", 0, "lp-config-loading");

    try {
      const allNewValues = await Promise.all([
        heroConfigRef.current.save(),
        localReviewsRef.current.save(),
        introductionToClient.current.save(),
        textConfigRef.current.save(),
        questionsRef.current.save(),
        closingVideoRef.current.save(),
        rechtliches.current.save(),
        pramienRef.current.save(),
        whatsAppConfigRef.current.save(),
        calendlyConfigRef.current.save(),
        nameConfig.current.save(),
      ]);

      const landingPagePayload = allNewValues.reduce(
        (accumulator, currentObject) => {
          return {
            ...accumulator,
            ...(currentObject?.landingPageData || {}),
          };
        },
        {}
      );

      const localReviewsPayload = allNewValues.reduce(
        (accumulator, currentObject) => {
          return [...accumulator, ...(currentObject?.localReviewsData || [])];
        },
        []
      );

      const questionsPayload = allNewValues.reduce(
        (accumulator, currentObject) => {
          return [...accumulator, ...(currentObject?.questionData || [])];
        },
        []
      );

      const editorFields = [
        "introductoryText",
        "accompanyingTextOne",
        "accompanyingTextTwo",
        "accompanyingTextThree",
        "introVideoHeader",
        "questionaireIntroductionText",
      ];

      let allUpdatedContents = [];
      let updatedImageList = [...(landingPageData.imageList || [])];
      const maxSizeInBytes = 500 * 1024;
      console.log(updatedImageList);
      for (const field of editorFields) {
        const editorContent = landingPagePayload[field];
        if (editorContent) {
          const base64Images = extractBase64Images(editorContent);
          const newBase64Images = base64Images.filter(
            (base64Image) =>
              !updatedImageList.some((url) => url.includes(base64Image))
          );

          if (newBase64Images.length > 0) {
            const compressedImages = await Promise.all(
              newBase64Images.map((base64Image) =>
                compressImageToMaxSize(base64Image, maxSizeInBytes)
              )
            );

            let uploadedImages = await UploadToCloudService.uploadBase64Image(
              compressedImages
            );

            if (!Array.isArray(uploadedImages)) {
              uploadedImages = [uploadedImages];
            }

            const imageMap = newBase64Images.map((base64Image, index) => ({
              base64Image,
              url: uploadedImages[index].secure_url,
            }));

            const newImageUrls = uploadedImages.map((img) => img.secure_url);
            updatedImageList = [...updatedImageList, ...newImageUrls];

            const updatedEditorContent = replaceBase64WithCloudinaryUrls(
              editorContent,
              imageMap
            );

            landingPagePayload[field] = updatedEditorContent;
            allUpdatedContents.push(updatedEditorContent);
          } else {
            allUpdatedContents.push(editorContent);
          }
        }
      }

      const finalImageList = await handleUnusedImages(
        ladingpageId,
        allUpdatedContents
      );

      landingPagePayload.imageList = finalImageList;

      await Promise.all([
        LandingPageService.update(ladingpageId, landingPagePayload),
        localReviewService.wipeAndAdd(ladingpageId, localReviewsPayload),
        QuestionService.wipeAndAdd(ladingpageId, questionsPayload),
      ]);

      clearMessage("lp-config-loading");
      showMessage("success", "Erfolgreich gespeichert!");
    } catch (error) {
      console.log(error);
      clearMessage("lp-config-loading");
      showMessage("error", "Etwas ist schiefgelaufen.");
    }

    setIsSaving(false);
    store.dispatch(setLoading(false));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          landingPageResponse,
          localReviewsResponse,
          questionDataResponse,
        ] = await Promise.all([
          LandingPageService.detail(ladingpageId),
          localReviewService.list(ladingpageId),
          QuestionService.listQuestions(ladingpageId),
        ]);

        setLandingPageData(landingPageResponse.data);
        setLocalReviewsData(localReviewsResponse.data);
        setQuestionData(questionDataResponse.data);
      } catch (error) {
        showMessage(
          "error",
          "Ups, da ist etwas schief gegangen. Versuche es später noch mal"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="max-w-[2000px] w-screen">
      {/** Border */}
      <div className="tertiary:w-[70%] sm:w-[85%] w-[95%] m-auto">
        {/** Header */}
        <Header
          className={"pt-10 pb-14"}
          onArrowClick={() => navigate(-1)}
          onButtonClick={() =>
            window.open(`/landingpage/${type}/${id}/viewmode`, "_blank")
          }
        />

        {/**Sections */}
        {isLoading && (
          <div className="m-auto w-min">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 50,
                  }}
                  spin
                />
              }
            />
          </div>
        )}
        {!isLoading && (
          <div className="flex flex-col gap-10">
            <HeroConfig
              landingPageData={landingPageData}
              ref={heroConfigRef}
              type={type}
            />
            <LocalReviews
              ref={localReviewsRef}
              landingPageData={landingPageData}
              localReviewsData={localReviewsData}
            />
            <IntroductionToClient
              landingPageData={landingPageData}
              ref={introductionToClient}
            />
            <TextConfig landingPageData={landingPageData} ref={textConfigRef} />
            <Box className="text-center text-[#868686] text-[1.25rem]">
              Erstelle interessante und themenbezogene Fragen, sowie zugehörige
              Antworten, damit sich Interessenten mit Ihren Anliegen und
              Problemen angesprochen und abgeholt fühlen.
            </Box>
            <Box className="text-center text-[#868686] text-[1.25rem]">
              Die rot umrahmte Frage ist dabei immer die Ablehnungsantwort.
            </Box>
            <Questions questionData={questionData} ref={questionsRef} />

            <Rechtliches landingPageData={landingPageData} ref={rechtliches} />
            <Pramien landingPageData={landingPageData} ref={pramienRef} />
            <ClosingVideoConfig
              landingPageData={landingPageData}
              ref={closingVideoRef}
              disabled={aboTarif(user) < 2}
            />
            <WhatsAppConfig
              landingPageData={landingPageData}
              ref={whatsAppConfigRef}
              disabled={aboTarif(user) < 2}
            />
            <CalendlyConfig
              landingPageData={landingPageData}
              ref={calendlyConfigRef}
              disabled={aboTarif(user) < 2}
            />
            <NameConfig landingPageData={landingPageData} ref={nameConfig} />
          </div>
        )}

        {/**Button Desktop */}

        <Button
          text="Speichern"
          onClick={saveAll}
          className="hidden my-10 primary:block"
          disabled={isSaving || isLoading}
          loading={isSaving}
        />

        {/**Button Mobile */}
        <div className="fixed bottom-0 left-0 right-0 z-10 py-3 bg-white shadow-bottom-bar primary:hidden">
          <Button
            text="Speichern"
            onClick={saveAll}
            className="tertiary:w-[70%] sm:w-[85%] w-[95%] m-auto"
            disabled={isSaving || isLoading}
            loading={isSaving}
          />
        </div>

        <div className="bottom-0 left-0 right-0 z-[-1] py-3 primary:hidden opacity-0">
          <Button text="A" onClick={() => {}} disabled={true} />
        </div>

        <IconButton
          onClick={saveAll}
          className="hidden primary:fixed primary:block bottom-10 right-2 tertiary:right-10"
          disabled={isSaving || isLoading}
        />
      </div>
    </div>
  );
};

export default LandingPageConfig;
