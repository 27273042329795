import React, { useEffect, useState } from "react";
import PublicService from "../../service/PublicService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";

const Index = () => {
  const [Impressum, setImpressum] = useState("");

  const getConditions = async () => {
    try {
      const { data } = await PublicService.getImpressum();

      setImpressum(data.content);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getConditions();
  }, []);

  if (!Impressum) {
    return <LoadingScreen />;
  }
  return (
    <div className="p-10">
      <h1 className="text-xl font-bold text-center formattierterRechtsText">
        Impressum
      </h1>
      <div className="my-10" dangerouslySetInnerHTML={{ __html: Impressum }} />
    </div>
  );
};

export default Index;
