import { Alert, Button, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { BsTrash3 } from "react-icons/bs";
import { RiEditBoxLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import AdminService from "../../service/AdminService";
import PublicService from "../../service/PublicService";
import SubscriptionService from "../../service/SubscriptionService";
import { PlayIcon } from "./PlayIcon";

const Index = ({ title }) => {
  const user = useSelector(selectUser);

  const [subscription, setSubscription] = useState(null);
  const getSubscriptionInfo = async () => {
    const { data } = await SubscriptionService.getSubscriptionInfo();
    console.log(data);
    setSubscription(data?.subscription);
  };
  useEffect(() => {
    if (title) title("Tutorials");
  }, [title]);
  useEffect(() => {
    getSubscriptionInfo();
  }, []);
  const [tutorials, setTutorials] = React.useState(null);
  React.useEffect(() => {
    const getTutorials = async () => {
      const { data } = await PublicService.getTutorials();
      const sortedData = data.sort((a, b) => a.ranking - b.ranking);
      setTutorials(data);
    };
    getTutorials();
  }, []);
  const navigate = useNavigate();
  if (!tutorials) {
    return <Skeleton active />;
  }

  return (
    <div className="flex flex-col gap-[35px]">
      <div className="grid items-center gap-[35px] lg:grid-cols-2 grid-cols-1">
        {tutorials?.map((e) => (
          <div
            className="video-card-two rounded-xl overflow-hidden w-full "
            key={e?._id}
            style={{
              backgroundImage: `url(${e?.thumbnail})`,
            }}
          >
            <div className="  min-h-[400px]   flex justify-between flex-col backdrop-brightness-75  py-[25px] px-[50px] ">
              <div className="text-base sm:text-xl lg:text-primary text-white font-bold">
                {e?.title}
              </div>
              <div
                className="flex items-center gap-4 sm:flex-nowrap flex-wrap w-fit cursor-pointer"
                onClick={() => navigate("/dashboard/chapter/" + e._id)}
              >
                <PlayIcon />

                <p className="text-white font-semibold text-base">
                  JETZT STARTEN
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
