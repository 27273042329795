import React from "react";

const LocalReviewCard = ({ data }) => (
  <div className="local-review-card flex flex-col gap-8 rounded-11xl p-7 bg-white overflow-hidden sm:min-w-[450px] sm:max-w-[450px] min-w-[280px] max-w-[280px] mx-2 sm:mx-4">
    <div className="flex items-center gap-3 md:gap-6">
      <img
        className="object-cover w-16 rounded-full aspect-square md:w-20"
        src={
          data?.image || "/images/icons-8-mannlicher-benutzer-eingekreist-1.png"
        }
        alt="Profilbild"
      />
      <div className="max-w-[3400px]">
        <h1 className="md:text-[22px] text-[14px] font-semibold truncate">
          {data?.name}
        </h1>
        <h1 className="md:text-[20px] text-[14px] truncate text-wrap">
          {data?.jobTitle}
        </h1>
      </div>
    </div>
    <p
      className="text-[#868686] text-[14px] font-semibold break-words md:text-[20px] 
    h-[75px]
    md:h-[105px] 
    overflow-auto 
    "
    >
      {data?.reviewText}
    </p>
  </div>
);

const ReviewSlider = ({ reviews }) => {
  return (
    <>
      <div className={`bg-[#fafafa] w-screen`}>
        <div className="md:text-primary text-[21px] primary:text-4xl text-center font-bold py-10 flex justify-center items-center gap-3 primary:flex-row primary:py-20">
          <p>Das sagen unsere Kunden</p>
          <img
            src="/images/logo.png"
            alt="EinGuterTipp logo"
            className="h-6 primary:h-12"
          ></img>
        </div>

        <div className="flex xs:max-w-[1400px] max-w-[95vw] mx-auto w-full !pt-0 gap-0 sm:gap-7 p-7 pl-0 sm:pl-7 overflow-scroll ">
          {reviews.map((e, i) => (
            <LocalReviewCard data={e} key={i} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ReviewSlider;
