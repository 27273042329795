import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class PromoterService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/promoter`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  list() {
    return this.api.get("/list");
  }
  getLastSiteClickOfPromoter(id) {
    return this.api.get(`/getLastSiteClickOfPromoter?id=${id}`);
  }

  detail(id) {
    return this.api.get(`/detail?id=${id}`);
  }
  detailForPromoterPage(id) {
    return this.api.get(`/detailForPromoterPage?id=${id}`);
  }

  create(promoterData) {
    return this.api.post("/create", {
      ...promoterData,
      baseUrl: window.location.origin,
    });
  }

  update(id, promoterData) {
    return this.api.put(`/update?id=${id}`, promoterData);
  }

  createPromoterAccess(id) {
    return this.api.post(`/createPromoterAccess?id=${id}`, {
      baseUrl: window.location.origin,
    });
  }
  getNotes(id) {
    return this.api.get(`/getNotes?id=${id}`);
  }

  updateNotes(id, data) {
    return this.api.put(`/updateNotes?id=${id}`, data);
  }
  deletePromoter(id) {
    return this.api.delete(`/deletePromoter?id=${id}`);
  }
  sendConfirmationLink(promoterId) {
    return this.api.post(`/sendConfirmationLink`, {
      promoterId,
      baseUrl: window.location.origin,
    });
  }
}

export default new PromoterService();
